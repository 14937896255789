import React, { useEffect, useState } from 'react';
import Card from '../lib/ui/Card';
import TertiaryLinkButton from '../lib/forms/simple/TertiaryLinkButton';
import CardPadding from '../lib/ui/CardPadding';
import { get, del } from '../../util/api';
import FormRow from '../lib/forms/layout/FormRow';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import ScoreRelativeToClass from './ScoreRelativeToClass';
import CardToolbar from '../lib/ui/CardToolbar';
import ButtonArea from '../lib/forms/ButtonArea';
import TertiaryButton from '../lib/forms/simple/TertiaryButton';
import Button from '../lib/forms/simple/Button';
import ConfirmPopup from '../lib/forms/confirmation/ConfirmPopup';

export default function StudentInformation({ match, history }) {
	const studentId = match.params.student;
	const intl = useIntl();

	const [ student, setStudent ] = useState(null);
	const [ hasAccess, setHasAccess ] = useState(false);
	const [ confirmDetach, setConfirmDetach ] = useState(false);

	useEffect(() => {
		get('/studentroles/' + studentId).then(srr => {
			setStudent(srr.user);
			setHasAccess(srr.hasAccess);
		});
	}, [ studentId ]);

	if (student == null) return null;
	return <Card>
		<CardPadding>
			<FormRow title={intl.formatMessage({ id: "student" })} description={!hasAccess && intl.formatMessage({ id: "teacherNotGivenAccessToSeeStudentCompetences" })}>
				<span>{student.fullName}</span><br />
				<span>{student.studentInfo.schoolClassName} - {student.studentInfo.schoolName}</span><br /><br />
				<span>{student.studentInfo.birthDate == null ? <FormattedMessage id="noBirthdayKnown" /> : <FormattedDate value={new Date(student.studentInfo.birthDate)} dateStyle={'short'} />}</span><br />
				<span>{student.studentInfo.studentNumber}</span>
			</FormRow>
			{hasAccess && <FormRow title={intl.formatMessage({ id: "actions" })}>
				<TertiaryLinkButton to={'/students/' + studentId + '/assessments'} label={intl.formatMessage({ id: "seeCompetencesOverview" })} iconRight={true} icon="angle-right" />
			</FormRow>}
			{hasAccess && student.studentInfo && student.studentInfo.schoolClassId && <FormRow
				title={intl.formatMessage({ id: "results" })}
				description={intl.formatMessage({ id: "seeStudentComparativelyToClass" })}>
				<ScoreRelativeToClass studentId={studentId} schoolClassId={student.studentInfo.schoolClassId} />
			</FormRow>}
			<FormRow title={intl.formatMessage({ id: "dangerZone" })}>
				<ConfirmPopup
					open={confirmDetach}
					onConfirm={() => {
						del('/me/teacher/studentTeacherRelations/' + studentId)
							.then(() => history.push('/students'))
							.finally(() => setConfirmDetach(false));
					}}
					onCancel={() => setConfirmDetach(false)}
					formComponent={({ onCancel, onConfirm }) => <Card>
						<CardToolbar title={intl.formatMessage({ id: "removeStudentAccess" })} />
						<CardPadding>
							<p><FormattedMessage id="studentRemovalFromOverviewWarning" /></p>
							<ButtonArea>
								<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={onCancel} />
								<Button style={{ background: 'var(--col-red)' }} icon="trash" label={intl.formatMessage({ id: "continue" })} onClick={onConfirm} />
							</ButtonArea>
						</CardPadding>
					</Card>} />
				<TertiaryButton label={intl.formatMessage({ id: "removeFromOverview" })} icon="trash" onClick={() => setConfirmDetach(true)} />
			</FormRow>
		</CardPadding>
	</Card>;
}
