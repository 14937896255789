import React, { useEffect, useState } from 'react';
import Form from '../../lib/forms/Form';
import { post, put, get, del } from '../../../util/api';
import Card from '../../lib/ui/Card';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import ConfirmPopup from '../../lib/forms/confirmation/ConfirmPopup';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import { FormattedMessage, useIntl } from 'react-intl';
export default function FaqCategoryEdit({ match, history }) {
	const intl = useIntl();

	const faqCategoryId = match.params.id;

	const [ deleteModal, setDeleteModal ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ categoryName, setCategoryName ] = useState();
	const [ faqItemsId, setFaqItemsId ] = useState(null);
	const [ sorting, setSorting ] = useState(null);
	const faqCategoryIdIsNull = faqCategoryId == null || faqCategoryId == "null";
	const faqCategoryNameIsEmpty = categoryName == null || categoryName.length == 0;

	useEffect(() => {
		if(!faqCategoryIdIsNull) {
			get('/faqCategory/' + faqCategoryId).then(faqCategory => {
				setCategoryName(faqCategory.name);
				setSorting(faqCategory.sorting);
				setFaqItemsId(faqCategory.faqItemIdList);
			}).catch(err => err.text());
		}
	}, [ faqCategoryId ]);

	return <Card>
		<Form onSubmit={() => {
			const newCategory = {
				id: typeof faqCategoryId === 'string' ? parseInt(faqCategoryId, 10) : faqCategoryId,
				name: categoryName,
				faqItemIdList: faqItemsId,
				sorting: sorting,
			};
			setLoading(true);
			if(faqCategoryIdIsNull) {
				post('/faqCategory', newCategory).then(() => { history.push('/admin/faq'); setLoading(false); });
			} else{
				put('/faqCategory/' + faqCategoryId, newCategory).then(() => { history.push('/admin/faq'); setLoading(false); });
			}
		}}>
			<ConfirmPopup
				open={deleteModal}
				onCancel={() => setDeleteModal(false)}
				formComponent={({ onCancel }) => <Card><CardToolbar title={intl.formatMessage({ id: "delete" })}/>
					<CardPadding>
						<FormattedMessage id="deleteCategoryModal" />
						<ButtonArea>
							<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={onCancel} />
							<Button style={{ background: 'var(--col-red)' }} icon="trash" label={intl.formatMessage({ id: "delete" })} onClick={() => {
								del('/faqCategory/' + faqCategoryId)
									.then(() => { history.push('/admin/faq'); setLoading(false); });
							}} />
						</ButtonArea>
					</CardPadding>
				</Card> } />
			<CardToolbar title={faqCategoryIdIsNull ? 'FAQ aanmaken' : 'FAQ bewerken'}>
				{!faqCategoryIdIsNull && <TertiaryButton
					icon="trash-o"
					label={intl.formatMessage({ id: "delete" })}
					onClick={() => setDeleteModal(true)} /> }
			</CardToolbar>
			{loading && <CardLoadingSpinner />}
			<CardPadding>
				<WithLabel label={intl.formatMessage({ id: "specifyTheNameOfTheCategory" })}>
					<InputField
						label={intl.formatMessage({ id: "specifyTheNameOfTheCategory" })}
						value={categoryName}
						onChange={setCategoryName} />
				</WithLabel>
				<ButtonArea>
					{faqCategoryNameIsEmpty ? <abbr title={ intl.formatMessage({ id: "inputFieldsEmpty" })}>
						<Button disabled={faqCategoryNameIsEmpty} label={faqCategoryIdIsNull ? "Toevoegen" : "Opslaan"} type={"submit"} />
					</abbr> : <Button label={faqCategoryIdIsNull ? "Toevoegen" : "Opslaan"} type={"submit"} />}
				</ButtonArea>
			</CardPadding>
		</Form>
	</Card>;
}