import React, { Fragment, useEffect, useState } from 'react';
import { get } from '../../../../util/api';
import BigMultiSelector from './BigMultiSelector';

export default function MonitorSelector({ disabled, onChange, value, capTwoCols = false }) {
	const [ monitors, setMonitors ] = useState([]);

	useEffect(() => {
		get('/monitors').then(setMonitors);
	}, []);

	return <BigMultiSelector
		onChange={onChange}
		value={value}
		capTwoCols={capTwoCols}
		disabled={disabled}
		options={monitors.map(m => ({
			value: m.id,
			label: m.name,
			description: <Fragment>
				<span>{m.clusters.length} clusters</span><br />
				<span>{m.competences.length} competenties</span>
			</Fragment>,
		}))} />;
}
