import React, { useContext, useState } from 'react';
import MaxWidth from '../../lib/ui/MaxWidth';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import CenterHorizontal from '../../lib/ui/CenterHorizontal';
import CardPadding from '../../lib/ui/CardPadding';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import UserContext from '../../../context/User';
import { put } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import { FormattedMessage, useIntl } from 'react-intl';

export default function EmailReset({ issues, onFix }) {
	const user = useContext(UserContext);
	const [ loading, setLoading ] = useState(false);
	const [ email, setEmail ] = useState('');
	const intl = useIntl();

	return <CenterHorizontal>
		<MaxWidth width="var(--u-512)">
			<Card>
				<CardToolbar title={intl.formatMessage({ id: "setEmail" })}>
					<TertiaryButton label={intl.formatMessage({ id: "doNotAskAgain" })} onClick={() => { setLoading(true); put('/users/me/postponeEmailPopup?duration=3153600000').then(onFix).finally(() => setLoading(false)); }} />
				</CardToolbar>
				<CardPadding>
					<form onSubmit={e => {
						e.preventDefault();
						e.stopPropagation();
						setLoading(true);
						put('/users/me/loginDetails', { username: user.username, email }).then(onFix).finally(() => setLoading(false));
						return false;
					}}>
						<WithLabel label={intl.formatMessage({ id: "email" })}>
							<InputField onChange={setEmail} value={email} />
							<p className="form-explanation"><FormattedMessage id="emailUsageExplanation" /></p>
						</WithLabel>
						<ButtonArea>
							{/* <TertiaryButton label="Niet meer vragen" onClick={() => { setLoading(true); put('/users/me/postponeEmailPopup?duration=3153600000').then(onFix).finally(() => setLoading(false)); }} />*/}
							<TertiaryButton label={intl.formatMessage({ id: "skipNow" })} onClick={() => { setLoading(true); put('/users/me/postponeEmailPopup?duration=86400').then(onFix).finally(() => setLoading(false)); }} />
							<Button disabled={email.length < 3 || !email.includes('@')} type="submit" icon="check" label={intl.formatMessage({ id: "continue" })} />
						</ButtonArea>

					</form>
				</CardPadding>
				{loading && <CardLoadingSpinner />}
			</Card>
		</MaxWidth>
	</CenterHorizontal>;
}
