import React, { Fragment } from 'react';

export default function FormRow({ title, description, customInfo, children }) {
	return <div className="form-row">
		<div className="form-row-info">
			<h2 className="header-a">{title}</h2>
			{description && <p>{description}</p>}
			<div>
				{customInfo}
			</div>
		</div>
		<div className="form-row-content">
			{children}
		</div>
	</div>;
}
