import React, { useContext, useState } from 'react';
import InputField from '../lib/forms/simple/InputField';
import WithLabel from '../lib/forms/WithLabel';
import Button from '../lib/forms/simple/Button';
import { authenticate, put } from '../../util/api';
import Card from '../lib/ui/Card';
import CardPadding from '../lib/ui/CardPadding';
import technasiumLogoWhite from '../../assets/technasium/logo-technasium-white-text.svg';
import ButtonArea from '../lib/forms/ButtonArea';
import ErrorMessage from '../lib/forms/ErrorMessage';
import TertiaryLinkButton from '../lib/forms/simple/TertiaryLinkButton';
import CardLoadingSpinner from "../lib/ui/CardLoadingSpinner";
import headerForeground from '../../assets/competentiemonitor/header/header-foreground-paths-login.svg';
import { Link } from 'react-router-dom';
import usePersistentState from '../../hooks/usePersistentState';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectField from '../lib/forms/simple/SelectField';
import FormRow from '../lib/forms/layout/FormRow';
import LanguageContext from '../../context/Language';

export default function Login({ onSuccess }) {
	const [ error, setError ] = useState(null);
	const [ username, setUsername ] = usePersistentState('', 'username');
	const [ password, setPassword ] = useState('');
	const [ loading, setLoading ] = useState(false);
	// const languageObject = useContext(LanguageContext);
	// const { language } = languageObject;
	// const { setLanguage } = languageObject;
	const intl = useIntl();

	return <div className="login">
		<Card>
			<div style={{ padding: 'var(--u-24) var(--u-32) 0 var(--u-32)' }}>
				<img
					style={{ paddingTop: 'var(--u-16)', maxWidth: '100%' }}
					className="logo"
					src={headerForeground}
					alt="Logo Competentiemonitor" />
			</div>
			<CardPadding>
				<form onSubmit={event => {
					event.preventDefault();
					if (!loading) {
						setError(null);
						setLoading(true);

						authenticate(username, password)
							.then(onSuccess)
							.catch(r => {
								if (r.status == 429) {
									setError(<FormattedMessage id="tooManyLoginAttempts" />);
								} else {
									setError(<FormattedMessage id="unknownLoginDetails" />);
								}
							})
							.finally(() => setLoading(false));
					}
				}}>
					{error != null && <ErrorMessage error={error}/>}
					<WithLabel label={intl.formatMessage({ id: "nameOrEmail" })}>
						<InputField
							type="text"
							autoFocus={true}
							onChange={setUsername}
							value={username}/>
					</WithLabel>
					<WithLabel label={intl.formatMessage({ id: "password" })}>
						<InputField
							type="password"
							onChange={setPassword}
							value={password} />
					</WithLabel>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						{/* <div style={{ width: '150px', height: '48px', paddingTop: 'var(--u-16)' }}>*/}
						{/*	<SelectField*/}
						{/*		options={[*/}
						{/*			{ value: 'Dutch', label: 'Nederlands' },*/}
						{/*			{ value: 'English', label: 'English' },*/}
						{/*		]}*/}
						{/*		onChange={l => {*/}
						{/*			setLanguage(l);*/}
						{/*			put('/users/me/language', { language: l });*/}
						{/*		}}*/}
						{/*		value={language} />*/}
						{/* </div>*/}
						<ButtonArea>
							<Button label={intl.formatMessage({ id: "login" })} type="submit" icon="angle-right" iconRight={true} />
						</ButtonArea>
					</div>

				</form>
			</CardPadding>
			<div style={{ background: 'var(--col-primary-50)', borderTop: '1px solid var(--col-primary-200)' }}>
				<CardPadding>
					<div style={{ textAlign: 'center' }}>
						<TertiaryLinkButton
							label={intl.formatMessage({ id: "loginWithEmail" })}
							onClick={() => setError(null)}
							to="/magiclink"/>
						<br /><br />
						<TertiaryLinkButton
							label={intl.formatMessage({ id: "createAccountWithTeacherCode" })}
							onClick={() => setError(null)}
							to="/signup"/>
						<br /><br />
						<TertiaryLinkButton
							label={intl.formatMessage({ id: "forgotPassword" })}
							onClick={() => setError(null)}
							to="/reset"/>
					</div>
					{loading && <CardLoadingSpinner />}
				</CardPadding>
			</div>
		</Card>
		<div className="login-subcard">
			<p><FormattedMessage id="developedByTechnasium" /> <a href="https://technasium.nl/competentiemonitor">technasium.nl/competentiemonitor</a></p>
			<p><FormattedMessage id="click" /> <Link to="/page/terms-and-conditions"><FormattedMessage id="here" /></Link> <FormattedMessage id="forTermsConditions" /></p>
			<a href="https://technasium.nl">
				<img src={technasiumLogoWhite} style={{ width: '50%', maxWidth: '200px', height: 'auto' }} alt="Logo Stichting Technasium" />
			</a>
		</div>
	</div>;
}
