import React, { Fragment, useContext, useEffect, useState } from 'react';
import Card from '../../../lib/ui/Card';
import CardPadding from '../../../lib/ui/CardPadding';
import Question from './Question';
import CardAccent from '../../../lib/ui/CardAccent';
import UserContext from '../../../../context/User';
import { get, post } from '../../../../util/api';
import ButtonArea from '../../../lib/forms/ButtonArea';
import Button from '../../../lib/forms/simple/Button';
import Comment from './Comment';
import TertiaryButton from '../../../lib/forms/simple/TertiaryButton';
import TaxonomyHtmlRenderer from '../../../taxonomy/TaxonomyHtmlRenderer';
import CollapsableArea from '../../../lib/ui/CollapsableArea';
import { useIntl } from 'react-intl';


function fetchComments(levelId, userId, setComments) {
	get('/comments/level/' + levelId + '/' + userId).then(setComments);
}

export default function Level({ competence, finished, assessment, level, answers, onAnswer, viewModeCompact, readOnly }) {
	const [ composing, setComposing ] = useState(false);
	const [ comments, setComments ] = useState([]);
	const [ newComment, setNewComment ] = useState('');
	const me = useContext(UserContext);
	const userId = (assessment || {}).userId || me.id;
	const intl = useIntl();

	useEffect(() => {
		fetchComments(level.id, userId, setComments);
	}, [ level.id ]);

	return <div className="assessment-level">
		<Card>
			<CardAccent color={level.color} />

			{viewModeCompact == false ?
				<CardPadding>
					{level.explanationText != null && level.explanationText.length > 0 && <Fragment>
						{/* <ReadMoreLink>*/}
						<CollapsableArea label={intl.formatMessage({ id: "levelExplanation" })} defaultOpen={!finished}>
							<TaxonomyHtmlRenderer html={level.explanationText} />
						</CollapsableArea>
						{/* </ReadMoreLink>*/}
						<br />
					</Fragment>}
					{level.questions.map(q => <Question
						readOnly={readOnly}
						question={q}
						onAnswer={a => onAnswer(q.id, a)}
						value={typeof answers[q.id] == 'undefined' ? null : answers[q.id]} />)}
					{finished && <TaxonomyHtmlRenderer html={level.finishedText} />}
				</CardPadding>
				:
				<CardPadding>
					{level.questions.map(q => <Question
						readOnly={readOnly}
						question={q}
						onAnswer={a => onAnswer(q.id, a)}
						value={typeof answers[q.id] == 'undefined' ? null : answers[q.id]} />)}
				</CardPadding>
			}
		</Card>

		<div className="comments">
			<div className="comments-content">
				{comments.map(c => <Comment key={c.id} {...c} />)}
				<form onSubmit={e => {
					e.preventDefault();
					e.stopPropagation();
					post('/comments', { levelId: level.id, userId: userId, content: newComment })
						.then(() => {
							fetchComments(level.id, userId, setComments);
							setNewComment('');
							setComposing(false);
						});
					return false;
				}}>
					{composing && <textarea className="form-element" onChange={e => setNewComment(e.target.value)} value={newComment} />}
					{composing && <ButtonArea>
						<Button type="submit" disabled={newComment.length == 0} icon="check" label={intl.formatMessage({ id: "send" })} />
					</ButtonArea>}
					{!composing && <ButtonArea>
						<TertiaryButton type="button" icon="pencil" label={intl.formatMessage({ id: "comment" })} onClick={() => setComposing(true)} />
					</ButtonArea>}
				</form>
			</div>
		</div>
	</div>;
}
