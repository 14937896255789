import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { get } from '../../../../util/api';

function competenceToRsElement(competence) {
	return { label: competence.name, value: competence.id };
}

export default function CompetenceField({ onChange, value, excluding, ...props }) {
	const [ competences, setCompetences ] = useState(null);
	const [ competencesById, setCompetencesById ] = useState({});

	useEffect(() => {
		get('/competences').then(competences => {
			setCompetences(competences);
			setCompetencesById(competences.reduce((tot, elem) => ({ ...tot, [elem.id]: elem }), {}));
		});
	}, []);

	const excludingMap = excluding.reduce((tot, elem) => ({ ...tot, [elem]: true }), {});

	return <Select
		{...props}
		options={competences == null ? [] : competences.filter(c => !excludingMap[c.id]).map(competenceToRsElement)}
		onChange={v => onChange(v == null ? null : competencesById[v.value])}
		value={value == null ? null : competenceToRsElement(competencesById[value])}
		className="react-select"
		classNamePrefix="react-select" />;
}
