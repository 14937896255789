import React, { useContext, useState } from 'react';
import UserContext from '../../../context/User';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import Card from '../../lib/ui/Card';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import { post } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import ErrorMessage from '../../lib/forms/ErrorMessage';
import WithValidation from '../../lib/forms/WithValidation';
import { FormattedMessage, useIntl } from 'react-intl';

export default function InviteTeacher({ history }) {
	const user = useContext(UserContext);
	const [ error, setError ] = useState(null);
	const [ loading, setLoading ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ fullName, setFullname ] = useState('');
	const intl = useIntl();

	const emailValid = /^(.+)@(.+)\.(.+)$/.test(email);
	const fullNameValid = fullName.length >= 2;

	return <Card>
		<CardToolbar title={intl.formatMessage({ id: "inviteSupervisor" })} />
		<CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				setError(null);
				setLoading(true);
				post('/schools/' + (user.schoolAdminInfo || {}).schoolId + '/teachers', { email, fullName })
					.then(() => history.push('/teachers'))
					.catch(e => e.text().then(setError))
					.finally(() => setLoading(false));
				return false;
			}}>
				{error != null && <ErrorMessage error={error == 'DUPLICATE_EMAIL' ? <FormattedMessage id="emailAlreadyInUse" /> : <FormattedMessage id="errorWhenInvitingSupervisor" />} />}
				<WithLabel label="E-mail">
					<WithValidation valid={emailValid}>
						<InputField onChange={setEmail} value={email} />
					</WithValidation>
					<p className="form-explanation"><FormattedMessage id="instructionsSentToThisEmail" /></p>
				</WithLabel>
				<WithLabel label={intl.formatMessage({ id: "fullName" })}>
					<WithValidation valid={fullNameValid}>
						<InputField onChange={setFullname} value={fullName} />
					</WithValidation>
					<p className="form-explanation"><FormattedMessage id="alsoShownToStudents" /></p>
				</WithLabel>
				<ButtonArea>
					<TertiaryLinkButton to="/teachers" label={intl.formatMessage({ id: "cancel" })} />
					<Button disabled={!emailValid || !fullNameValid} type="submit" icon="paper-plane" label={intl.formatMessage({ id: "invite" })} />
				</ButtonArea>
			</form>
		</CardPadding>
		{loading && <CardLoadingSpinner />}
	</Card>;
}
