import React, { useEffect, useState } from 'react';
import { get } from '../../../util/api';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import TablePadding from '../../lib/table/TablePadding';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';

function sortClassTypes(cts) {
	return cts.slice().sort((a, b) => (a.name == b.name ? 0 : (a.name < b.name ? -1 : 1)));
}

export default function SchoolClassTypeOverview() {
	const [ classTypes, setClassTypes ] = useState(null);

	useEffect(() => {
		get('/schoolClassTypes').then(ct => setClassTypes(sortClassTypes(ct)));
	}, []);

	return <Card>
		<CardToolbar title="Klastypen beheren">
			<TertiaryLinkButton icon="plus" label="Klastype maken" to="/admin/schoolclasstypes/create" />
		</CardToolbar>

		{classTypes != null && <TablePadding>
			<span className="text-small">{classTypes.length} klastypen weergegeven</span>
		</TablePadding>}

		<table className="table">
			<thead>
				<tr>
					<th>Klastype</th>
					<th></th>
				</tr>
			</thead>
			{classTypes != null && <tbody>
				{classTypes.map(c => <tr key={c.id}>
					<td>{c.name}</td>
					<td>
						<TertiaryLinkButton iconRight={true} icon="angle-right" label="Bewerk" to={'/admin/schoolclasstypes/' + c.id} />
					</td>
				</tr>)}
			</tbody>}
		</table>
		{classTypes == null && <CardLoadingSpinner />}
	</Card>;
}
