import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class DateField extends React.Component {
	static propTypes = {
		value: PropTypes.number,
		onChange: PropTypes.func,
		placeholder: PropTypes.string,
	};

	constructor() {
		super();
		this.state = {
			text: null,
			editSinceFocus: false,
			showPicker: false,
			monthOffset: 0,
		};
		this.ignoreNextBlur = false;
	}

	onType(e) {
		this.setState({ text: e.target.value, editSinceFocus: true });
	}

	textValueOrDate() {
		if (this.state.text || this.state.editSinceFocus) return this.state.text;
		if (this.props.value != null) return moment.unix(this.props.value).format('DD-MM-YYYY');
		return '';
	}

	getViewingDate() {
		const now = moment();
		now.subtract(this.state.monthOffset, 'months');
		return now;
	}

	increaseMonth() {
		this.setState(state => ({ ...state, monthOffset: state.monthOffset - 1 }));
	}

	decreaseMonth() {
		this.setState(state => ({ ...state, monthOffset: state.monthOffset + 1 }));
	}

	increaseYear() {
		this.setState(state => ({ ...state, monthOffset: state.monthOffset - 12 }));
	}

	decreaseYear() {
		this.setState(state => ({ ...state, monthOffset: state.monthOffset + 12 }));
	}

	// Left pad zeroes
	static lpz(str, len) {
		let output = '' + str;
		while (output.length < len) {
			output = '0' + output;
		}
		return output;
	}

	blur(e) {
		if (this.ignoreNextBlur) {
			e.preventDefault();
			if (this.input) setTimeout(() => this.input.focus(), 0);
			this.ignoreNextBlur = false;
		} else {
			const txt = (this.state.text || '').replace(/\//g, '-');
			let date = '';
			if (txt.match(/^[0-9]{4}$/)) {
				date = txt.slice(0, 2) + '-' + txt.slice(2, 4) + '-' + moment().year();
			} else if (txt.match(/^[0-9]{6}$/)) {
				date = txt.slice(0, 2) + '-' + txt.slice(2, 4) + '-' + ((parseInt(txt.slice(4, 6), 10) >= 70) ? '19' : '20') + txt.slice(4, 6);
			} else if (txt.match(/^[0-9]{8}$/)) {
				date = txt.slice(0, 2) + '-' + txt.slice(2, 4) + '-' + txt.slice(4, 8);
			} else if (txt.match(/^[0-9]{1,2}-[0-9]{1,2}$/)) {
				date = DateField.lpz(txt.split('-')[0], 2) + '-' + DateField.lpz(txt.split('-')[1], 2) + '-' + moment().year();
			} else if (txt.match(/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{2}$/)) {
				const parts = txt.split('-');
				date = DateField.lpz(parts[0], 2) + '-' + DateField.lpz(parts[1], 2) + '-' + ((parseInt(parts[2], 10) >= 70) ? '19' : '20') + parts[2];
			} else if (txt.match(/^[0-9]{1,2}-[0-9]{1,2}-[0-9]{4}$/)) {
				const parts = txt.split('-');
				date = DateField.lpz(parts[0], 2) + '-' + DateField.lpz(parts[1], 2) + '-' + parts[2];
			}

			if (date != '' && moment(date, 'DD-MM-YYYY').isValid()) {
				this.props.onChange(moment(date, 'DD-MM-YYYY').unix());
			} else if (this.state.editSinceFocus) {
				this.props.onChange(null);
			}

			this.setState(state => ({ ...state, showPicker: false, editSinceFocus: false, text: null }));
		}
	}

	setIgnoreNextBlur() {
		this.ignoreNextBlur = true;
	}

	selectDay(date) {
		this.setState(state => ({ ...state, editSinceFocus: false, text: null }));
		this.props.onChange(moment(date.format('DD-MM-YYYY'), 'DD-MM-YYYY').unix());
	}

	// Externally available!
	focus() {
		if (this.input) this.input.focus();
	}

	// Get the monday before the 1st (or on the 1st) of the current month we're viewing
	getFirstDate() {
		const start = moment(this.getViewingDate());
		start.date(1);
		start.isoWeekday(1);
		return start;
	}

	renderTable(selected) {
		const viewingDate = this.getViewingDate();
		const date = moment(this.getFirstDate());
		const output = [];
		for (let week = 0; (viewingDate.month() >= date.month() && viewingDate.year() >= date.year()) || viewingDate.year() > date.year(); week++) {
			const cells = [];
			for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
				const classes = [];
				if (date.month() != viewingDate.month()) classes.push('greyed');
				if (selected != null && date.year() == selected.year() && date.month() == selected.month() && date.date() == selected.date()) classes.push('selected');
				cells.push(<td className={classes.join(' ')} key={week + '-' + dayOfWeek} onClick={this.selectDay.bind(this, moment(date))}>{date.format('D')}</td>);
				date.add(1, 'days');
			}
			output.push(<tr key={week}>{cells}</tr>);
		}
		return output;
	}

	render() {
		const value = this.props.value == null ? null : moment.unix(this.props.value);
		const viewingDate = this.getViewingDate();
		return <div className="date-picker">
			<div className={'fg-line' + (this.state.showPicker ? ' fg-toggled' : '')}>
				<input
					type="text"
					className="form-element date-picker-field"
					ref={ref => (this.input = ref)}
					value={this.textValueOrDate()}
					onFocus={() => this.setState({ showPicker: true })}
					onBlur={this.blur.bind(this)}
					onChange={this.onType.bind(this)}
					placeholder={this.props.placeholder} />
			</div>
			<button className="btn btn-flat" type="button" onClick={() => this.focus()}><span className="fa fa-calendar" /></button>
			<div className="date-picker-dropdown" style={{ display: this.state.showPicker ? 'block' : 'none' }} onMouseDown={this.setIgnoreNextBlur.bind(this)}>
				<div className="date-picker-header">
					<button type="button" onClick={() => this.decreaseYear()} className="btn btn-flat">
						<i className="fa fa-angle-double-left" />
					</button>
					<button type="button" onClick={() => this.decreaseMonth()} className="btn btn-flat">
						<i className="fa fa-angle-left" />
					</button>
					<span>{viewingDate.format('MMMM YYYY')}</span>
					<button type="button" onClick={() => this.increaseMonth()} className="btn btn-flat">
						<i className="fa fa-angle-right" />
					</button>
					<button type="button" onClick={() => this.increaseYear()} className="btn btn-flat">
						<i className="fa fa-angle-double-right" />
					</button>
				</div>
				<table>
					<thead>
						<tr>
							<td>Ma</td>
							<td>Di</td>
							<td>Wo</td>
							<td>Do</td>
							<td>Vr</td>
							<td>Za</td>
							<td>Zo</td>
						</tr>
					</thead>
					<tbody>
						{this.renderTable(value)}
					</tbody>
				</table>
			</div>
		</div>;
	}
}
