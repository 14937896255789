import React from 'react';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import CollapsableArea from '../../lib/ui/CollapsableArea';
import CompetenceEditQuestion from './CompetenceEditQuestion';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import ButtonArea from '../../lib/forms/ButtonArea';
import HtmlField from '../../lib/forms/simple/HtmlField';
import ColorField from '../../lib/forms/simple/ColorField';

function createNewQuestion(level) {
	const sorting = level.questions.length > 0 ? level.questions.sort((a, b) => { if(a.sorting - b.sorting) return -1; else return 1; })[0].sorting + 1 : 0;
	return {
		name: level.label,
		version: level.version,
		question: '',
		sorting: sorting,
	};
}

export default function CompetenceEditLevel({ level, setLevel, removeLevel }) {
	return <CollapsableArea label={level.name}>
		<WithLabel label="Niveaunaam">
			<InputField onChange={name => setLevel({ ...level, name })} value={level.name} />
		</WithLabel>
		<WithLabel label="Label">
			<InputField onChange={label => setLevel({ ...level, label })} value={level.label} />
		</WithLabel>
		<WithLabel label="Kleur">
			<ColorField onChange={color => setLevel({ ...level, color })} value={level.color} />
		</WithLabel>

		<div className="form-2-col">
			<WithLabel label="Uitleg">
				<HtmlField onChange={ex => setLevel({ ...level, explanationText: ex })} value={level.explanationText} />
			</WithLabel>

			<WithLabel label="Afrondingstekst">
				<HtmlField onChange={ft => setLevel({ ...level, finishedText: ft })} value={level.finishedText} />
			</WithLabel>
		</div>

		{level.questions.map((question, idx) => <CompetenceEditQuestion
			question={question}
			setQuestion={updatedQuestion => setLevel({ ...level, questions: level.questions.map((q, i) => (i == idx ? updatedQuestion : q)) })}
			removeQuestion={() => setLevel({ ...level, questions: level.questions.filter((_, i) => i != idx) })}
			key={idx} />)}
		<ButtonArea>
			<TertiaryButton onClick={() => setLevel({ ...level, questions: [ ...level.questions, createNewQuestion(level) ] })} icon="plus" label="Vraag toevoegen" />
		</ButtonArea>
	</CollapsableArea>;
}
