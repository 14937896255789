import React, { Fragment, useEffect, useState } from 'react';
import CardToolbar from '../lib/ui/CardToolbar';
import Card from '../lib/ui/Card';
import { get, put } from '../../util/api';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import InputField from '../lib/forms/simple/InputField';
import TertiaryLinkButton from '../lib/forms/simple/TertiaryLinkButton';
import TablePadding from '../lib/table/TablePadding';
import SortableHeader from '../lib/table/SortableHeader';
import RelativeTime from '../lib/datetime/RelativeTime';
import useSessionState from '../../hooks/useSessionState';
import { FormattedMessage, useIntl } from 'react-intl';

function customSort(filters, a, b) {
	const filter = filters[0].name.split('.');
	const keyA = filter.reduce((element, part) => element[part], a);
	const keyB = filter.reduce((element, part) => element[part], b);
	const correctionFactor = {
		'asc': 1,
		'desc': -1,
		'none': 0,
	}[filters[0].direction];

	// First deal with null cases
	if (keyA == null && keyB == null) {
		return filters.length > 1 ? customSort(filters.slice(1), a, b) : 0;
	} else if (keyB == null) {
		return -1 * correctionFactor;
	} else if (keyA == null) {
		return 1 * correctionFactor;
	}


	if(filter[0] == 'studentLastLoginCreatedAt') {
		// Special comparisons for dates on last login due to output being simplified for the user
		if (a.studentLastLogin == null) {
			return -1 * correctionFactor;
		} else if(b.studentLastLogin == null) {
			return 1 * correctionFactor;
		} else if (keyA < keyB) {
			return -1 * correctionFactor;
		} else if (keyA == keyB) {
			return 0;
		} else {
			return 1 * correctionFactor;
		}
	} else {
		// Normal comparison that upper-cases to account for names that are lower-cased, doesn't impact non name field comparisons
		if (keyA.toUpperCase() == keyB.toUpperCase()) {
			return filters.length > 1 ? customSort(filters.slice(1), a, b) : 0;
		} else if (keyA.toUpperCase() < keyB.toUpperCase()) {
			return -1 * correctionFactor;
		} else {
			return 1 * correctionFactor;
		}
	}


}

export default function StudentOverview() {
	const [ sort, setSort ] = useSessionState([ { name: 'newEntry', direction: 'asc' }, { name: 'studentLastLoginCreatedAt', direction: 'desc' } ], 'filters');
	const [ relations, setRelations ] = useState(null);
	const [ query, setQuery ] = useSessionState('', 'studentsOverviewQuery');
	const intl = useIntl();

	useEffect(() => {
		get('/me/teacher/studentTeacherRelations')
			.then(rel => setRelations(rel.map(r => ({ ...r, studentLastLoginCreatedAt: r.studentLastLogin || r.studentCreatedAt }))));
	}, []);

	const results = (relations || []).filter(r => {
		if (query.length == 0) return true;
		if (r.studentName.toLowerCase().includes(query.toLowerCase())) return true;
		if ((r.studentRole.schoolClassName || '').toLowerCase().includes(query.toLowerCase())) return true;
		if ((r.studentRole.studentNumber || '').toLowerCase().includes(query.toLowerCase())) return true;
		return false;
	}).sort((a, b) => {
		return customSort(sort, a, b);
	});

	return <Card>
		<abbr title={intl.formatMessage({ id: "someStudentsDidntGiveAccessToThePersonalCompetences" })}>
			<CardToolbar title={intl.formatMessage({ id: "studentOverview" })}>
				<TertiaryLinkButton label={intl.formatMessage({ id: "invite" })} to="/inviteStudents" icon="plus" />
			</CardToolbar></abbr>

		{relations != null && <Fragment>
			<TablePadding>
				<InputField placeholder={intl.formatMessage({ id: "search" })} onChange={setQuery} value={query} type="search" />
				<span className="text-small">{results.length} <FormattedMessage id="studentsShown" /></span>
			</TablePadding>
			<table className="table" style={{ tableLayout: 'fixed' }}>
				<colgroup>
					<col width="59" />
					<col width="*" />
					<col width="*" />
					<col width="*" />
					<col width="*" />
					<col width="75" />
				</colgroup>
				<thead>
					<tr>
						<SortableHeader
							name="newEntry"
							firstSortName={sort[0].name}
							firstSortDirection={sort[0].direction}
							secondSortName={sort[1].name}
							secondSortDirection={sort[1].direction}
							onChange={(firstName, firstDirection, secondName, secondDirection) => setSort([ { name: firstName, direction: firstDirection }, { name: secondName, direction: secondDirection } ])}>
							<span title={intl.formatMessage({ id: "newEntryTooltip" })} className="fa fa-circle --col-primary-500" />
						</SortableHeader>
						<SortableHeader
							name="studentName"
							firstSortName={sort[0].name}
							firstSortDirection={sort[0].direction}
							secondSortName={sort[1].name}
							secondSortDirection={sort[1].direction}
							onChange={(firstName, firstDirection, secondName, secondDirection) => setSort([ { name: firstName, direction: firstDirection }, { name: secondName, direction: secondDirection } ])}>
							<FormattedMessage id="name" />
						</SortableHeader>
						<SortableHeader
							name="studentRole.schoolClassName"
							firstSortName={sort[0].name}
							firstSortDirection={sort[0].direction}
							secondSortName={sort[1].name}
							secondSortDirection={sort[1].direction}
							onChange={(firstName, firstDirection, secondName, secondDirection) => setSort([ { name: firstName, direction: firstDirection }, { name: secondName, direction: secondDirection } ])}>
							<FormattedMessage id="class" />
						</SortableHeader>
						<SortableHeader
							name="studentRole.studentNumber"
							firstSortName={sort[0].name}
							firstSortDirection={sort[0].direction}
							secondSortName={sort[1].name}
							secondSortDirection={sort[1].direction}
							onChange={(firstName, firstDirection, secondName, secondDirection) => setSort([ { name: firstName, direction: firstDirection }, { name: secondName, direction: secondDirection } ])}>
							<FormattedMessage id="studentNumber" />
						</SortableHeader>
						<SortableHeader
							name="studentLastLoginCreatedAt"
							firstSortName={sort[0].name}
							firstSortDirection={sort[0].direction}
							secondSortName={sort[1].name}
							secondSortDirection={sort[1].direction}
							onChange={(firstName, firstDirection, secondName, secondDirection) => setSort([ { name: firstName, direction: firstDirection }, { name: secondName, direction: secondDirection } ])}>
							<FormattedMessage id="lastLogin" />
						</SortableHeader>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{results.map(r => <tr key={r.id}>
						<td>{r.newEntry ? <span title={intl.formatMessage({ id: "newCompetencesFilledIn" })} className="fa fa-circle" style={{ color: 'var(--col-primary)' }} /> : ''}</td>
						<td style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{r.studentName}</td>
						<td>{r.studentRole.schoolClassName || ''}</td>
						<td>{r.studentRole.studentNumber || ''}</td>
						<td>
							{r.studentLastLogin ? <RelativeTime datetime={r.studentLastLogin} /> : <FormattedMessage id="never" />}
						</td>
						<td>
							<TertiaryLinkButton to={'/students/' + r.studentRole.id + '/info'} label={intl.formatMessage({ id: "view" })} icon="angle-right" iconRight={true} onClick={() => put('/me/teacher/studentTeacherRelations/markAsRead/' + r.studentRole.id, null, false)} />
						</td>
					</tr>)}
				</tbody>
			</table>
		</Fragment>}
		{relations == null && <CardLoadingSpinner />}
	</Card>;
}
