import React from 'react';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

const gradeMap = [ 'A', 'B', 'C', 'D', 'E', 'F' ];

export default function AssessmentBar({ isTeacher, studentId, competence, assessment, min, max, star, onStar, onUnstar, focus, onFocus, onUnfocus }) {
	const intl = useIntl();
	return <div className="assessment-bar">
		<div className="assessment-bar-actions">
			{!isTeacher && <Link to={'/assessments/create/' + competence.id} className="btn btn-touch" type="button">
				{/* Custom styling since visual 'center of mass' of the play button does not align
				    with the actual center of the play button. Correcting for the human eye is necessary */}
				<span style={{ position: 'relative', left: '1.5px' }} className="fa fa-play" />
			</Link>}
			{isTeacher && assessment != null && <Link to={'/students/' + studentId + '/assessments/view/' + assessment.id} className="btn btn-touch" type="button">
				<span style={{ position: 'relative', top: '1px' }} className="fa fa-eye" />
			</Link>}
		</div>
		<div className="assessment-bar-name">
			<span>{competence.label}</span>
		</div>
		<div className="assessment-bar-progress" style={{ display: 'grid', gridTemplateColumns: '1fr '.repeat(max - min + 1), gap: 'var(--u-4)', alignItems: 'center' }}>
			{gradeMap
				.map((grade, idx) => ({ grade, idx }))
				.filter(g => g.idx >= min && g.idx <= max)
				.map(g => {
					const isEnabled = g.idx <= (assessment || { grade: min - 1 }).grade;
					const isHighest = g.idx == (assessment || { grade: min - 1 }).grade || (g.idx == 0 && (assessment || { grade: min - 1 }).grade == -1);
					const colors = [ competence.color, ...competence.levels.map(lvl => lvl.color) ].map(c => c.trim());
					return <div
						key={g.idx}
						className={"assessment-bar-block" + (isEnabled ? ' assessment-bar-block-enabled' : '') + (isHighest ? ' assessment-bar-block-highest' : '')}
						style={{ background: isEnabled ? colors[g.idx] : 'var(--col-grey-100)' }}>
						<span className="assessment-bar-grade">{g.grade}</span>
					</div>;
				})}
		</div>
		{isTeacher ?
			<>
				<div style={{ paddingLeft: 'var(--u-8)' }}>
					{star ?
						<abbr title={intl.formatMessage({ id: "studentMarkedCompetenceAsImportant" })}>
							<span className={'fa fa-star'} style={{ color: '#FFC400' }}/>
						</abbr>
						:
						<abbr title={intl.formatMessage({ id: "studentHasNotMarkedCompetenceAsImportant" })}>
							<span className={'fa fa-star-o'} style={{ color: 'var(--col-grey-200)' }}/>
						</abbr>
					}
				</div>
				<div style={{ paddingLeft: 'var(--u-8)' }} onClick={() => (focus ? onUnfocus(studentId, focus) : onFocus(studentId, competence.id))}>
					<abbr title={intl.formatMessage({ id: "markThisCompetenceAsImportantForThisStudent" })}>
						<span className={'clickable-star ' + (focus ? 'fa fa-flag' : 'fa fa-flag-o')} style={{ color: focus ? '#3DB814' : 'var(--col-grey-500)' }} />
					</abbr>
				</div>
			</>
			:
			<>
				<div style={{ paddingLeft: 'var(--u-8)' }} onClick={() => (star ? onUnstar(star) : onStar(competence.id))}>
					<abbr title={intl.formatMessage({ id: "markThisCompetenceAsImportantForYourself" })}>
						<span className={'clickable-star ' + (star ? 'fa fa-star' : 'fa fa-star-o')} style={{ color: star ? '#FFC400' : 'var(--col-grey-500)' }}/>
					</abbr>
				</div>
				<div style={{ paddingLeft: 'var(--u-8)' }}>
					{focus ?
						<abbr title={intl.formatMessage({ id: "supervisorMarkedCompetenceAsImportant" })}>
							<span className={'fa fa-flag'}
								  style={{ color: '#3DB814' }}/>
						</abbr>
						:
						<abbr title={intl.formatMessage({ id: "supervisorHasNotMarkedCompetenceAsImportant" })}>
							<span className={'fa fa-flag-o'}
								  style={{ color: 'var(--col-grey-200)' }}/>
						</abbr>
					}
				</div>
			</>
		}
	</div>;
}
