import React from 'react';

export default function Pagination({ page, maxPages, selectPage }) {
	return <div className="pagination">
		{[ 1, 2, 3, page - 1, page, page + 1, maxPages - 2, maxPages - 1, maxPages ]
			.reduce((arr, item) => (arr.includes(item) ? arr : [ ...arr, item ]), [])
			.filter(item => item >= 1 && item <= maxPages)
			.sort((a, b) => a - b)
			.reduce((arr, item) => {
				return ((arr[arr.length - 1] || 0) + 1) == item ? [ ...arr, item ] : [ ...arr, '..', item ];
			}, [])
			.map((item, i) => <li key={i} className={page == item ? 'active' : ''}>
				{item != '..' ? <a className="pagination-element" onClick={() => selectPage(item)}>{item}</a> : <span className="pagination-element">..</span>}
			</li>)}
	</div>;
}
