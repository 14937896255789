import React, { useEffect, useState } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import { get } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import TablePadding from '../../lib/table/TablePadding';
import InputField from '../../lib/forms/simple/InputField';

function sortSchoolsLowercase(schools) {
	return schools.sort((a, b) => {
		if (a.name.toLowerCase() == b.name.toLowerCase()) return 0;
		if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
		return 1;
	});
}

export default function SchoolsOverview() {
	const [ query, setQuery ] = useState('');
	const [ schools, setSchools ] = useState(null);

	useEffect(() => {
		get('/schools').then(sortSchoolsLowercase).then(setSchools).catch(e => e.text());
	}, []);

	const results = (schools || []).filter(s => s.name.toLowerCase().includes(query.toLowerCase()));

	return <Card>
		<CardToolbar title="Scholen beheren">
			<TertiaryLinkButton to="/admin/schools/create" icon="plus" label="School toevoegen" />
		</CardToolbar>

		{schools != null && <TablePadding>
			<InputField type="search" onChange={setQuery} value={query} placeholder="Zoeken..." />
			<span className="text-small">{results.length} scholen weergegeven</span>
		</TablePadding>}

		{schools != null && <table className="table">
			<thead>
				<tr>
					<th>Naam</th>
					<th>Monitor</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{results.map(s => <tr key={s.id}>
					<td>{s.name}</td>
					<td>{s.monitorName}</td>
					<td>
						<TertiaryLinkButton to={'/admin/schools/' + s.id} icon="angle-right" iconRight={true} label="Bewerk" />
					</td>
				</tr>)}
			</tbody>
		</table>}
		{schools == null && <CardLoadingSpinner />}
	</Card>;
}
