import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { get } from '../../../../util/api';

function clusterToRsElement(cluster) {
	return { label: cluster.name, value: cluster.id };
}

export default function ClusterField({ onChange, value, excluding = [], ...props }) {
	const [ clusters, setClusters ] = useState(null);
	const [ clustersById, setClustersById ] = useState({});

	useEffect(() => {
		get('/clusters').then(clusters => {
			setClusters(clusters);
			setClustersById(clusters.reduce((tot, elem) => ({ ...tot, [elem.id]: elem }), {}));
		});
	}, []);

	const excludingMap = excluding.reduce((tot, elem) => ({ ...tot, [elem]: true }), {});

	return <Select
		{...props}
		options={clusters == null ? [] : clusters.filter(c => !excludingMap[c.id]).map(clusterToRsElement)}
		onChange={v => onChange(v == null ? null : clustersById[v.value])}
		value={value == null ? null : clusterToRsElement(clustersById[value])}
		className="react-select"
		classNamePrefix="react-select" />;
}
