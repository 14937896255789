import WithLabelRedesign from '../../lib/forms/WithLabelRedesign';
import SelectField from '../../lib/forms/simple/SelectField';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useCallback, useEffect, useState } from 'react';
import { del, get, post } from '../../../util/api';
import { cmpCompetences } from './SchoolClassScore';

function checkClassCompetenceFocus(schoolClassId, competenceId, setIsFullClass) {
	get('/schoolClassFocus/checkSchoolClassCompetenceFocus/' + schoolClassId + '/' + competenceId).then(setIsFullClass);
}
function checkClassClusterFocus(schoolClassId, clusterId, setIsFullCluster) {
	get('/schoolClassFocus/checkSchoolClassClusterFocus/' + schoolClassId + '/' + clusterId).then(setIsFullCluster);
}
function checkFlaggedCompetences(schoolClassId, setFlagSet) {
	get('/schoolClassFocus/competences/' + schoolClassId).then(schoolClassFullCompetences => setFlagSet(new Set(schoolClassFullCompetences.map(s => s.competenceId))));
}

function checkFlaggedClusters(schoolClassId, setClusterFlagSet) {
	get('/schoolClassFocus/clusters/' + schoolClassId).then(flaggedClustersIds => setClusterFlagSet(new Set(flaggedClustersIds)));
}

export default function SchoolClassMarkImportant({ schoolClassId, schoolId }) {
	const [ competences, setCompetences ] = useState([]);
	const [ clusters, setClusters ] = useState([]);
	const [ selectedCluster, setSelectedCluster ] = useState(null);
	const [ flagSet, setFlagSet ] = useState(new Set([]));
	const [ clusterFlagSet, setClusterFlagSet ] = useState(new Set([]));
	const [ selectedCompetence, setSelectedCompetence ] = useState(null);
	const [ isFullClass, setIsFullClass ] = useState(false);
	const [ isFullCluster, setIsFullCluster ] = useState(false);
	const intl = useIntl();

	useEffect(() => {
		get('/monitors/bySchool/' + schoolId).then(monitor => {
			const orderedCompetences = monitor.competences.sort(cmpCompetences);
			setCompetences(orderedCompetences);
			setClusters(monitor.clusters);
			if(selectedCluster == null) setSelectedCluster(monitor.clusters[0].id);
			if (selectedCompetence == null) setSelectedCompetence(orderedCompetences[0].id);
		});
		get('/schoolClassFocus/' + schoolClassId).then(schoolClassCompetences => setFlagSet(new Set(schoolClassCompetences.map(s => s.competenceId))));
	}, [ schoolClassId ]);

	useEffect(() => {
		if (selectedCompetence != null && selectedCluster != null) {
			checkClassClusterFocus(schoolClassId, selectedCluster, setIsFullCluster);
			checkClassCompetenceFocus(schoolClassId, selectedCompetence, setIsFullClass);
			checkFlaggedCompetences(schoolClassId, setFlagSet);
			checkFlaggedClusters(schoolClassId, setClusterFlagSet);
		}
	}, [ selectedCompetence, selectedCluster ]);

	const onFocusCluster = useCallback((classroomId, competenceId, clusterId) => {
		post('/schoolClassFocus/cluster/' + classroomId + '/' + clusterId).finally(() => { checkClassCompetenceFocus(schoolClassId, competenceId, setIsFullClass); checkFlaggedCompetences(schoolClassId, setFlagSet); checkClassClusterFocus(classroomId, clusterId, setIsFullCluster); checkFlaggedClusters(schoolClassId, setClusterFlagSet); });
	}, [ selectedCluster ]);

	const onUnfocusCluster = useCallback((classroomId, competenceId, clusterId) => {
		del('/schoolClassFocus/cluster/' + classroomId + '/' + clusterId).finally(() => { checkClassCompetenceFocus(schoolClassId, competenceId, setIsFullClass); checkFlaggedCompetences(schoolClassId, setFlagSet); checkClassClusterFocus(classroomId, clusterId, setIsFullCluster); checkFlaggedClusters(schoolClassId, setClusterFlagSet); });
	}, [ selectedCluster ]);

	const onFocusClass = useCallback((classroomId, competenceId, clusterId) => {
		post('/schoolClassFocus/' + classroomId + '/' + competenceId).finally(() => { checkClassCompetenceFocus(schoolClassId, competenceId, setIsFullClass); checkFlaggedCompetences(schoolClassId, setFlagSet); checkClassClusterFocus(classroomId, clusterId, setIsFullCluster); checkFlaggedClusters(schoolClassId, setClusterFlagSet); });
	}, [ selectedCompetence ]);

	const onUnfocusClass = useCallback((classroomId, competenceId, clusterId) => {
		del('/schoolClassFocus/' + classroomId + '/' + competenceId).finally(() => { checkClassCompetenceFocus(schoolClassId, competenceId, setIsFullClass); checkFlaggedCompetences(schoolClassId, setFlagSet); checkClassClusterFocus(classroomId, clusterId, setIsFullCluster); checkFlaggedClusters(schoolClassId, setClusterFlagSet); });
	}, [ selectedCompetence ]);

	return <div>
		<div style={{ display: 'inline-block', padding: '20px 10px 10px 0px', width: '50%', float: 'left', borderRight: 'dotted' }}>
			<WithLabelRedesign label={"Cluster"}>
				<div style={{ float: "right", position: 'relative', zIndex: 1 }} onClick={() => (!isFullCluster ? onFocusCluster(schoolClassId, selectedCompetence, selectedCluster) : onUnfocusCluster(schoolClassId, selectedCompetence, selectedCluster))}>
					<abbr title={intl.formatMessage({ id: "markCompetenceAsImportantForTheClass" })}>
						<span className={'clickable-star ' + (isFullCluster ? 'fa fa-flag' : 'fa fa-flag-o')} style={{ color: isFullCluster ? '#3DB814' : 'var(--col-grey-500)' }} />
					</abbr>
				</div>
				<SelectField options={clusters
					.map(c => ({ value: c.id, label: <span>{c.label}{clusterFlagSet.has(c.id) && <span>&nbsp;<span className="fa fa-flag" style={{ color: '#3DB814' }} /></span>}</span> }))} onChange={setSelectedCluster} value={selectedCluster}></SelectField>
			</WithLabelRedesign>
			<FormattedMessage id={"markClusterAsImportantForTheClass"}></FormattedMessage>
		</div>
		<div style={{ padding: '20px 10px 10px 10px', display: 'inline-block', width: '50%', float: 'right' }}>
			<WithLabelRedesign label={intl.formatMessage({ id: "competence" })}>
				<div style={{ float: "right", position: 'relative', zIndex: 1 }} onClick={() => (!isFullClass ? onFocusClass(schoolClassId, selectedCompetence, selectedCluster) : onUnfocusClass(schoolClassId, selectedCompetence, selectedCluster))}>
					<abbr title={intl.formatMessage({ id: "markClusterAsImportantForTheClass" })}>
						<span className={'clickable-star ' + (isFullClass ? 'fa fa-flag' : 'fa fa-flag-o')} style={{ color: isFullClass ? '#3DB814' : 'var(--col-grey-500)' }} />
					</abbr>
				</div>
				<SelectField options={competences
					.map(c => ({ value: c.id, label: <span>{c.label}{flagSet.has(c.id) && <span>&nbsp;<span className="fa fa-flag" style={{ color: '#3DB814' }} /></span>}</span> }))} onChange={setSelectedCompetence} value={selectedCompetence} />
			</WithLabelRedesign>
			<FormattedMessage id={"markSingleCompetenceAsImportantForTheClass"}></FormattedMessage>
		</div>
	</div>;
}