import React, { useEffect, useState } from 'react';
import { get, put } from '../util/api.js';
import history from '../history';

import '../styling/main.css';
import 'font-awesome/css/font-awesome.min.css';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './auth/Login';
import AssessmentForm from './assessments/form/create/AssessmentForm';
import Profile from './profile/Profile';
import StaticPage from './staticpage/StaticPage';
import { Redirect } from 'react-router';
import AppFrame from './frame/AppFrame';
import MyAssessmentOverview from './assessments/overview/MyAssessmentOverviewRoute';
import ProfileNameForm from './profile/ProfileNameForm';
import ProfileUpdatePassword from './profile/ProfileUpdatePassword';
import ProfileLoginDetailsForm from './profile/ProfileLoginDetailsForm';
import ProfileUpdateStudentInfo from './profile/ProfileUpdateStudentInfo';
import AdminOverview from './admin/AdminOverview';
import SchoolsOverview from './admin/schools/SchoolsOverview';
import SchoolEdit from './admin/schools/SchoolEdit';
import UserContext from '../context/User';
import LocaleInjector from './Locale';
import MonitorsOverview from './admin/monitors/MonitorsOverview';
import MonitorEdit from './admin/monitors/MonitorEdit';
import ClustersOverview from './admin/clusters/ClustersOverview';
import ClusterEdit from './admin/clusters/ClusterEdit';
import CompetencesOverview from './admin/competencies/CompetencesOverview';
import CompetenceEdit from './admin/competencies/CompetenceEdit';
import StudentOverview from './teacher/StudentOverview';
import AssessmentOverviewByStudentRoute from './assessments/overview/AssessmentOverviewByStudentRoute';
import StudentInformation from './teacher/StudentInformation';
import AssessmentView from './assessments/form/view/AssessmentView';
import withAuthCheck from './auth/withAuthCheck';
import PagesOverview from './admin/pages/PagesOverview';
import PageEdit from './admin/pages/PageEdit';
import InviteStudents from './teacher/InviteStudents';
import Signup from './auth/Signup';
import ResetPassword from './auth/ResetPassword';
import createUseMagicLink from './auth/UseMagicLink';
import TeacherOverview from './schooladmin/teachers/TeacherOverview';
import InviteTeacher from './schooladmin/teachers/InviteTeacher';
import UserOverview from './admin/users/UserOverview';
import UserEditLogin from './admin/users/UserEditLogin';
import UserView from './admin/users/UserView';
import SchoolClassesOverview from './schooladmin/schoolclasses/SchoolClassesOverview';
import SchoolClassView from './schooladmin/schoolclasses/SchoolClassView';
import SchoolClassEdit from './schooladmin/schoolclasses/SchoolClassEdit';
import TaxonomyInjector from './taxonomy/TaxonomyInjector';
import TermsOverview from './admin/taxonomy/TermsOverview';
import TermEdit from './admin/taxonomy/TermEdit';
import MySchoolEdit from './schooladmin/school/MySchoolEdit';
import TeacherView from './schooladmin/teachers/TeacherView';
import UserInvite from './admin/users/UserInvite';
import Faq from './faq/Faq';
import GrowthGraph from './growth/GrowthGraph';
import GrowthGraphByStudentRoute from './growth/GrowthGraphByStudentRoute';
import FaqOverview from './admin/faq/FaqOverview';
import FaqItemEdit from './admin/faq/FaqItemEdit';
import UserDataChangeCounter from '../context/UserDataChangeCounter';
import MagicLinkLogin from './auth/MagicLinkLogin';
import SchoolClassTypeOverview from './admin/schoolclasstypes/SchoolClassTypeOverview';
import SchoolClassTypeEdit from './admin/schoolclasstypes/SchoolClassTypeEdit';
import FaqCategoryEdit from './admin/faq/FaqCategoryEdit';
import FeedbackOverview from './feedback/FeedbackOverview';
import CreateFeedbackRequest from './feedback/CreateFeedbackRequest';
import SubmitInFeedbackRequest from './feedback/SubmitFeedbackRequest';
import ViewSubmittedFeedback from './feedback/ViewSubmittedFeedback';

function testLogin() {
	return get('/whoami').then(r => {
		if (r.status >= 400) throw r;
		return r;
	});
}

function fetchUser(setUser, resetPasswordPopup) {
	testLogin().then(u => {
		setUser(u);
		if (typeof resetPasswordPopup == 'function') resetPasswordPopup();
	}).catch(() => setUser(null));
}

const withGlobalAdminCheck = withAuthCheck.bind(null, user => user.globalAdmin);
const withSchoolAdminCheck = withAuthCheck.bind(null, user => user.schoolAdminInfo != null);
const withTeacherCheck = withAuthCheck.bind(null, user => user.teacherInfo != null);
const withSchoolAdminOrTeacherCheck = withAuthCheck.bind(null, user => user.teacherInfo != null || user.schoolAdminInfo != null);
const withStudentCheck = withAuthCheck.bind(null, user => user.studentInfo != null);

function App() {
	const [ userDataCounter, setUserDataCounter ] = useState(0);
	const [ user, setUser ] = useState(null);

	useEffect(() => {
		fetchUser(setUser);
	}, []);

	return <UserContext.Provider value={user}>
		<LocaleInjector>
			<Router history={history}>
				<Switch>
					{/* Pages that you can access without being authenticated */}
					<Route path="/page/:pageId" component={StaticPage} />
					<Route exact path="/feedback/submit/:accessToken" component={SubmitInFeedbackRequest} />

					{/* Pages that require auth */}
					<Route path="/">
						{user == null && <Switch>
							<Route exact path="/magiclink/:token" component={createUseMagicLink(() => fetchUser(setUser))} />
							<Route exact path="/magiclink/reset/:token" component={createUseMagicLink(() => fetchUser(setUser, put('/users/me/forceResetPassword')))} />
							<Route exact path="/reset" component={ResetPassword} />
							<Route exact path="/signup" component={Signup} />
							<Route exact path="/magiclink" component={MagicLinkLogin} />
							<Route component={() => <Login onSuccess={() => fetchUser(setUser)} />} />
						</Switch>}
						<UserDataChangeCounter.Provider value={{ increment: () => { setUserDataCounter(userDataCounter + 1); fetchUser(setUser); }, value: userDataCounter }}>
							{user != null && <UserContext.Provider value={user}>
								<TaxonomyInjector>
									<AppFrame>
										<Switch>
											{/* Student and profile routes */}
											<Route path="/assessments/create/:id" component={withStudentCheck(AssessmentForm)} />
											<Route exact path="/assessments" component={withStudentCheck(MyAssessmentOverview)} />

											<Route exact path="/growth" component={withStudentCheck(GrowthGraph)} />
											<Route exact path="/feedback" component={withStudentCheck(FeedbackOverview)} />
											<Route exact path="/feedback/new" component={withStudentCheck(CreateFeedbackRequest)} />
											<Route exact path="/feedback/view/:feedbackRequestId" component={withStudentCheck(ViewSubmittedFeedback)} />

											<Route exact path="/profile/updateName" component={ProfileNameForm} />
											<Route exact path="/profile/updateLoginDetails" component={ProfileLoginDetailsForm} />
											<Route exact path="/profile/updatePassword" component={ProfileUpdatePassword} />
											<Route exact path="/profile/updateStudentInfo" component={withStudentCheck(ProfileUpdateStudentInfo)} />
											<Route exact path="/profile" component={Profile} />

											<Route exact path="/faq" component={Faq} />

											{/* Teacher routes */}
											<Route exact path="/students" component={withTeacherCheck(StudentOverview)} />
											<Route path="/students/:student/info" component={withTeacherCheck(StudentInformation)} />
											<Route path="/students/:student/assessments/view/:id" component={withTeacherCheck(AssessmentView)} />
											<Route exact path="/students/:student/assessments" component={withTeacherCheck(AssessmentOverviewByStudentRoute)} />
											<Route path="/students/:student/growth" component={withTeacherCheck(GrowthGraphByStudentRoute)} />
											<Route path="/inviteStudents" component={withTeacherCheck(InviteStudents)} />

											{/* School Admin routes */}
											<Route path="/teachers/invite" component={withSchoolAdminCheck(InviteTeacher)} />
											<Route path="/teachers/view/:id" component={withSchoolAdminCheck(TeacherView)} />
											<Route path="/teachers" component={withSchoolAdminCheck(TeacherOverview)} />

											<Route path="/schoolclasses/create" component={withSchoolAdminCheck(SchoolClassEdit)} />
											<Route path="/schoolclasses/edit/:id" component={withSchoolAdminCheck(SchoolClassEdit)} />
											<Route path="/schoolclasses/view/:id" component={withSchoolAdminOrTeacherCheck(SchoolClassView)} />
											<Route path="/schoolclasses" component={withSchoolAdminOrTeacherCheck(SchoolClassesOverview)} />

											<Route path="/myschool" component={withSchoolAdminCheck(MySchoolEdit)} />

											{/* Global Admin routes */}
											<Route exact path="/admin/schools" component={withGlobalAdminCheck(SchoolsOverview)} />
											<Route exact path="/admin/schools/create" component={withGlobalAdminCheck(SchoolEdit)} />
											<Route exact path="/admin/schools/:id" component={withGlobalAdminCheck(SchoolEdit)} />

											<Route exact path="/admin/monitors" component={withGlobalAdminCheck(MonitorsOverview)} />
											<Route exact path="/admin/monitors/create" component={withGlobalAdminCheck(MonitorEdit)} />
											<Route exact path="/admin/monitors/:id" component={withGlobalAdminCheck(MonitorEdit)} />

											<Route exact path="/admin/clusters" component={withGlobalAdminCheck(ClustersOverview)} />
											<Route exact path="/admin/clusters/create" component={withGlobalAdminCheck(ClusterEdit)} />
											<Route exact path="/admin/clusters/:id" component={withGlobalAdminCheck(ClusterEdit)} />

											<Route exact path="/admin/competences" component={withGlobalAdminCheck(CompetencesOverview)} />
											<Route exact path="/admin/competences/create" component={withGlobalAdminCheck(CompetenceEdit)} />
											<Route exact path="/admin/competences/:id" component={withGlobalAdminCheck(CompetenceEdit)} />

											<Route exact path="/admin/users/view/:id" component={withGlobalAdminCheck(UserView)} />
											<Route exact path="/admin/users/edit/:id" component={withGlobalAdminCheck(UserEditLogin)} />
											<Route exact path="/admin/users/invite" component={withGlobalAdminCheck(UserInvite)} />
											<Route exact path="/admin/users" component={withGlobalAdminCheck(UserOverview)} />

											<Route exact path="/admin/pages" component={withGlobalAdminCheck(PagesOverview)} />
											<Route exact path="/admin/pages/create" component={withGlobalAdminCheck(PageEdit)} />
											<Route exact path="/admin/pages/:id" component={withGlobalAdminCheck(PageEdit)} />

											<Route exact path="/admin/taxonomy/terms" component={withGlobalAdminCheck(TermsOverview)} />
											<Route exact path="/admin/taxonomy/terms/create" component={withGlobalAdminCheck(TermEdit)} />
											<Route exact path="/admin/taxonomy/terms/:id" component={withGlobalAdminCheck(TermEdit)} />

											<Route exact path="/admin/faq" component={withGlobalAdminCheck(FaqOverview)} />
											<Route exact path="/admin/faq/faqCategory/create/:id" component={withGlobalAdminCheck(FaqCategoryEdit)} />
											<Route exact path="/admin/faq/faqCategory/edit/:id" component={withGlobalAdminCheck(FaqCategoryEdit)} />
											<Route exact path="/admin/faq/edit/:id" component={withGlobalAdminCheck(FaqItemEdit)} />
											<Route exact path="/admin/faq/create/:catId" component={withGlobalAdminCheck(FaqItemEdit)} />

											<Route exact path="/admin/schoolclasstypes" component={withGlobalAdminCheck(SchoolClassTypeOverview)} />
											<Route exact path="/admin/schoolclasstypes/create" component={withGlobalAdminCheck(SchoolClassTypeEdit)} />
											<Route exact path="/admin/schoolclasstypes/:id" component={withGlobalAdminCheck(SchoolClassTypeEdit)} />

											<Route exact path="/admin" component={withGlobalAdminCheck(AdminOverview)} />

											<Route path="/">
												{user.globalAdmin && <Redirect to="/admin" />}
												{user.schoolAdminInfo && <Redirect to="/teachers" />}
												{user.teacherInfo && <Redirect to="/students" />}
												{user.studentInfo && <Redirect to="/assessments" />}
											</Route>
										</Switch>
									</AppFrame>
								</TaxonomyInjector>
							</UserContext.Provider>}
						</UserDataChangeCounter.Provider>
					</Route>
				</Switch>
			</Router>
		</LocaleInjector>
	</UserContext.Provider>;
}

export default App;
