import React, { Fragment, useEffect, useState } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import InputField from '../../lib/forms/simple/InputField';
import TablePadding from '../../lib/table/TablePadding';
import { get, parameterify } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import Pagination from '../../lib/table/Pagination';
import TableSummary from '../../lib/table/TableSummary';
import SelectField from '../../lib/forms/simple/SelectField';
import usePersistentState from '../../../hooks/usePersistentState';
import RelativeTime from '../../lib/datetime/RelativeTime';
import { injectIntl } from 'react-intl';

const userTypes = [
	{ value: 'ALL', label: 'Alle' },
	{ value: 'STUDENT', label: 'Leerling' },
	{ value: 'TEACHER', label: 'Begeleider' },
	{ value: 'SCHOOLADMIN', label: 'Schoolbeheerder' },
	{ value: 'GLOBALADMIN', label: 'Administrator' },
];

export default injectIntl(({ intl }) => {
	const [ loading, setLoading ] = useState(true);
	const [ userType, setUserType ] = usePersistentState('ALL', 'userOverview.userType');
	const [ page, setPage ] = usePersistentState(1, 'userOverview.page');
	const [ query, setQuery ] = usePersistentState('', 'userOverview.query');
	const [ result, setResult ] = usePersistentState(null, 'userOverview.result');

	useEffect(() => {
		get('/users?' + parameterify({ q: query, page, size: 25, userType }))
			.then(setResult)
			.finally(() => setLoading(false));
	}, [ page, query, userType ]);

	return <Card>
		<CardToolbar title="Gebruikersoverzicht">
			<TertiaryLinkButton label="Uitnodigen" to="/admin/users/invite" icon="plus" />
		</CardToolbar>
		{!loading && result != null && <Fragment>
			<TablePadding>
				<div style={{ display: 'grid', gridTemplateColumns: '1fr minmax(150px, 0.618fr)', gap: 'var(--u-16)' }}>
					<InputField placeholder="Zoeken..." onChange={q => { setPage(1); setQuery(q); }} value={query} type="search" />
					<SelectField options={userTypes} onChange={ut => { setPage(1); setUserType(ut); }} value={userType} />
				</div>
				<TableSummary visible={result.content.length} maxItems={result.totalElements} maxPages={result.totalPages} />
			</TablePadding>
			<table className="table">
				<colgroup>
					<col width="*" />
					<col width="25%" />
					<col width="*" />
					<col width="72px" />
					<col width="72px" />
					<col width="72px" />
					<col width="72px" />
					<col width="1px" />
				</colgroup>
				<thead>
					<tr>
						<th>Naam</th>
						<th>School</th>
						<th>Laatst ingelogd</th>
						<th style={{ textAlign: 'center' }}><abbr title="Leerling">Lln.</abbr></th>
						<th style={{ textAlign: 'center' }}><abbr title="Begeleider">Beg.</abbr></th>
						<th style={{ textAlign: 'center' }}><abbr title="Schooladministrator">SA.</abbr></th>
						<th style={{ textAlign: 'center' }}><abbr title="Globaal Administrator">GA.</abbr></th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{result.content.map(user => <tr key={user.id}>
						<td>{user.fullName}</td>
						<td>{(user.schoolAdminInfo || {}).schoolName || (user.teacherInfo || {}).schoolName || (user.studentInfo || {}).schoolName || '-'}</td>
						<td>{user.lastLogin == null ? 'nooit' : <RelativeTime datetime={user.lastLogin} />}</td>
						<td style={{ textAlign: 'center' }}>{user.studentInfo != null ? <span className="fa fa-check-circle green" /> : <span className="fa fa-times-circle red" />}</td>
						<td style={{ textAlign: 'center' }}>{user.teacherInfo != null ? <span className="fa fa-check-circle green" /> : <span className="fa fa-times-circle red" />}</td>
						<td style={{ textAlign: 'center' }}>{user.schoolAdminInfo != null ? <span className="fa fa-check-circle green" /> : <span className="fa fa-times-circle red" />}</td>
						<td style={{ textAlign: 'center' }}>{user.globalAdmin ? <span className="fa fa-check-circle green" /> : <span className="fa fa-times-circle red" />}</td>
						<td>
							<TertiaryLinkButton to={'/admin/users/view/' + user.id} label="Bekijk" iconRight={true} icon="angle-right" />
						</td>
					</tr>)}
				</tbody>
			</table>
			<Pagination page={page} maxPages={result.totalPages} selectPage={setPage} />
		</Fragment>}
		{loading && <CardLoadingSpinner />}
	</Card>;
});
