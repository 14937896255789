import React from 'react';
import YesNoButtons from './YesNoButtons';
import TaxonomyHtmlRenderer from '../../../taxonomy/TaxonomyHtmlRenderer';

export default function Question({ readOnly, question, onAnswer, value }) {
	return <div className="question">
		<div className="question-text-wrapper">
			<p className="question-text">
				<TaxonomyHtmlRenderer html={question.question} />
			</p>
		</div>
		<div className="question-answer">
			<YesNoButtons disabled={readOnly} onChange={onAnswer} value={value} />
		</div>
	</div>;
}
