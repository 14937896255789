import React, { Fragment } from 'react';
import InfoDot from './InfoDot';

export default function WithLabelRedesign({ info, label, children, dontUseLabelElement = false }) {
	const content = <Fragment>
		{/* Used only when children need to be positioned to the right or next to the label div instead of under it*/}
		<div className="label" style={{ float: "left" }}>{label}&nbsp;&nbsp;{info && <InfoDot msg={info} />}</div>
		{children}
	</Fragment>;

	if (dontUseLabelElement) return <div className="label-wrapper">{content}</div>;
	return <label>{content}</label>;
}
