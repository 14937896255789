import React from 'react';
import CardAccent from '../../../lib/ui/CardAccent';
import Card from '../../../lib/ui/Card';

export default function CompetenceInfoCompact({ competence }) {
	return <Card>
		<CardAccent color={competence.color} />
		<div className="assessment">
			<div className="assessment-compact">
				<h1>{competence.label}</h1>
			</div>
		</div>
	</Card>;
}
