import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { parameterify } from '../../../../util/api';

export default function HtmlField({ value, onChange, defaultHeight = 256, minHeight = 256, maxHeight = 500, images = false }) {
	const [ tinymceLoaded, setTinymceLoaded ] = useState(false);

	useEffect(() => {
		import('../../../../util/tinymce.js').then(() => setTinymceLoaded(true));
	}, []);

	if (!tinymceLoaded) return null;
	return <div className="html-field">
		<Editor
			value={value}
			init={{
				skin_url: process.env.PUBLIC_URL + '/skins/ui/oxide',
				height: defaultHeight,
				min_height: minHeight,
				max_height: maxHeight,
				menubar: false,
				toolbar: 'undo redo | bold italic | alignleft aligncenter alignright alignjustify' + (images ? ' | image' : ''),
				plugins: [ 'lists table wordcount' + (images ? ' image' : '') ],
				automatic_uploads: true,
				file_picker_types: 'image',
				images_file_types: 'jpeg,jpg,png',
				images_dataimg_filter: function(img) {
					return img.hasAttribute('internal-blob');
				},
				images_upload_handler: (blobInfo, success, failure, progress) => {
					const xhr = new XMLHttpRequest();
					xhr.withCredentials = true;
					xhr.open('POST', '/api/files/upload?' + parameterify({ name: blobInfo.filename(), accessLevel: 'SCHOOL' }));

					xhr.upload.onprogress = function(e) {
						progress(e.loaded / e.total * 100);
					};

					xhr.onload = () => {
						if (xhr.status === 403 || xhr.status === 401) return failure('HTTP Error: ' + xhr.status, { remove: true });
						if (xhr.status < 200 || xhr.status >= 300) return failure('HTTP Error: ' + xhr.status);

						const json = JSON.parse(xhr.responseText);

						if (!json) return failure('Invalid JSON: ' + xhr.responseText);
						success('/api/files/serve/' + json.id);
					};

					xhr.onerror = function() {
						failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
					};

					const formData = new FormData();
					formData.append('file', blobInfo.blob(), blobInfo.filename());
					xhr.send(formData);
				},
				file_picker: true,
				// file_picker_callback: () => {},
				imagetools_fetch_image: async(img) => {
					console.log(img);
					return new Blob();
				},
				elementpath: false,
			}}
			onEditorChange={content => onChange(content)} />
	</div>;
}
