import React from 'react';

export default function WithStatusIcon({ className, icon, children }) {
	return <div className={'icon-field ' + (className || '')}>
		<div className="icon-field-content">
			{children}
		</div>
		<div className="icon-field-icon">
			{icon}
		</div>
	</div>;
}
