import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import TimeAgoContext from '../../../context/TimeAgo';

export default injectIntl(({ intl, datetime }) => {
	const timeAgo = useContext(TimeAgoContext);
	const date = new Date(datetime);
	const title = intl.formatDate(date, { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit' });

	return <span className="relative-time" title={title}>
		{timeAgo.format(date)}
	</span>;
});
