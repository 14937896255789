import React from 'react';
import CardAccent from '../../../lib/ui/CardAccent';
import CardPadding from '../../../lib/ui/CardPadding';
import CollapsableArea from '../../../lib/ui/CollapsableArea';
import Card from '../../../lib/ui/Card';
import TaxonomyHtmlRenderer from '../../../taxonomy/TaxonomyHtmlRenderer';
import { useIntl } from 'react-intl';

export default function CompetenceInfo({ competence, teacherMode = false }) {
	const intl = useIntl();
	return <Card>
		<CardAccent color={competence.color} />
		<div className="assessment-grid">
			<CardPadding>
				<h1>{competence.label}</h1>

				<CollapsableArea label={intl.formatMessage({ id: "explanation" })} defaultOpen={!teacherMode}>
					<TaxonomyHtmlRenderer html={competence.explanation} />
				</CollapsableArea>
				<CollapsableArea label={intl.formatMessage({ id: "definition" })}>
					<TaxonomyHtmlRenderer html={competence.definition} />
				</CollapsableArea>
				<CollapsableArea label={intl.formatMessage({ id: "correlation" })}>
					<TaxonomyHtmlRenderer html={competence.associations} />
				</CollapsableArea>
				<CollapsableArea label={intl.formatMessage({ id: "baseLevel" })}>
					<TaxonomyHtmlRenderer html={competence.baseLevelDescription} />
				</CollapsableArea>
			</CardPadding>
			<CardPadding />
		</div>
	</Card>;
}
