import React from 'react';

export default function TabbedCard({ tabs, selected, onSelect, children }) {
	return <div className="card">
		<div className="tab-bar-large">
			{tabs.map(tab => <div key={tab.value} onClick={() => onSelect(tab.value)} className={'tab' + (selected === tab.value ? ' tab-selected' : '')}>{tab.label}</div>)}
			<div className="tab tab-remaining" />
		</div>
		<div className="tab-bar-small">
			<select onChange={e => onSelect(e.target.value)} value={selected}>
				{tabs.map(tab => <option value={tab.value} key={tab.value}>{tab.label}</option>)}
			</select>
		</div>
		<div className="tab-content">
			{children}
		</div>
	</div>;
}
