import React, { useState, useEffect } from 'react';
import { get } from '../../../util/api';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TablePadding from '../../lib/table/TablePadding';

function sortCompetencesLowercase(competences) {
	return competences.sort((a, b) => {
		if (a.name.toLowerCase() == b.name.toLowerCase()) return 0;
		if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
		return 1;
	});
}

export default function CompetencesOverview() {
	const [ competences, setCompetences ] = useState(null);

	useEffect(() => {
		get('/competences').then(m => setCompetences(sortCompetencesLowercase(m)));
	}, []);

	return <Card>
		<CardToolbar title="Competenties beheren">
			<TertiaryLinkButton icon="plus" label="Competentie maken" to="/admin/competences/create" />
		</CardToolbar>

		{competences != null && <TablePadding>
			<span className="text-small">{competences.length} competenties weergegeven</span>
		</TablePadding>}

		<table className="table">
			<thead>
				<tr>
					<th>Competentienaam</th>
					<th>Label</th>
					<th>Versie</th>
					<th></th>
				</tr>
			</thead>
			{competences != null && <tbody>
				{competences.map(c => <tr key={c.id}>
					<td>{c.name}</td>
					<td>{c.label}</td>
					<td>{c.version}</td>
					<td>
						<TertiaryLinkButton iconRight={true} icon="angle-right" label="Bewerk" to={'/admin/competences/' + c.id} />
					</td>
				</tr>)}
			</tbody>}
		</table>
		{competences == null && <CardLoadingSpinner />}
	</Card>;
}
