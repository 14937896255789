import React, { useEffect, useState } from 'react';
import Card from '../lib/ui/Card';
import NavigateAwayPrompt from '../lib/forms/NavigateAwayPrompt';
import CardPadding from '../lib/ui/CardPadding';
import { get, post } from '../../util/api';
import { useIntl } from 'react-intl';
import WithLabel from '../lib/forms/WithLabel';
import InputField from '../lib/forms/simple/InputField';
import ButtonArea from '../lib/forms/ButtonArea';
import Button from '../lib/forms/simple/Button';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import CardAccent from '../lib/ui/CardAccent';
import CollapsableArea from '../lib/ui/CollapsableArea';
import TaxonomyHtmlRenderer from '../taxonomy/TaxonomyHtmlRenderer';
import Modal from '../lib/ui/Modal';
import headerForeground from '../../assets/competentiemonitor/header/header-foreground-paths.svg';
import ModalToolbar from '../lib/ui/ModalToolbar';
import ModalContent from '../lib/ui/ModalContent';

export default function SubmitFeedbackRequest({ match }) {
	const { accessToken } = match.params;
	const [ loading, setLoading ] = useState(true);
	const [ redirect, setRedirect ] = useState(null);
	const [ answers, setAnswers ] = useState({});
	const [ feedbackRequest, setFeedbackRequest ] = useState(null);
	const [ thanksPopup, setThanksPopup ] = useState(false);
	const [ expiredPopup, setExpiredPopup ] = useState(false);
	const [ explanationTextPopup, setExplanationTextPopup ] = useState(false);
	const intl = useIntl();

	useEffect(() => {
		get('/feedbackRequest/token/' + accessToken).then(setFeedbackRequest).catch(() => setExpiredPopup(true)).finally(() => setLoading(false));
	}, [ accessToken ]);

	const handleInputChange = (feedbackId, questionIndex, value) => {
		const updatedAnswers = { ...answers };
		if (!updatedAnswers[feedbackId]) {
			updatedAnswers[feedbackId] = [];
		}
		updatedAnswers[feedbackId][questionIndex] = value;
		setAnswers(updatedAnswers);
	};

	function getCurrentLevelDescription(f) {
		// grade: A -> null; B -> 0; C -> 1; D -> 2; E -> 3; F -> 4; finished (passed F) -> 5
		if (f.grade == null || f.grade == 0 || f.competence.levels == null) { // nothing filled in is the same as level A
			if (f.competence.baseLevelDescriptionAlternative != null) {
				return f.competence.baseLevelDescriptionAlternative; // use alternative description if available (the default one usually contains a call to action, so alternative is preferred)
			} else {
				return f.competence.baseLevelDescription;
			}
		} else {
			return f.competence.levels[f.grade - 1].explanationText;
		}
	}

	return <div className="app">
		<div className="header">
			<div className="header-content">
				<div className="header-left">
					<img
						style={{ paddingTop: 'var(--u-16)' }}
						className="logo"
						src={headerForeground}
						alt="Logo Competentiemonitor" />
				</div>
				<div className="header-right">
					<Button icon="question-circle" label={intl.formatMessage({ id: "whatIsTheCompetentiemonitor" })} onClick={() => setExplanationTextPopup(true)} />
				</div>
			</div>
		</div>
		<div style={{ maxWidth: 'var(--u-1280)', padding: 'var(--u-64)', margin: '0 auto' }}>
			{loading && <CardLoadingSpinner />}
			{!loading && thanksPopup && <Modal>
				<span>{intl.formatMessage({ id: "thanksForLeavingFeedback" })}</span>
			</Modal>}
			{!loading && expiredPopup && <Modal>
				<span>{intl.formatMessage({ id: "feedbackLinkHasExpired" })}</span>
			</Modal>}
			{!loading && explanationTextPopup && <Modal>
				<ModalToolbar title={intl.formatMessage({ id: "theCompetentiemonitor" })} />
				<ModalContent style={{ overflowY: 'visible' }}>
					<span>{intl.formatMessage({ id: "competentiemonitorExplanationText1" })}</span>
					<br />
					<br />
					<span>{intl.formatMessage({ id: "competentiemonitorExplanationText2" })}</span>
				</ModalContent>
				<br />
				<ButtonArea>
					<Button label={intl.formatMessage({ id: "close" })} onClick={() => setExplanationTextPopup(false)} disabled={thanksPopup} />
				</ButtonArea>
			</Modal>}
			{!loading && !thanksPopup && !expiredPopup && <>
				<NavigateAwayPrompt redirect={redirect} />

				<br />
				<h1 style={{ lineHeight: '1.2em', fontSize: '1.5em', paddingBottom: '10px' }}>{feedbackRequest.userName + ' ' + intl.formatMessage({ id: "from" }) + ' ' + feedbackRequest.userSchoolName + ' ' + intl.formatMessage({ id: "hasInvitedYouToGiveFeedback" })}</h1>
				<div>{intl.formatMessage({ id: "pleaseGiveMeFeedback" })}</div>

				<form onSubmit={event => {
					event.preventDefault();
					event.stopPropagation();
					setLoading(true);
					post('/feedback/submit/' + accessToken, { answers }).then(() => {
						setLoading(false);
						setThanksPopup(true);
					}).catch(() => { setLoading(false); setExpiredPopup(true); }); // I assume this is the only thing that goes wrong; shows this even if you came via an invalid token
				}}>
					{feedbackRequest.feedbacks.toSorted((a, b) => { return a.competence.label.localeCompare(b.competence.label); }).map(f => <Card>
						<Card>
							<CardAccent color={f.competence.color} />
							<div className="assessment-grid">
								<CardPadding>
									<h1>{f.competence.label}</h1>
									<CollapsableArea label={intl.formatMessage({ id: "explanation" })} >
										<TaxonomyHtmlRenderer html={f.competence.explanation} />
									</CollapsableArea>
									<CollapsableArea label={intl.formatMessage({ id: "definition" })} >
										<TaxonomyHtmlRenderer html={f.competence.definition} />
									</CollapsableArea>
									<CollapsableArea label={intl.formatMessage({ id: "currentLevel" })} defaultOpen={true} >
										<TaxonomyHtmlRenderer html={getCurrentLevelDescription(f)} />
									</CollapsableArea>
								</CardPadding>
								<CardPadding />
								<CardPadding>
									<WithLabel label={f.question1} >
										<InputField onChange={(text) => handleInputChange(f.id, 0, text)} />
									</WithLabel>
									<WithLabel label={f.question2} >
										<InputField onChange={(text) => handleInputChange(f.id, 1, text)} />
									</WithLabel>
									<WithLabel label={f.question3} >
										<InputField onChange={(text) => handleInputChange(f.id, 2, text)} />
									</WithLabel>
								</CardPadding>
							</div>
						</Card>
					</Card>)}

					<ButtonArea>
						<Button icon="paper-plane" label={intl.formatMessage({ id: "sendMail" })} type="submit" disabled={thanksPopup} />
					</ButtonArea>
				</form>
			</>}
		</div>
	</div>;
}