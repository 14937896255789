import React, { useState, useEffect } from 'react';
import { get } from '../../../util/api';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TablePadding from '../../lib/table/TablePadding';

function sortClustersLowercase(clusters) {
	return clusters.sort((a, b) => {
		if (a.name.toLowerCase() == b.name.toLowerCase()) return 0;
		if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
		return 1;
	});
}

export default function ClustersOverview() {
	const [ clusters, setClusters ] = useState(null);

	useEffect(() => {
		get('/clusters').then(m => setClusters(sortClustersLowercase(m)));
	}, []);

	return <Card>
		<CardToolbar title="Clusters beheren">
			<TertiaryLinkButton icon="plus" label="Cluster maken" to="/admin/clusters/create" />
		</CardToolbar>

		{clusters != null && <TablePadding>
			<span className="text-small">{clusters.length} clusters weergegeven</span>
		</TablePadding>}

		<table className="table">
			<thead>
				<tr>
					<th>Clusternaam</th>
					<th>Label</th>
					<th>Versie</th>
					<th></th>
				</tr>
			</thead>
			{clusters != null && <tbody>
				{clusters.map(c => <tr key={c.id}>
					<td>{c.name}</td>
					<td>{c.label}</td>
					<td>{c.version}</td>
					<td>
						<TertiaryLinkButton iconRight={true} icon="angle-right" label="Bewerk" to={'/admin/clusters/' + c.id} />
					</td>
				</tr>)}
			</tbody>}
		</table>
		{clusters == null && <CardLoadingSpinner />}
	</Card>;
}
