import React, { useState } from 'react';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import WithLabel from '../../lib/forms/WithLabel';
import CardPadding from '../../lib/ui/CardPadding';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import MaxWidth from '../../lib/ui/MaxWidth';
import CenterHorizontal from '../../lib/ui/CenterHorizontal';
import { post } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import WithValidation from '../../lib/forms/WithValidation';
import ErrorMessage from '../../lib/forms/ErrorMessage';
import { FormattedMessage, useIntl } from 'react-intl';

export default function SignupCodeEntry({ onFix }) {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ signupCode, setSignupCode ] = useState('');
	const intl = useIntl();

	const signupValid = signupCode.length == 6;

	return <CenterHorizontal>
		<MaxWidth width="var(--u-512)">
			<Card>
				<form onSubmit={e => {
					e.preventDefault();
					e.stopPropagation();
					setLoading(true);
					post('/users/reattach/' + signupCode)
						.then(onFix)
						.catch(err => {
							if (err.status == 404) {
								setError(<FormattedMessage id="invalidTeacherCode" />);
							} else {
								setError(<FormattedMessage id="unknownError" />);
							}
						})
						.finally(() => setLoading(false));
					return false;
				}}>
					<CardToolbar title={intl.formatMessage({ id: "linkAccount" })} />
					<CardPadding>
						{error && <ErrorMessage error={error} />}

						<p><FormattedMessage id="accountNotLinkedEnterCode" /></p>
						<p><FormattedMessage id="toRegisterAsSupervisorContactAdmin" /></p>
						<WithLabel label={intl.formatMessage({ id: "teacherCode" })}>
							<WithValidation valid={signupValid}>
								<InputField onChange={setSignupCode} value={signupCode} />
							</WithValidation>
						</WithLabel>

						<ButtonArea>
							<Button disabled={!signupValid} type="submit" icon="check" label={intl.formatMessage({ id: "continue" })} />
						</ButtonArea>
					</CardPadding>
				</form>
				{loading && <CardLoadingSpinner />}
			</Card>
		</MaxWidth>
	</CenterHorizontal>;
}
