import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function YesNoButtons({ value, onChange, disabled = false }) {
	return <div className="yes-no-btn-group btn-group">
		<button disabled={disabled} className={'btn btn-yes' + (value === true ? ' btn-active' : '')} onClick={() => onChange(true)}>
			<span><FormattedMessage id="yes" /></span>
		</button>
		<button disabled={disabled} className={'btn btn-no' + (value === false ? ' btn-active' : '')} onClick={() => onChange(false)}>
			<span><FormattedMessage id="no" /></span>
		</button>
	</div>;
}
