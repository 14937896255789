import React from 'react';
import WithStatusIcon from '../WithStatusIcon';

export default function InputField({ type = 'text', label, onChange, value, placeholder, ...props }) {
	const field = <input
		className="form-element"
		type={type}
		onChange={e => onChange(e.target.value)}
		value={value}
		placeholder={placeholder}
		{...props} />;

	if (props.disabled) {
		return <WithStatusIcon icon={<span className="fa fa-ban" />}>
			{field}
		</WithStatusIcon>;
	} else {
		return field;
	}
}
