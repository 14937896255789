import React, { useEffect, useState, Fragment } from 'react';
import { get, del, put } from '../../../util/api';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import FormRow from '../../lib/forms/layout/FormRow';
import ConfirmPopup from '../../lib/forms/confirmation/ConfirmPopup';
import ButtonArea from '../../lib/forms/ButtonArea';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import Button from '../../lib/forms/simple/Button';
import WithLabel from '../../lib/forms/WithLabel';
import { FormattedDate, FormattedTime } from 'react-intl';

export default function UserEditLogin({ match, history }) {
	const userId = match.params.id;
	const [ loading, setLoading ] = useState(true);
	const [ user, setUser ] = useState(null);

	const [ confirmDelete, setConfirmDelete ] = useState(false);
	const [ confirmUpgradeToSchoolAdmin, setConfirmUpgradeToSchoolAdmin ] = useState(false);
	const [ confirmDowngradeFromSchoolAdmin, setConfirmDowngradeFromSchoolAdmin ] = useState(false);

	useEffect(() => {
		get('/users/' + userId)
			.then(setUser)
			.finally(() => setLoading(false));
	}, [ userId ]);

	return <Card>
		{user != null && <CardPadding>
			<FormRow title="Naam">
				<span>{user.fullName}</span>
			</FormRow>
			<FormRow
				title="Inloggegevens"
				description="Als administrator kun je de gebruikersnaam en het gekoppelde e-mailadres van deze gebruiker bewerken"
				customInfo={<TertiaryLinkButton to={'/admin/users/edit/' + user.id} label="Bewerken" iconRight={true} icon="angle-right" />}>
				<span>{user.username}</span><br />
				<span>{user.email}</span><br /><br />
				<WithLabel label="Laatste keer ingelogd">
					<div style={{ paddingLeft: '4px', marginTop: '-8px', marginBottom: '8px' }}>
						<span>{user.lastLogin
							? <Fragment><FormattedDate value={new Date(user.lastLogin)} dateStyle="short" />&nbsp;<FormattedTime value={new Date(user.lastLogin)} /></Fragment>
							: 'nooit'}
						</span>
					</div>
				</WithLabel>
				<WithLabel label="Aangemaakt">
					<div style={{ paddingLeft: '4px', marginTop: '-8px' }}>
						<span>{user.createdAt
							? <Fragment><FormattedDate value={new Date(user.createdAt)} dateStyle="short" />&nbsp;<FormattedTime value={new Date(user.createdAt)} /></Fragment>
							: 'nooit'}
						</span>
					</div>
				</WithLabel>
			</FormRow>
			{user.studentInfo && <FormRow
				title="Leerlinggegevens">
				<span>{user.studentInfo.studentNumber || 'Geen leerlingnummer bekend'}</span><br />
				<span>{(user.studentInfo.birthDate || '').split('-').reverse().join('-') || 'Geen geboortedatum bekend'}</span><br />
				<span>{user.studentInfo.schoolClassName || 'Geen klas'} - {user.studentInfo.schoolName}</span>
			</FormRow>}
			{user.teacherInfo && <FormRow
				title="Begeleidergegevens">
				<span>{user.teacherInfo.schoolName || 'Geen school bekend'}</span><br />
			</FormRow>}
			{user.schoolAdminInfo && <FormRow
				title="Schoolbeheerder">
				<span>{user.schoolAdminInfo.schoolName || 'Onbekende school'}</span><br />
			</FormRow>}
			{user.globalAdmin && <FormRow
				title="Administrator">
				<span>Deze gebruiker is administrator</span><br />
			</FormRow>}
			<FormRow title="Gevarenzone">
				<ConfirmPopup
					open={confirmUpgradeToSchoolAdmin}
					onConfirm={() => {
						put('/users/' + user.id + '/schoolAdmin?admin=true')
							.finally(() => {
								get('/users/' + userId).then(setUser);
								setConfirmUpgradeToSchoolAdmin(false);
							});
					}}
					onCancel={() => setConfirmUpgradeToSchoolAdmin(false)}
					formComponent={({ onCancel, onConfirm }) => <Card>
						<CardToolbar title="Schoolbeheerder maken" />
						<CardPadding>
							<p>Hiermee maak je deze gebruiker een schoolbeheerder, en geef je deze persoon rechten om docenten uit te nodigen of te verwijderen.</p>
							<ButtonArea>
								<TertiaryButton label="Annuleren" onClick={onCancel} />
								<Button icon="check" label="Doorgaan" onClick={onConfirm} />
							</ButtonArea>
						</CardPadding>
					</Card>} />

				<ConfirmPopup
					open={confirmDowngradeFromSchoolAdmin}
					onConfirm={() => {
						put('/users/' + user.id + '/schoolAdmin?admin=false')
							.finally(() => {
								get('/users/' + userId).then(setUser);
								setConfirmDowngradeFromSchoolAdmin(false);
							});
					}}
					onCancel={() => setConfirmDowngradeFromSchoolAdmin(false)}
					formComponent={({ onCancel, onConfirm }) => <Card>
						<CardToolbar title="Schoolbeheerdersrechten intrekken" />
						<CardPadding>
							<p>Hiermee trek je de rechten in van deze schoolbeheerder.</p>
							<ButtonArea>
								<TertiaryButton label="Annuleren" onClick={onCancel} />
								<Button icon="check" label="Doorgaan" onClick={onConfirm} />
							</ButtonArea>
						</CardPadding>
					</Card>} />

				{user.teacherInfo && !user.schoolAdminInfo && <Fragment>
					<TertiaryButton label="Schoolbeheerder maken" icon="arrow-up" onClick={() => setConfirmUpgradeToSchoolAdmin(true)} />
					<br /><br />
				</Fragment>}

				{user.teacherInfo && user.schoolAdminInfo && <Fragment>
					<TertiaryButton label="Schoolbeheerdersrechten intrekken" icon="arrow-down" onClick={() => setConfirmDowngradeFromSchoolAdmin(true)} />
					<br /><br />
				</Fragment>}

				<ConfirmPopup
					open={confirmDelete}
					onConfirm={() => {
						del('/users/' + userId)
							.then(() => history.push('/admin/users'))
							.finally(() => setConfirmDelete(false));
					}}
					onCancel={() => setConfirmDelete(false)}
					formComponent={({ onCancel, onConfirm }) => <Card>
						<CardToolbar title="Account verwijderen" />
						<CardPadding>
							<p>Je verwijdert hiermee dit gebruikersaccount. Alle gekoppelde gegevens, zoals ingevulde competenties, worden definitief verwijderd. Deze actie kan niet ongedaan gemaakt worden.</p>
							<ButtonArea>
								<TertiaryButton label="Annuleren" onClick={onCancel} />
								<Button style={{ background: 'var(--col-red)' }} icon="trash" label="Verwijderen" onClick={onConfirm} />
							</ButtonArea>
						</CardPadding>
					</Card>} />
				<TertiaryButton label="Account Verwijderen" icon="trash" onClick={() => setConfirmDelete(true)} />
			</FormRow>

		</CardPadding>}
		{loading && <CardLoadingSpinner/>}
	</Card>;
}
