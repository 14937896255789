import React from 'react';
import WithStatusIcon from './WithStatusIcon';

export default function WithValidation({ valid, icon = true, children }) {
	return <WithStatusIcon
		className={'validated-field ' + (valid ? 'validated-field-valid' : 'validated-field-invalid')}
	    icon={!icon ? <span /> : (valid ? <span className="fa fa-check-circle green" /> : <span className="fa fa-times-circle red" />)}>
		{children}
	</WithStatusIcon>;
}
