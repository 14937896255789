import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { get } from '../../../../util/api';

function teacherToRsElement(teacher) {
	return { label: teacher.fullName, value: teacher.id };
}

export default function PotentialTeachersField({ onChange, value, excluding, ...props }) {
	const [ teachers, setTeachers ] = useState(null);
	const [ teachersById, setTeachersById ] = useState({});

	useEffect(() => {
		get('/me/student/studentTeacherRelations/potentialTeachers').then(teachers => {
			setTeachers(teachers);
			setTeachersById(teachers.reduce((tot, elem) => ({ ...tot, [elem.id]: elem }), {}));
		});
	}, []);

	const excludingMap = excluding.reduce((tot, elem) => ({ ...tot, [elem]: true }), {});

	return <Select
		{...props}
		options={teachers == null ? [] : teachers.filter(c => !excludingMap[c.id]).map(teacherToRsElement)}
		onChange={v => onChange(v == null ? null : teachersById[v.value])}
		value={value == null ? null : teacherToRsElement(teachersById[value])}
		className="react-select"
		classNamePrefix="react-select" />;
}
