import React, { useContext, useEffect, useRef, useState } from 'react';
import UserContext from '../../context/User';
import { get } from '../../util/api';
import Card from '../lib/ui/Card';
import CardToolbar from '../lib/ui/CardToolbar';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CardPadding from '../lib/ui/CardPadding';
import moment from 'moment';
import WithLabel from '../lib/forms/WithLabel';
import SelectField from '../lib/forms/simple/SelectField';
import Button from '../lib/forms/simple/Button';
import TertiaryButton from '../lib/forms/simple/TertiaryButton';
import GraphOverlay from './GraphOverlay';
import html2canvas from 'html2canvas';
import ButtonArea from '../lib/forms/ButtonArea';
import { FormattedMessage, useIntl } from 'react-intl';

const grades = [ 'A', 'B', 'C', 'D', 'E', 'F' ];

function getDataForCompetence(assessments, competence) {
	return assessments
		.filter(a => competence != null && a.competenceId == competence)
		.map(a => ({ time: new Date(a.createdAt).getTime(), grade: a.grade }))
		.sort((a, b) => a.time - b.time);
}

export default function GrowthGraph({ studentId }) {
	const user = useContext(UserContext);
	const [ monitor, setMonitor ] = useState(null);
	const [ assessments, setAssessments ] = useState(null);
	const [ competence, setCompetence ] = useState(null);
	const [ competences, setCompetences ] = useState([]);
	const [ selectedCluster, setSelectedCluster ] = useState(null);
	const [ starSet, setStarSet ] = useState(new Set([]));
	const [ flagSet, setFlagSet ] = useState(new Set([]));
	const intl = useIntl();

	const growthGraphScreenshotRef = useRef(null);

	useEffect(() => {
		const sid = studentId || user.studentInfo.id;
		get('/assessments/student/' + sid + '/all').then(setAssessments);
		get('/monitors/byStudent/' + sid).then(setMonitor);
		get('/stars/student/' + sid).then(stars => setStarSet(new Set(stars.map(s => s.competenceId))));
		get('/focus/student/' + sid).then(flags => setFlagSet(new Set(flags.map(f => f.competenceId))));
	}, []);

	if (monitor == null || assessments == null) return null;


	return <Card>
		<CardToolbar title={intl.formatMessage({ id: "progress" })} />
		<CardPadding>
			<WithLabel dontUseLabelElement={true} label={intl.formatMessage({ id: "selectedCompetences" })}>
				<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
					{competences.map(c => <div className="tag" key={c.id}>
						<span>{c.label}</span>
						{starSet.has(c.id) && <span>&nbsp;<span className="fa fa-star" style={{ color: '#ffc400' }} /></span>}
						{flagSet.has(c.id) && <span>&nbsp;<span className="fa fa-flag" style={{ color: '#3DB814' }} /></span>}
						<TertiaryButton icon="times" onClick={() => setCompetences(competences.filter(cc => c.id != cc.id))} />
					</div>)}
					{competences.length == 0 && <div style={{ width: '1px', height: '56px' }} />}
				</div>
				<div className="grid-2-1">
					<WithLabel label={intl.formatMessage({ id: "addCompetence" })}>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 'var(--u-16)' }}>
							<SelectField
								placeholder={intl.formatMessage({ id: "selectCompetence" })}
								onChange={setCompetence}
								value={competence}
								options={monitor.competences
									.map(c => ({ value: c.id, label: <span>{c.label}{starSet.has(c.id) && <span>&nbsp;<span className="fa fa-star" style={{ color: '#ffc400' }} /></span>}{flagSet.has(c.id) && <span>&nbsp;<span className="fa fa-flag" style={{ color: '#3DB814' }} /></span>}</span>, disabled: competences.includes(c) || getDataForCompetence(assessments, c.id).length < 1 }))
									.sort((a, b) => (a.label < b.label ? -1 : (a.label == b.label ? 0 : 1)))} />
							<Button icon="plus" label={intl.formatMessage({ id: "add" })} disabled={competence == null} onClick={() => { setCompetence(null); setCompetences([ ...competences.filter(c => c.id != competence), monitor.competences.find(c => c.id == competence) ]); }} />
						</div>
					</WithLabel>
					<WithLabel label={intl.formatMessage({ id: "addCluster" })}>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 'var(--u-16)' }}>
							<SelectField
								onChange={setSelectedCluster}
								value={selectedCluster}
								placeholder={intl.formatMessage({ id: "selectCluster" })}
								options={monitor.clusters
									.map(c => ({ value: c.id, label: c.label }))} />
							<Button icon="plus" label={intl.formatMessage({ id: "add" })} disabled={selectedCluster == null} onClick={() => { setSelectedCluster(null); setCompetences(monitor.clusters.find(clu => clu.id == selectedCluster).competences.map(c => monitor.competences.find(com => com.id == c.id)).filter(c => c != null && getDataForCompetence(assessments, c.id).length >= 1)); }} />
						</div>
					</WithLabel>
				</div>
			</WithLabel>

			<WithLabel label={intl.formatMessage({ id: "progressGraph" })}>
				<div className="growth-graph-screenshot-container" ref={growthGraphScreenshotRef} style={{ paddingTop: 'var(--u-8)' }}>
					<GraphOverlay message={competences.length == 0 ? <FormattedMessage id="selectCompetenceAbove" /> : null}>
						<ResponsiveContainer height={400}>
							<LineChart>
								<CartesianGrid strokeDasharray="3 3" />
								<XAxis
									dataKey="time"
									domain={[ 'dataMin', 'dataMax' ]}
									name="Time"
									tickFormatter={time => {
										const d = new Date(time);
										return moment(new Date(time)).format('DD-MM-YYYY');
									}}
									scale="time"
									tickMargin={12}
									tick={{ fontSize: 14 }}
									tickCount={3}
									type="number"
									allowDuplicatedCategory={false} />
								<YAxis
									domain={[ 0, 5 ]}
									tickCount={6}
									tickFormatter={t => grades[t]}/>
								<Legend />
								<Tooltip
									separator={': '}
									labelFormatter={label => moment.unix(label / 1000).format('DD-MM-YYYY')}
									formatter={(value, name) => {
										return [ grades[value], name ];
									}} />
								{competences.map(c => {
									const data = assessments.filter(a => a.competenceId == c.id).map(a => ({ time: new Date(a.createdAt).getTime(), grade: a.grade })).sort((a, b) => a.time - b.time);
									return <Line
										key={c.id}
										dataKey="grade"
										name={c.label}
										animationDuration={Math.min(data.length, 5) * 100}
										dot={{ r: 5, strokeWidth: 2 }} activeDot={{ strokeWidth: 2, r: 3 }} strokeWidth={2}
										data={data}
										stroke={c.levels[c.levels.length - 1].color} />;
								})}
							</LineChart>
						</ResponsiveContainer>
					</GraphOverlay>
				</div>
			</WithLabel>
			<div>
				<ButtonArea>
					<Button
						icon="picture-o"
						label={intl.formatMessage({ id: "downloadThisGraph" })}
						disabled={competences.length == 0}
						onClick={() => {
							html2canvas(growthGraphScreenshotRef.current).then(canvas => {
								const link = document.createElement('a');
								link.download = 'ontwikkelingsgrafiek.png';
								link.href = canvas.toDataURL('image/png');
								link.click();
							});
						}} />
				</ButtonArea>
			</div>
		</CardPadding>
	</Card>;
}
