import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { get } from '../../../../util/api';

function schoolClassTypeToRsElement(schoolClassType) {
	if (schoolClassType == null) return null;
	return { label: schoolClassType.name, value: schoolClassType.id };
}

export default function SchoolClassTypeField({ onChange, value, excluding = [], ...props }) {
	const [ schoolClassTypes, setSchoolClassTypes ] = useState(null);
	const [ schoolClassTypesById, setSchoolClassTypesById ] = useState({});

	useEffect(() => {
		get('/schoolClassTypes').then(scts => {
			setSchoolClassTypes(scts);
			setSchoolClassTypesById(scts.reduce((tot, elem) => ({ ...tot, [elem.id]: elem }), {}));
		});
	}, []);

	const excludingMap = excluding.reduce((tot, elem) => ({ ...tot, [elem]: true }), {});

	return <Select
		{...props}
		options={schoolClassTypes == null ? [] : schoolClassTypes.filter(c => !excludingMap[c.id]).map(schoolClassTypeToRsElement)}
		onChange={v => onChange(v == null ? null : schoolClassTypesById[v.value])}
		value={value == null ? null : schoolClassTypeToRsElement(schoolClassTypesById[value])}
		className="react-select"
		classNamePrefix="react-select" />;
}
