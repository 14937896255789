import React from 'react';
import ReactHtmlParser from 'react-html-parser';

export default function Term({ zIndex, text, description }) {
	return <span className="term" style={{ zIndex }}>
		<span className="term-text">{text}</span>
		<div className="term-popup-sticky-wrapper">
			<div className="term-popup-sticky">
				<div className="term-popup">
					{ReactHtmlParser(description)}
				</div>
			</div>
		</div>
		<div className="term-arrow-container">
			<div className="term-arrow" />
		</div>
	</span>;
}
