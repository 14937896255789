import React, { useContext } from 'react';
import UserContext from '../../context/User';
import { Redirect } from 'react-router';

export default function withAuthCheck(checkFn, Component) {
	return function WithAuthCheckWrapper(props) {
		const user = useContext(UserContext);

		if (!checkFn(user)) return <Redirect to="/" />;

		return <Component {...props} />;
	};
}
