import React, { useState } from 'react';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import WithLabel from '../../lib/forms/WithLabel';
import CardPadding from '../../lib/ui/CardPadding';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import MaxWidth from '../../lib/ui/MaxWidth';
import CenterHorizontal from '../../lib/ui/CenterHorizontal';
import PotentialSchoolClassesField from '../../lib/forms/complex/PotentialSchoolClassesField';
import { put } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import { Redirect } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';

export default function SchoolClassSelection({ onFix }) {
	const [ loading, setLoading ] = useState(false);
	const [ selectedClass, setSelectedClass ] = useState(null);
	const intl = useIntl();

	return <CenterHorizontal>
		<Redirect to="/profile" />
		<MaxWidth width="var(--u-384)">
			<Card>
				<form onSubmit={e => {
					e.preventDefault();
					e.stopPropagation();
					setLoading(true);
					put('/me/student/potentialSchoolClasses?schoolClassId=' + selectedClass, null, false)
						.then(onFix)
						.finally(() => setLoading(false));
					return false;
				}}>
					<CardToolbar title={intl.formatMessage({ id: "chooseClass" })} />
					<CardPadding>
						<WithLabel label={intl.formatMessage({ id: "class" })}>
							<PotentialSchoolClassesField value={selectedClass} onChange={schoolClass => setSelectedClass(schoolClass.id)} />
						</WithLabel>

						<p><FormattedMessage id="classSelectDescription" /></p>

						<ButtonArea>
							<Button disabled={selectedClass == null} type="submit" icon="check" label={intl.formatMessage({ id: "continue" })} />
						</ButtonArea>
					</CardPadding>
				</form>
				{loading && <CardLoadingSpinner />}
			</Card>
		</MaxWidth>
	</CenterHorizontal>;
}
