import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../context/User';
import { get, put } from '../../../util/api';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import CardPadding from '../../lib/ui/CardPadding';
import Card from '../../lib/ui/Card';
import FormRow from '../../lib/forms/layout/FormRow';
import HtmlField from '../../lib/forms/simple/HtmlField';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import WithValidation from '../../lib/forms/WithValidation';
import { useIntl } from 'react-intl';

export function dayMonthValid(dayInput, monthInput) {
	// Typecheck
	if (typeof dayInput != 'number' && isNaN(parseInt(dayInput, 10))) return false;
	if (typeof monthInput != 'number' && isNaN(parseInt(monthInput, 10))) return false;

	// Cast to number
	const day = typeof dayInput == 'number' ? dayInput : parseInt(dayInput, 10);
	const month = typeof monthInput == 'number' ? monthInput : parseInt(monthInput, 10);

	// Date range check (29th of feb is also disallowed)
	if ([ 1, 3, 5, 7, 8, 10, 12 ].includes(month)) return day >= 1 && day <= 31;
	if ([ 4, 6, 9, 11 ].includes(month)) return day >= 1 && day <= 30;
	if ([ 2 ].includes(month)) return day >= 1 && day <= 28;
	return false;
}

export default function MySchoolEdit({ history }) {
	const user = useContext(UserContext);
	const [ loading, setLoading ] = useState(true);

	const [ name, setName ] = useState(null);
	const [ resetDay, setResetDay ] = useState(null);
	const [ resetMonth, setResetMonth ] = useState(null);
	const [ textField1, setTextField1 ] = useState(null);
	const [ textField2, setTextField2 ] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		if (user.schoolAdminInfo == null) return;
		get('/schools/' + user.schoolAdminInfo.schoolId).then(s => {
			setName(s.name);
			setResetDay(s.resetDay);
			setResetMonth(s.resetMonth);
			setTextField1(s.textField1);
			setTextField2(s.textField2);
		}).finally(() => setLoading(false));
	}, [ user.id ]);

	const schoolNameValid = name != null && name.length > 0;
	const resetMomentValid = dayMonthValid(resetDay, resetMonth);

	return <Card>
		<CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				setLoading(true);
				put('/schools/' + user.schoolAdminInfo.schoolId, { name, resetDay, resetMonth, textField1, textField2 }).then(() => history.push('/'));
				return false;
			}}>
				<FormRow title={intl.formatMessage({ id: "school" })}>
					<WithLabel label={intl.formatMessage({ id: "schoolName" })}>
						<WithValidation valid={schoolNameValid}>
							<InputField onChange={setName} value={name} />
						</WithValidation>
					</WithLabel>
				</FormRow>
				<FormRow title={intl.formatMessage({ id: "classReset" })} description={intl.formatMessage({ id: "classResetExplanation" })}>
					<WithLabel label={intl.formatMessage({ id: "resetDate" })}>
						<div style={{ display: 'grid', gridTemplateColumns: '64px 16px 64px 48px' }}>
							<div>
								<InputField type="number" onChange={setResetDay} value={resetDay} />
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: 'var(--u-16)', color: 'var(--col-grey-600)' }}>
								<span>/</span>
							</div>
							<div>
								<InputField type="number" onChange={setResetMonth} value={resetMonth} />
							</div>
							<WithValidation valid={resetMomentValid}>
								<div />
							</WithValidation>
						</div>
					</WithLabel>
				</FormRow>
				<FormRow title={intl.formatMessage({ id: "footer" })}>
					<WithLabel label={intl.formatMessage({ id: "leftColumn" })}>
						<HtmlField onChange={setTextField1} value={textField1} images={true} />
					</WithLabel>
					<WithLabel label={intl.formatMessage({ id: "rightColumn" })}>
						<HtmlField onChange={setTextField2} value={textField2} images={true} />
					</WithLabel>
				</FormRow>
				<ButtonArea>
					<Button disabled={!schoolNameValid || !resetMomentValid} type="submit" label={intl.formatMessage({ id: "save" })} icon="check" />
				</ButtonArea>
			</form>
		</CardPadding>
		{loading && <CardLoadingSpinner />}
	</Card>;
}
