import React, { useState } from 'react';
import { post } from '../../util/api';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

function logout(history) {
	post('/whoami/logout', null, false).finally(() => {
		history.push('/');
		window.location.reload(true);
		window.sessionStorage.clear();
	});
}

export default withRouter(function UserInfo({ history, user }) {
	const [ visibleSmall, setVisibleSmall ] = useState(false);

	if (user == null) return null;

	return <div className={'user-info' + (visibleSmall ? ' user-info-visible-small' : '')}>
		<div className="user-info-content">
			<div>
				<Link to="/profile"><span className="user-info-name">{user.fullName}</span></Link><br />
				<div className="user-info-secondary">
					<span className="user-info-school">{(user.studentInfo || user.teacherInfo || {}).schoolName || ''}</span><br />
					<span className="user-info-schoolclass">{(user.studentInfo || user.teacherInfo || {}).schoolClassName || ''}</span>
				</div>
				<div className="user-info-actions">
					<a href="#" onClick={logout.bind(this, history)}>Uitloggen</a>
				</div>
			</div>
		</div>
		<div className="user-info-icon">
			<div className="user-info-icon-circle" onClick={() => setVisibleSmall(!visibleSmall)}>
				<span className="fa fa-user" />
			</div>
		</div>
		<div className="user-info-logout-icon">
			<div className="user-info-logout-icon-circle" onClick={logout.bind(this, history)}>
				<span className="fa fa-sign-out" />
			</div>
		</div>
	</div>;
});
