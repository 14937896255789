import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { get } from '../../../../util/api';

function schoolToRsElement(school) {
	if (school == null) return null;
	return { label: school.name, value: school.id };
}

export default function SchoolField({ placeholder = 'School zoeken...', onChange, value, excluding = [], ...props }) {
	const [ schools, setSchools ] = useState(null);
	const [ schoolsById, setSchoolsById ] = useState({});

	useEffect(() => {
		get('/schools').then(schools => {
			setSchools(schools);
			setSchoolsById(schools.reduce((tot, elem) => ({ ...tot, [elem.id]: elem }), {}));
		});
	}, []);

	const excludingMap = excluding.reduce((tot, elem) => ({ ...tot, [elem]: true }), {});

	return <Select
		{...props}
		placeholder={placeholder}
		options={schools == null ? [] : schools.filter(c => !excludingMap[c.id]).map(schoolToRsElement)}
		onChange={v => onChange(v == null ? null : schoolsById[v.value])}
		value={value == null ? null : schoolToRsElement(schoolsById[value])}
		className="react-select"
		classNamePrefix="react-select" />;
}
