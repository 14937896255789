import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../context/User';
import CardToolbar from '../../lib/ui/CardToolbar';
import TablePadding from '../../lib/table/TablePadding';
import Card from '../../lib/ui/Card';
import { get } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import InputField from '../../lib/forms/simple/InputField';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import { FormattedMessage, useIntl } from 'react-intl';

export default function TeacherOverview() {
	const user = useContext(UserContext);
	const [ loading, setLoading ] = useState(true);
	const [ query, setQuery ] = useState('');
	const [ teachers, setTeachers ] = useState([]);
	const intl = useIntl();

	useEffect(() => {
		get('/schools/' + user.schoolAdminInfo.schoolId + '/teachers')
			.then(setTeachers)
			.finally(() => setLoading(false));
	}, [ user.schoolAdminInfo.schoolId ]);

	const results = teachers.filter(t => t.fullName.toLowerCase().includes(query.toLowerCase()));

	return <Card>
		<CardToolbar title={intl.formatMessage({ id: "supervisors" })}>
			<TertiaryLinkButton to="/teachers/invite" label={intl.formatMessage({ id: "inviteSupervisor" })} icon="plus" />
		</CardToolbar>
		<TablePadding>
			<InputField placeholder={intl.formatMessage({ id: "search" })} onChange={setQuery} value={query} type="search" />
			<span className="text-small">{results.length} <FormattedMessage id="supervisorsShown" /></span>
		</TablePadding>
		<table className="table">
			<colgroup>
				<col width="*" />
				<col width="*" />
				<col width="*" />
				<col width="1" />
			</colgroup>
			<thead>
				<tr>
					<th><FormattedMessage id="name" /></th>
					<th><FormattedMessage id="email" /></th>
					<th><FormattedMessage id="administrator" /></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{results.map(t => <tr key={t.id}>
					<td>{t.fullName}</td>
					<td>{t.email}</td>
					<td>{t.schoolAdminInfo != null ? <span className="fa fa-check-circle green" /> : <span />}</td>
					<td>
						<TertiaryLinkButton icon="angle-right" iconRight={true} label={intl.formatMessage({ id: "view" })} to={'/teachers/view/' + t.id} />
					</td>
				</tr>)}
			</tbody>
		</table>
		{loading && <CardLoadingSpinner />}
	</Card>;
}
