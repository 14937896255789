import React from 'react';
import Modal from 'react-modal';
import CardToolbar from '../../ui/CardToolbar';
import ButtonArea from '../ButtonArea';
import TertiaryButton from '../simple/TertiaryButton';
import Button from '../simple/Button';
import Card from '../../ui/Card';
import CardPadding from '../../ui/CardPadding';
import MaxWidth from '../../ui/MaxWidth';
import CenterHorizontal from '../../ui/CenterHorizontal';

const styles = {
	overlay: {
		background: 'rgba(0, 0, 0, 0.85)',
		zIndex: 10,
	},
	content: {
		background: 'none',
		border: 'none',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		padding: 'var(--u-4)',
	},
};

const defaultFormComponent = ({ onCancel, onConfirm }) => {
	return <Card>
		<CardToolbar title="Bevestigen" />
		<CardPadding>
			<p>Weet je zeker dat je dit wilt doen? Deze actie is niet ongedaan te maken.</p>
			<ButtonArea>
				<TertiaryButton label="Annuleren" onClick={onCancel} />
				<Button label="Bevestigen" onClick={onConfirm} />
			</ButtonArea>
		</CardPadding>
	</Card>;
};

export default function ConfirmPopup({ open, onCancel, onConfirm, formComponent = defaultFormComponent }) {
	const FormComp = formComponent;
	return <Modal isOpen={open} onRequestClose={onCancel} style={styles} closeTimeoutMS={150}>
		<CenterHorizontal>
			<MaxWidth width="var(--u-512)">
				<FormComp onCancel={onCancel} onConfirm={onConfirm} />
			</MaxWidth>
		</CenterHorizontal>
	</Modal>;
}
