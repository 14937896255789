function api(method, uri, body, processJson = true) {
	const forceProcessJson = method == 'DELETE' ? false : processJson;
	const headers = new Headers();
	if (body != null) headers.append('Content-Type', 'application/json');

	return fetch('/api' + uri, {
		method: (method || 'GET').toLowerCase(),
		body: body ? JSON.stringify(body) : body,
		headers,
	}).then(r => {
		if (r.status >= 400) throw r;
		return forceProcessJson ? r.json() : r;
	});
}

export const get = api.bind(null, 'GET');
export const post = api.bind(null, 'POST');
export const put = api.bind(null, 'PUT');
export const del = api.bind(null, 'DELETE');

const parameterifyTypes = {
	'number': (key, val) => encodeURIComponent(key) + '=' + encodeURIComponent(val),
	'string': (key, val) => encodeURIComponent(key) + '=' + encodeURIComponent(val),
	'array': (key, val) => val.map(v => encodeURIComponent(key) + '[]=' + encodeURIComponent(v)).join('&'),
};

export function parameterify(obj) {
	if (!Object.keys(obj).reduce((res, key) => res && typeof parameterifyTypes[typeof obj[key]] === 'function', true)) throw new Error('incompatible type given');
	return Object.keys(obj).map(key => parameterifyTypes[typeof obj[key]](key, obj[key])).join('&');
}

export function resetPassword(email) {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');

	return fetch('/api/whoami/password/reset', {
		method: 'post',
		body: JSON.stringify({ email }),
		headers,
	}).then(r => {
		if (r.status >= 400) throw r;
	});
}

export function requestMagicLink(email) {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');

	return fetch('/api/whoami/magiclink/request', {
		method: 'post',
		body: JSON.stringify({ email }),
		headers,
	}).then(r => {
		if (r.status >= 400) throw r;
	});
}

export function useMagicLink(token) {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');

	return fetch('/api/whoami/magiclink/login/' + token, {
		method: 'post',
		headers,
	}).then(r => {
		if (r.status >= 400) throw r;
	});
}


export function authenticate(username, password) {
	const headers = new Headers();
	headers.append('Authorization', 'Basic ' + btoa(username + ':' + password));

	return fetch('/api/whoami', {
		method: 'get',
		headers,
	}).then(r => {
		if (r.status >= 400) throw r;
	});
}
