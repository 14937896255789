import React, { useContext, useEffect, useState } from 'react';
import { get, put } from '../../util/api';
import Card from '../lib/ui/Card';
import CardToolbar from '../lib/ui/CardToolbar';
import CardPadding from '../lib/ui/CardPadding';
import WithLabel from '../lib/forms/WithLabel';
import InputField from '../lib/forms/simple/InputField';
import ButtonArea from '../lib/forms/ButtonArea';
import Button from '../lib/forms/simple/Button';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import DateField from '../lib/forms/simple/DateField';
import moment from 'moment';
import PotentialSchoolClassesField from '../lib/forms/complex/PotentialSchoolClassesField';
import NavigateAwayPrompt from '../lib/forms/NavigateAwayPrompt';
import TertiaryButton from '../lib/forms/simple/TertiaryButton';
import { useIntl } from 'react-intl';
import UserDataChangeCounter from '../../context/UserDataChangeCounter';

export default function ProfileUpdateStudentInfo() {
	const { increment, value } = useContext(UserDataChangeCounter);
	const [ redirect, setRedirect ] = useState(null);
	const [ loading, setLoading ] = useState(false);

	const [ studentNumber, setStudentNumber ] = useState('');
	const [ birthDate, setBirthDate ] = useState('');
	const [ schoolClassId, setSchoolClassId ] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		get('/whoami').then(user => {
			setStudentNumber(user.studentInfo.studentNumber);
			setBirthDate(user.studentInfo.birthDate == null ? null : moment(user.studentInfo.birthDate, 'YYYY-MM-DD').unix());
			setSchoolClassId(user.studentInfo.schoolClassId);
		});
	}, [ value ]);

	return <Card>
		<NavigateAwayPrompt redirect={redirect} />
		<CardToolbar title={intl.formatMessage({ id: "changeStudentDetails" })} />
		<CardPadding>
			<form onSubmit={event => {
				event.preventDefault();
				event.stopPropagation();
				setLoading(true);
				put('/users/me/studentRoleDetails', { studentNumber, birthDate: birthDate == null ? null : moment.unix(birthDate).format('YYYY-MM-DD'), schoolClassId })
					.then(() => setRedirect('/profile'))
					.finally(increment)
					.catch(e => e.text());
				return false;
			}}>
				<WithLabel label={intl.formatMessage({ id: "studentNumber" })}>
					<InputField value={studentNumber} onChange={setStudentNumber} />
				</WithLabel>

				<WithLabel label={intl.formatMessage({ id: "birthday" })}>
					<DateField value={birthDate} onChange={setBirthDate} />
				</WithLabel>

				<WithLabel label={intl.formatMessage({ id: "class" })}>
					<PotentialSchoolClassesField onChange={sc => setSchoolClassId(sc == null ? null : sc.id)} value={schoolClassId} />
				</WithLabel>

				<ButtonArea>
					<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={() => setRedirect('/profile')} />
					<Button icon="check" label={intl.formatMessage({ id: "save" })} type="submit" />
				</ButtonArea>

				{loading && <CardLoadingSpinner />}
			</form>
		</CardPadding>
	</Card>;
}
