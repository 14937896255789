import React, { useEffect, useState } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import { del, get, post, put } from '../../../util/api';
import { withRouter } from 'react-router';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import HtmlField from '../../lib/forms/simple/HtmlField';
import ConfirmPopup from '../../lib/forms/confirmation/ConfirmPopup';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import FormRow from '../../lib/forms/layout/FormRow';
import SchoolClassTypeField from '../../lib/forms/complex/SchoolClassTypeField';


export default withRouter(({ match, history }) => {
	const schoolClassTypeId = match.params.id;

	const [ loading, setLoading ] = useState(true);
	const [ name, setName ] = useState('');
	const [ description, setDescription ] = useState('');
	const [ confirmDelete, setConfirmDelete ] = useState(false);
	const [ classCount, setClassCount ] = useState(0);
	const [ studentCount, setStudentCount ] = useState(0);
	const [ schoolClassTypeChangeChoice, setSchoolClassTypeChangeChoice ] = useState(null);
	const [ warningMandatoryField, setWarningMandatoryField ] = useState(false);
	const [ classTypeCount, setClassTypeCount ] = useState(0);

	useEffect(() => {
		if (schoolClassTypeId == null) return setLoading(false);
		get('/schoolClassTypes/' + schoolClassTypeId).then(sct => {
			setName(sct.name);
			setDescription(sct.description);
		// If school class type is null then you should be redirected to the overview page (most likely you pressed "back" after deleting a class)
		}).catch(() => history.push('/schoolclasstypes'));
		get('/schoolClassTypes/count').then(setClassTypeCount);
		get('/schoolClassTypes/classCount/' + schoolClassTypeId).then(setClassCount);
		get('/schoolClassTypes/studentCount/' + schoolClassTypeId).then(setStudentCount).finally(() => setLoading(false));
	}, [ schoolClassTypeId ]);

	return <Card>
		<CardToolbar title={schoolClassTypeId == null ? 'Klastype aanmaken' : 'Klastype bewerken'} />
		{!loading && <CardPadding>
			<ConfirmPopup
				open={confirmDelete}
				onConfirm={() => {
					// Delete and replace if there is a new schoolClassTypeChangeChoice
					// or simply delete if there isn't a choice (when you have no classes so you can safely delete without choosing)
					del('/schoolClassTypes/' + schoolClassTypeId + (schoolClassTypeChangeChoice != null ? '/' + schoolClassTypeChangeChoice : ''))
						.then(() => history.push('/schoolclasstypes'))
						.finally(() => { setConfirmDelete(false); setWarningMandatoryField(false); });
				}}
				onCancel={() => setConfirmDelete(false)}
				formComponent={({ onCancel, onConfirm }) => <Card>
					<CardToolbar title="Klastype verwijderen" />
					<CardPadding>
						<p>Je verwijdert hiermee dit klastype.</p>
						{classCount > 0 && <p>
							<p>Op dit moment zijn er {classCount} klas(sen) van dit klastype, met totaal {studentCount} leerling(en) er in.</p>
							{classTypeCount > 0 && <p>
								<p>Je moet deze klassen migreren naar een ander klassetype.</p>

								<WithLabel label="Klastype">
									{warningMandatoryField && <p style={{ color: 'var(--col-red)' }} >Je moet een klastype kiezen</p>}
									<SchoolClassTypeField onChange={sc => setSchoolClassTypeChangeChoice(sc == null ? null : sc.id)} value={schoolClassTypeChangeChoice} excluding={[ schoolClassTypeId ]} />
								</WithLabel>
							</p>} </p>}
						{classCount == 0 && <p>Op dit moment zijn er geen klassen die van dit type gebruikmaken, je kunt het veilig verwijderen. </p>}
						<ButtonArea>
							<TertiaryButton label="Annuleren" onClick={onCancel} />
							<Button style={{ background: 'var(--col-red)' }} icon="trash" label="Verwijderen" onClick={() => ((schoolClassTypeChangeChoice != null || classCount == 0 || classTypeCount == 0) ? onConfirm() : setWarningMandatoryField(true))} />
						</ButtonArea>
					</CardPadding>
				</Card>} />

			{schoolClassTypeId != null && <FormRow title="Klastype informatie">
				{name}<br />
				{classCount > 0 ? "Er bestaan " + classCount + " klas(sen) van dit type" : 'Er bestaan geen klassen van dit type'}<br />
				{studentCount > 0 ? "Er zijn " + studentCount + " leerlingen in klassen van dit type" : 'Er zijn geen leerlingen in klassen van dit type'}<br /><br />
				<TertiaryButton icon="trash-o" label="Klastype verwijderen" onClick={() => setConfirmDelete(true)} />
			</FormRow>}

			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				const dto = { name, description };
				if (schoolClassTypeId) {
					put('/schoolClassTypes/' + schoolClassTypeId, dto).finally(() => history.push('/admin/schoolclasstypes'));
				} else {
					post('/schoolClassTypes', dto).finally(() => history.push('/admin/schoolclasstypes'));
				}
				return false;
			}}>
				<WithLabel label="Klastypenaam">
					<InputField onChange={setName} value={name} />
				</WithLabel>

				<WithLabel label="Beschrijving">
					<HtmlField onChange={setDescription} value={description} />
				</WithLabel>

				<ButtonArea>
					<TertiaryLinkButton label="Annuleren" to="/admin/schoolclasstypes" />
					<Button icon="check" label="Opslaan" type="submit" />
				</ButtonArea>
			</form>
		</CardPadding>}
		{loading && <CardLoadingSpinner />}
	</Card>;
});
