import React, { Fragment, useContext, useEffect, useState } from 'react';
import UserContext from '../../../context/User';
import { get, put, del } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import Card from '../../lib/ui/Card';
import FormRow from '../../lib/forms/layout/FormRow';
import Button from '../../lib/forms/simple/Button';
import ConfirmPopup from '../../lib/forms/confirmation/ConfirmPopup';
import CardPadding from '../../lib/ui/CardPadding';
import CardToolbar from '../../lib/ui/CardToolbar';
import ButtonArea from '../../lib/forms/ButtonArea';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import { FormattedMessage, useIntl } from 'react-intl';

export default function TeacherView({ match, history }) {
	const user = useContext(UserContext);
	const teacherId = match.params.id;
	const [ loading, setLoading ] = useState(true);
	const [ teacher, setTeacher ] = useState(null);

	const [ confirmAdminUpgrade, setConfirmAdminUpgrade ] = useState(false);
	const [ confirmAdminDowngrade, setConfirmAdminDowngrade ] = useState(false);
	const [ confirmDelete, setConfirmDelete ] = useState(false);
	const intl = useIntl();

	useEffect(() => {
		get('/schools/' + user.schoolAdminInfo.schoolId + '/teachers/' + teacherId)
			.then(setTeacher)
			.finally(() => setLoading(false));
	}, [ teacherId ]);

	return <Card>
		<CardPadding>
			{!loading && teacher != null && <Fragment>
				<FormRow title={intl.formatMessage({ id: "supervisor" })}>
					<span>{teacher.fullName}</span><br />
					<span>{teacher.teacherInfo.schoolName}</span>
				</FormRow>
				<FormRow title={intl.formatMessage({ id: "administrator" })}>
					{teacher.schoolAdminInfo && <p><FormattedMessage id="teacherIsAdministrator" /></p>}
					{user.schoolAdminInfo != null && teacher.schoolAdminInfo == null && <Button label={intl.formatMessage({ id: "giveAdministratorRights" })} onClick={() => setConfirmAdminUpgrade(true)} />}
					<ConfirmPopup
						open={confirmAdminUpgrade}
						onConfirm={() => {
							put('/users/' + teacherId + '/schoolAdmin?admin=true')
								.then(setTeacher)
								.finally(() => setConfirmAdminUpgrade(false));
						}}
						onCancel={() => setConfirmAdminUpgrade(false)}
						formComponent={({ onCancel, onConfirm }) => <Card>
							<CardToolbar title={intl.formatMessage({ id: "confirmAdministratorCreate" })} />
							<CardPadding>
								<p><FormattedMessage id="administratorRights" /></p>
								<ul>
									<li><FormattedMessage id="administratorRights1" /></li>
									<li><FormattedMessage id="administratorRights2" /></li>
									<li><FormattedMessage id="administratorRights3" /></li>
									<li><FormattedMessage id="administratorRights4" /></li>
								</ul>
								<ButtonArea>
									<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={onCancel} />
									<Button label={intl.formatMessage({ id: "continue" })} onClick={onConfirm} />
								</ButtonArea>
							</CardPadding>
						</Card>} />

					{user.schoolAdminInfo != null && teacher.schoolAdminInfo != null && <Button label={intl.formatMessage({ id: "revokeAdministratorRights" })} onClick={() => setConfirmAdminDowngrade(true)} />}
					<ConfirmPopup
						open={confirmAdminDowngrade}
						onConfirm={() => {
							put('/users/' + teacherId + '/schoolAdmin?admin=false')
								.then(setTeacher)
								.finally(() => setConfirmAdminDowngrade(false));
						}}
						onCancel={() => setConfirmAdminDowngrade(false)}
						formComponent={({ onCancel, onConfirm }) => <Card>
							<CardToolbar title={intl.formatMessage({ id: "confirmAdministratorRevocation" })} />
							<CardPadding>
								<p><FormattedMessage id="confirmAdministratorRevocation1" /></p>
								<ButtonArea>
									<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={onCancel} />
									<Button label={intl.formatMessage({ id: "continue" })} onClick={onConfirm} />
								</ButtonArea>
							</CardPadding>
						</Card>} />
				</FormRow>
				<FormRow title={intl.formatMessage({ id: "dangerZone" })}>
					<ConfirmPopup
						open={confirmDelete}
						onConfirm={() => {
							del('/users/' + teacherId)
								.then(() => history.push('/teachers'))
								.finally(() => setConfirmDelete(false));
						}}
						onCancel={() => setConfirmDelete(false)}
						formComponent={({ onCancel, onConfirm }) => <Card>
							<CardToolbar title={intl.formatMessage({ id: "deleteAccount" })} />
							<CardPadding>
								<p><FormattedMessage id="confirmDeleteAccount" /></p>
								<ButtonArea>
									<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={onCancel} />
									<Button style={{ background: 'var(--col-red)' }} icon="trash" label={intl.formatMessage({ id: "delete" })} onClick={onConfirm} />
								</ButtonArea>
							</CardPadding>
						</Card>} />
					<TertiaryButton label={intl.formatMessage({ id: "deleteAccount" })} icon="trash" onClick={() => setConfirmDelete(true)} />
				</FormRow>
			</Fragment>}
		</CardPadding>

		{loading && <CardLoadingSpinner />}
	</Card>;
}
