import React, { useState, useEffect } from 'react';
import { get } from '../../../util/api';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TablePadding from '../../lib/table/TablePadding';

function sortMonitorsLowercase(monitors) {
	return monitors.sort((a, b) => {
		if (a.name.toLowerCase() == b.name.toLowerCase()) return 0;
		if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
		return 1;
	});
}

export default function MonitorsOverview() {
	const [ monitors, setMonitors ] = useState(null);

	useEffect(() => {
		get('/monitors').then(m => setMonitors(sortMonitorsLowercase(m)));
	}, []);

	return <Card>
		<CardToolbar title="Monitors beheren">
			<TertiaryLinkButton icon="plus" label="Monitor maken" to="/admin/monitors/create" />
		</CardToolbar>

		{monitors != null && <TablePadding>
			<span className="text-small">{monitors.length} monitors weergegeven</span>
		</TablePadding>}

		<table className="table">
			<thead>
				<tr>
					<th>Monitornaam</th>
					<th>Versie</th>
					<th>Clusters</th>
					<th>Competenties</th>
					<th></th>
				</tr>
			</thead>
			{monitors != null && <tbody>
				{monitors.map(m => <tr key={m.id}>
					<td>{m.name}</td>
					<td>{m.version}</td>
					<td>{m.clusters.length}</td>
					<td>{m.competences.length}</td>
					<td>
						<TertiaryLinkButton iconRight={true} icon="angle-right" label="Bewerk" to={'/admin/monitors/' + m.id} />
					</td>
				</tr>)}
			</tbody>}
		</table>
		{monitors == null && <CardLoadingSpinner />}
	</Card>;
}
