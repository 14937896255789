import React, { useCallback, useEffect, useState } from 'react';
import FaqCategory from './FaqCategory';
import { get, put } from '../../../util/api';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import { useIntl } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import ConfirmPopup from '../../lib/forms/confirmation/ConfirmPopup';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';

export function move(list, fromIdx, toIdx) {
	const element = list[fromIdx];
	const removedList = [ ...list.slice(0, fromIdx), ...list.slice(fromIdx + 1, list.length) ]; // make a copy of list without the element
	return [ ...removedList.slice(0, toIdx), element, ...removedList.slice(toIdx, removedList.length) ]; // return a new list with the element in the toIdx position
}

// This function makes ids of FAQitems and FAQCategories the Object keys.
// These are used for changing the order when dragging and dropping.
export function makeMap(list) {
	const map = {};
	for (let i = 0; i < list.length; i++) {
		map[list[i].id] = list[i];
	}
	return map;
}

export function sortBySortingProp(list) {
	if(list != null && Object.keys(list).length < 2) return Object.keys(list); // If we have an empty array or just 1 element, we dont need to sort it.
	return Object.keys(list).sort((a, b) => {
		if (list[a].sorting == null) return 1;
		else if (list[b].sorting == null) return -1;
		else return list[a].sorting - list[b].sorting;
	});
}

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: "none",
	padding: '4px 0',
	margin: `var(--u-8)`,
	borderRadius: 'var(--u-8)',
	top: '0',
	left: '0',
	overflow: 'hidden',
	boxShadow: '0 0 1px inset rgba(0, 0, 0, 0.3)',
	background: 'var(--col-primary-200)',
	borderStyle: 'solid',
	borderColor: 'black',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = isDraggingOver => ({
	width: '100%',
	overflow: 'auto',
});

export default function FaqOverview() {
	const intl = useIntl();

	const [ faqCategories, setFaqCategories ] = useState([]);
	const [ faqItems, setFaqItems ] = useState([]);
	const [ loading, setLoading ] = useState(false);

	const [ order, setOrder ] = useState(Object.keys(faqCategories).length > 0 ? sortBySortingProp(faqCategories) : []);
	const [ faqCategoriesOrder, setFaqCategoriesOrder ] = useState(order.length > 0 ? order : []);
	const [ showFaqCategoriesModal, setShowFaqCategoriesModal ] = useState(false);

	useEffect(() => {
		get('/faqCategory').then(categories => setFaqCategories(makeMap(categories)));
		get('/faqItem').then(items => setFaqItems(items.reduce((tot, elem) => ({ ...tot, [elem.id]: elem }), {})));
	}, [ showFaqCategoriesModal ]);

	// So const order is responsible for order of faqCategories only inside the modal and faqCategoriesOrder is responsible for the order of faqCategories on the main screen.
	// When we want to save the order of faqCategories, we setFaqCatgoriesOrder to the order which is inside the modal.
	// If we want to close the modal without saving, we simply dont set that order to faqCategoriesOrder
	useEffect(() => {
		setOrder(sortBySortingProp(faqCategories));
		setFaqCategoriesOrder(sortBySortingProp(faqCategories));
	}, [ faqCategories ]);

	const onDragEnd = useCallback(result => {
		if (!result.destination) return;
		setOrder(move(order, result.source.index, result.destination.index));
	}, [ order, setOrder ]);

	return (
		<div>
			<TertiaryLinkButton label={intl.formatMessage({ id: "addCategory" }) } icon={"plus"} to={"/admin/faq/faqCategory/create/" + null}/>
			<TertiaryLinkButton style={{ float: 'right' }} label={intl.formatMessage({ id: "changeOrderOfCategories" })} icon={"sort"} onClick={() => setShowFaqCategoriesModal(true)}/>
			{faqCategoriesOrder.map((id) => <FaqCategory
				key={id}
				category={faqCategories[id]}
				items={faqCategories[id].faqItemIdList.map(id => faqItems[id]).filter(x => x != null)} />)}
			<ConfirmPopup
				open={showFaqCategoriesModal}
				onConfirm={() => {
					setLoading(true);
					put('/faqCategory/swap', order).then(() => { setLoading(false); setFaqCategoriesOrder(order); setShowFaqCategoriesModal(false); }) // If we want to save the order, we set the faqCategoriesOrder to the order from the modal
						.catch((err) => { err.text(); setLoading(false); setShowFaqCategoriesModal(false); });
				}}
				onCancel={() => setShowFaqCategoriesModal(false)}
				formComponent={({ onCancel, onConfirm }) => <Card>
					<div style={{ paddingBottom: 'var(--u-16)' }}>
						<CardToolbar title={intl.formatMessage({ id: "changeOrderOfCategories" })}>
							<span onClick={onCancel} style={{ cursor: 'pointer' }} className={'fa fa-times' }/>
						</CardToolbar>
					</div>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided, snapshot) => (
								<div
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									{order.map((id, index) => (
										<Draggable key={id} draggableId={id} index={index}>
											{(provided, snapshot) => {
												return <div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}>
													<div style={{
														display: 'flex',
														margin: 'var(--u-4)',
														alignItems: 'center',
													}}>
														<div>
															<span className="fa fa-sort" style={{ padding: 'var(--u-8)', color: 'var(--col-grey-500)' }} />
														</div>
														<div>
															{faqCategories[id].name}
														</div>
													</div>
												</div>;
											}}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
					<ButtonArea>
						<Button style={{ marginRight: 'var(--u-8)' }} label={intl.formatMessage({ id: "save" })} onClick={onConfirm} level="primary" type="submit"><span className="fa fa-check" />&nbsp; Opslaan</Button>
					</ButtonArea>
					{loading && <CardLoadingSpinner />}
				</Card>} />
		</div>
	);
}
