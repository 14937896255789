import React, { useState } from 'react';
import CardLoadingSpinner from '../../../lib/ui/CardLoadingSpinner';
import Button from '../../../lib/forms/simple/Button';
import Modal from '../../../lib/ui/Modal';
import ModalToolbar from '../../../lib/ui/ModalToolbar';
import ModalContent from '../../../lib/ui/ModalContent';
import WithLabel from '../../../lib/forms/WithLabel';
import LabelContent from '../../../lib/forms/LabelContent';
import ButtonArea from '../../../lib/forms/ButtonArea';
import partyPopper from '../../../../assets/competentiemonitor/party-popper.gif';
import { FormattedMessage, useIntl } from 'react-intl';
import TaxonomyHtmlRenderer from '../../../taxonomy/TaxonomyHtmlRenderer';


export default function AdvanceInLevelModal({ assessment, competence, newGrade, onClose }) {

	const [ loading, setLoading ] = useState(false);
	const grades = [ 'A', 'B', 'C', 'D', 'E', 'F' ];
	const intl = useIntl();
	const finishedText = newGrade > 0 ? competence.levels[newGrade - 1].finishedText : competence.baseLevelDescription; // -1 due to the offset: levels start at B, no level is level A

	return <Modal>
		<form onSubmit={() => { setLoading(true); onClose(); }}>
			{loading && <CardLoadingSpinner />}
			<ModalToolbar title={intl.formatMessage({ id: "congratulations" })} />
			<ModalContent style={{ overflowY: 'visible' }}>
				<WithLabel label={competence.label}>
					{newGrade < 5 ?
						<LabelContent><FormattedMessage id="youAdvancedToLevel" /> </LabelContent>
						:
						<LabelContent><FormattedMessage id="maxLevel" /> </LabelContent>
					}
				</WithLabel>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<div className="level-up">
						<div className="new-level">
							{grades[newGrade]}
						</div>
						{assessment != null && assessment.grade < 5 &&
							<div className="old-level">
								{grades[assessment.grade]}
							</div>
						}
					</div>
					<img src={partyPopper} alt={intl.formatMessage({ id: "congratulations" })} width="450" height="338" />
				</div>

				{finishedText.length > 0 && <TaxonomyHtmlRenderer html={finishedText}/>}

				<ButtonArea>
					<Button type="submit" icon="check" label={intl.formatMessage({ id: "confirm" })} />
				</ButtonArea>
			</ModalContent>
		</form>
	</Modal>;
}
