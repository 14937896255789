import React, { useEffect, useState } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import { get, post, put } from '../../../util/api';
import MonitorSelector from '../../lib/forms/complex/MonitorSelector';
import { withRouter } from 'react-router';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import WithValidation from '../../lib/forms/WithValidation';
import FormRow from '../../lib/forms/layout/FormRow';
import HtmlField from '../../lib/forms/simple/HtmlField';
import { dayMonthValid } from '../../schooladmin/school/MySchoolEdit';

export default withRouter(({ match, history }) => {
	const schoolId = match.params.id;

	const [ name, setName ] = useState('');
	const [ monitor, setMonitor ] = useState(null);
	const [ resetDay, setResetDay ] = useState(null);
	const [ resetMonth, setResetMonth ] = useState(null);
	const [ textField1, setTextField1 ] = useState(null);
	const [ textField2, setTextField2 ] = useState(null);

	useEffect(() => {
		if (schoolId == null) return;

		get('/schools/' + schoolId).then(school => {
			setName(school.name);
			setMonitor(school.monitorId);
			setResetDay(school.resetDay);
			setResetMonth(school.resetMonth);
			setTextField1(school.textField1);
			setTextField2(school.textField2);
		});
	}, [ schoolId ]);

	const schoolNameValid = name != null && name.length > 0;
	const resetMomentValid = dayMonthValid(resetDay, resetMonth);
	const monitorValid = monitor != null;

	return <Card>
		<CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				const body = { name, monitorId: monitor, resetDay, resetMonth, textField1, textField2 };
				if (schoolId) {
					put('/schools/' + schoolId, body).finally(() => history.push('/admin/schools'));
				} else {
					post('/schools', body).finally(() => history.push('/admin/schools'));
				}
				return false;
			}}>
				<FormRow title="Schoolnaam">
					<WithLabel label="Schoolnaam">
						<WithValidation valid={schoolNameValid}>
							<InputField onChange={setName} value={name} />
						</WithValidation>
					</WithLabel>
				</FormRow>
				<FormRow title="Monitor">
					<MonitorSelector disabled={schoolId != null} onChange={setMonitor} value={monitor} capTwoCols={true} />
					<span className="text-small">Wijzigen van monitor na aanmaken van een school is nog niet mogelijk</span>
				</FormRow>
				<FormRow title="Klassenreset" description="Leerlingen krijgen op deze datum automatisch een pop-up om een nieuwe klas te kiezen">
					<WithLabel label="Resetdatum (dd/mm)">
						<div style={{ display: 'grid', gridTemplateColumns: '64px 16px 64px 48px' }}>
							<div>
								<InputField type="number" onChange={setResetDay} value={resetDay} />
							</div>
							<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingBottom: 'var(--u-16)', color: 'var(--col-grey-600)' }}>
								<span>/</span>
							</div>
							<div>
								<InputField type="number" onChange={setResetMonth} value={resetMonth} />
							</div>
							<WithValidation valid={resetMomentValid}>
								<div />
							</WithValidation>
						</div>
					</WithLabel>
				</FormRow>
				<FormRow title="Footer">
					<WithLabel label="Linkerkolom">
						<HtmlField onChange={setTextField1} value={textField1} />
					</WithLabel>
					<WithLabel label="Rechterkolom">
						<HtmlField onChange={setTextField2} value={textField2} />
					</WithLabel>
				</FormRow>

				<ButtonArea>
					<TertiaryLinkButton label="Annuleren" to="/admin/schools" />
					<Button disabled={!schoolNameValid || !resetMomentValid || !monitorValid} icon="check" label="Opslaan" type="submit" />
				</ButtonArea>
			</form>
		</CardPadding>
	</Card>;
});
