import React, { useContext, useEffect, useState } from 'react';
import Card from '../../../lib/ui/Card';
import CardPadding from '../../../lib/ui/CardPadding';
import { get, post, put } from '../../../../util/api';
import Level from './../parts/Level';
import Button from '../../../lib/forms/simple/Button';
import CardAccent from '../../../lib/ui/CardAccent';
import UserContext from '../../../../context/User';
import ButtonArea from '../../../lib/forms/ButtonArea';
import CompetenceInfo from '../parts/CompetenceInfo';
import NavigateAwayPrompt from '../../../lib/forms/NavigateAwayPrompt';
import TertiaryButton from '../../../lib/forms/simple/TertiaryButton';
import AdvanceInLevelModal from '../parts/AdvanceInLevelModal';
import { useIntl } from 'react-intl';


export function shouldShowLevel(competence, previousLevel, answers) {
	if (previousLevel == null) return true;
	const maxRight = previousLevel.questions.length;
	const okQuestions = previousLevel.questions.filter(q => answers[q.id] === true).length;
	const notAnsweredQuestions = previousLevel.questions.filter(q => answers[q.id] == null).length;

	return notAnsweredQuestions == 0
		&& (okQuestions >= 2 || okQuestions == maxRight)
		&& shouldShowLevel(competence, competence.levels[competence.levels.indexOf(previousLevel) - 1], answers);
}

export default function AssessmentForm({ match }) {
	const [ redirect, setRedirect ] = useState(null);
	const [ competence, setCompetence ] = useState(null);
	const [ assessment, setAssessment ] = useState(null);
	const [ answers, setAnswers ] = useState({});
	const [ advancedInLevelModal, setAdvancedInLevelModal ] = useState(null);
	const user = useContext(UserContext);
	const intl = useIntl();

	useEffect(() => {
		// Fetch assessment type
		get('/competences/' + match.params.id).then(setCompetence);

		// Fetch current assessments / student details
		get('/assessments/student/' + user.studentInfo.id + '/latest/' + match.params.id)
			.then(assessment => {
				if (assessment != null) {
					setAssessment(assessment);
					setAnswers(assessment.answers.reduce((tot, ans) => ({ ...tot, [ans.questionId]: ans.answer }), {}));
				}
			})
			.catch(console.error);
	}, [ match.params.id ]);

	// TODO: replace by loading indicator or something
	if (competence == null) return null;

	return <div className="assessment-form">
		{advancedInLevelModal != null && <AdvanceInLevelModal assessment={assessment} competence={competence} newGrade={advancedInLevelModal} onClose={() => setRedirect('/assessments')} />}
		<NavigateAwayPrompt redirect={redirect} />
		<CompetenceInfo competence={competence} />
		{answers && competence.levels
			.filter((l, idx) => shouldShowLevel(competence, competence.levels[idx - 1], answers))
			.map((l, idx) => <Level
				key={l.id}
				finished={shouldShowLevel(competence, competence.levels[idx], answers, l.name)}
				assessment={assessment}
				competence={competence}
				level={l}
				answers={answers}
				onAnswer={(qId, a) => setAnswers({ ...answers, [qId]: a })} />)}
		<Card style={{ position: 'sticky', bottom: '16px', boxShadow: '0 0 8px rgba(0, 0, 0, 0.35)' }}>
			<CardAccent color={competence.color} />
			<CardPadding style={{ margin: '-16px 0' }}>
				<ButtonArea>
					<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={() => setRedirect('/assessments')} />
					<Button type="button" icon="check" label={intl.formatMessage({ id: "save" })} onClick={() => {
						put('/me/student/studentTeacherRelations/markAsUnread', null, false);
						// update state to show you a congratulations if you advanced in level
						post('/assessments', { answers, competenceId: competence.id }).then(a => {
							if(assessment == null || a.grade > assessment.grade || a.grade == 5) {
								setAdvancedInLevelModal(a.grade);
							} else {
								setRedirect('/assessments');
							}
						});
					}} />
				</ButtonArea>
			</CardPadding>
		</Card>
	</div>;
}
