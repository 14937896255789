import React, { useEffect, useState } from 'react';
import { get } from '../../../util/api';
import WithLabel from '../../lib/forms/WithLabel';
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from 'recharts';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectField from '../../lib/forms/simple/SelectField';

export function cmpCompetences(a, b) {
	if (a.name.toLowerCase() < b.name.toLowerCase()) {
		return -1;
	} else if (a.name.toLowerCase() == b.name.toLowerCase()) {
		return 0;
	} else {
		return 1;
	}
}

export default function SchoolClassScore({ schoolClassId, schoolId }) {
	const [ competences, setCompetences ] = useState([]);
	const [ classScores, setClassScores ] = useState(null);
	const [ selectedCompetence, setSelectedCompetence ] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		get('/schoolclasses/' + schoolClassId + '/stats').then(setClassScores);
		get('/monitors/bySchool/' + schoolId).then(monitor => {
			const orderedCompetences = monitor.competences.sort(cmpCompetences);
			setCompetences(orderedCompetences);
			if (selectedCompetence == null) setSelectedCompetence(orderedCompetences[0].id);
		});
	}, [ schoolClassId ]);

	const scoresForThisCompetence = classScores == null || classScores.scoresByCompetence == null ? null : classScores.scoresByCompetence.find(sc => sc.competence.id == selectedCompetence);

	const normalizedCountByGrade = ((scoresForThisCompetence || {}).countByGrade || {});
	normalizedCountByGrade[-1] = 0;
	const data = [
		{ name: 'A', grade: 0, count: normalizedCountByGrade[0] || 0 },
		{ name: 'B', grade: 1, count: normalizedCountByGrade[1] || 0 },
		{ name: 'C', grade: 2, count: normalizedCountByGrade[2] || 0 },
		{ name: 'D', grade: 3, count: normalizedCountByGrade[3] || 0 },
		{ name: 'E', grade: 4, count: normalizedCountByGrade[4] || 0 },
		{ name: 'F', grade: 5, count: normalizedCountByGrade[5] || 0 },
	];

	const numberAssessmentsTotal = Math.max(Object.entries(normalizedCountByGrade).reduce((total, x) => total + x[1], 0), 0);

	return <div>
		{classScores != null && classScores.scoresByCompetence != null && classScores.schoolClassSize > 5 ?
			<div>
				<WithLabel label={intl.formatMessage({ id: "competence" })}>
					<SelectField options={competences
						.map(c => ({ value: c.id, label: <span>{c.label}</span> }))} onChange={setSelectedCompetence} value={selectedCompetence} />
				</WithLabel>
				<WithLabel label={intl.formatMessage({ id: "classDistribution" })}>
					<BarChart
						width={600}
						height={300}
						data={data}>
						<Tooltip separator={': '} formatter={(value, key, props) => [ value, intl.formatMessage({ id: "count" }) ]} />
						<XAxis dataKey="name" />
						<YAxis label={{ value: intl.formatMessage({ id: "numberOfStudents" }), angle: -90, dx: -15, style: { fontSize: 14 } }} />
						<Bar
							dataKey="count"
							fill="#8884d8">
							{data.map((entry, idx) => <Cell key={idx} fill={'var(--col-primary-500)'} />)}
						</Bar>
					</BarChart>
				</WithLabel>
				<WithLabel label={intl.formatMessage({ id: "count" })}>
					<p><FormattedMessage id="competenceFilledInNumber" values={{ numberAssessmentsTotal: numberAssessmentsTotal }} /> </p>
				</WithLabel>
			</div>
			:
			// Don't show if there is too few samples that the samples could be identified individually
			// This is necessary due to the GDPR / AVG laws
			<div style={{ marginTop: 'var(--u-8)' }}><FormattedMessage id="tooFewResultsToShowGraph" /></div>
		}
	</div>;
}
