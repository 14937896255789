import React, { useEffect, useState } from 'react';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import Form from '../../lib/forms/Form';
import CardPadding from '../../lib/ui/CardPadding';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import { get, post, put, del } from '../../../util/api';
import HtmlField from '../../lib/forms/simple/HtmlField';
import SelectField from '../../lib/forms/simple/SelectField';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import ConfirmPopup from '../../lib/forms/confirmation/ConfirmPopup';
import { useIntl } from 'react-intl';

export default function FaqItemEdit({ match, history }) {
	const intl = useIntl();

	const faqItemId = match.params.id;
	const faqCatId = match.params.catId;

	const [ deleteModal, setDeleteModal ] = useState(false);
	const [ faqCategoryId, setFaqCategoryId ] = useState(faqCatId);
	const [ sorting, setSorting ] = useState(null);
	const [ targetGroup, setTargetGroup ] = useState('STUDENT');
	const [ question, setQuestion ] = useState('');
	const [ answer, setAnswer ] = useState('');

	useEffect(() => {
		if (faqItemId == null) return;
		get('/faqItem/' + faqItemId).then(faq => {
			setFaqCategoryId(faq.faqCategoryId);
			setTargetGroup(faq.targetGroup);
			setQuestion(faq.question);
			setAnswer(faq.answer);
			setSorting(faq.sorting);
		});
	}, [ faqItemId ]);

	return <Card>
		<Form onSubmit={e => {
			const faqItem = {
				id: faqItemId,
				faqCategoryId: typeof faqCategoryId === 'string' ? parseInt(faqCategoryId, 10) : faqCategoryId,
				targetGroup,
				question,
				answer,
				sorting: sorting,
			};
			if (faqItemId) {
				put('/faqItem/' + faqItemId, faqItem).then(() => history.push('/admin/faq'));
			} else {
				post('/faqItem', faqItem).then(() => history.push('/admin/faq'));
			}
		}}>
			<ConfirmPopup
				open={deleteModal}
				onConfirm={() => {
					del('/faqItem/' + faqItemId)
						.then(() => history.push('/admin/faq'));
				}}
				onCancel={() => setDeleteModal(false)}
				formComponent={({ onCancel, onConfirm }) => <Card>
					<CardToolbar title="Bevestig verwijderen" />
					<CardPadding>
						<p>Je verwijdert hiermee deze vraag. Dit kan niet ongedaan gemaakt worden.</p>
						<ButtonArea>
							<TertiaryButton label="Annuleren" onClick={onCancel} />
							<Button style={{ background: 'var(--col-red)' }} icon="trash-o" label="Doorgaan" onClick={onConfirm} />
						</ButtonArea>
					</CardPadding>
				</Card>} />

			<CardToolbar title={faqItemId == null ? 'FAQ aanmaken' : 'FAQ bewerken'}>
				{faqItemId != null && <TertiaryButton
					icon="trash-o"
					label="Verwijderen"
					onClick={() => setDeleteModal(true)} />}
			</CardToolbar>

			<CardPadding>
				<WithLabel label="Doelgroep">
					<SelectField
						options={[
							{ value: 'STUDENT', label: 'Leerling' },
							{ value: 'TEACHER', label: 'Docent' },
							{ value: 'SCHOOL_ADMIN', label: 'Schoolbeheerder' },
						]}
						onChange={setTargetGroup}
						value={targetGroup} />
				</WithLabel>

				<WithLabel label="Vraag">
					<InputField
						onChange={setQuestion}
						value={question} />
				</WithLabel>

				<WithLabel label="Antwoord">
					<HtmlField
						onChange={setAnswer}
						value={answer} />
				</WithLabel>

				<ButtonArea>
					{question.length == 0 || answer.length == 0 ?
						<abbr title={intl.formatMessage({ id: "inputFieldsEmpty" })}>
							<Button disabled={question.length == 0 || answer.length == 0} type="submit" icon="check" label="Opslaan" />
						</abbr> : <Button type="submit" icon="check" label="Opslaan" />}
				</ButtonArea>
			</CardPadding>
		</Form>
	</Card>;
}
