import React, { useEffect, useState } from 'react';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import CardPadding from '../../lib/ui/CardPadding';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ColorField, { isValidColor } from '../../lib/forms/simple/ColorField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import { get, post, put } from '../../../util/api';
import CompetenceEditLevel from './CompetenceEditLevel';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import HtmlField from '../../lib/forms/simple/HtmlField';
import WithValidation from '../../lib/forms/WithValidation';
import { useIntl } from 'react-intl';

function createNewLevels(competence) {
	// for now its hard-coded that there are always 6 levels when creating a new competence.
	// Probably we are gonna change it in the future
	if(competence.levels.length == 5) return;
	const sorting = competence.levels.length > 0 ? competence.levels.sort((a, b) => { if(a.sorting - b.sorting) return -1; else return 1; })[0].sorting + 1 : 0;
	const levels = [ 'B', 'C', 'D', 'E', 'F' ];
	while (competence.levels.length < 5) {
		competence.levels.push({
			name: competence.name + ' ' + levels[competence.levels.length],
			label: competence.label + ' ' + levels[competence.levels.length],
			version: competence.version,
			explanationText: '',
			finishedText: '',
			color: competence.color,
			questions: [],
			sorting: sorting,
		});
	}
	return competence.levels;
}

function invalidColorsForCompetence(competence) {
	const invalidColor = (lvl) => !isValidColor(lvl.color);
	return competence.levels.some(invalidColor); // returns true if there is at least one invalid color, returns false if all colors are correct
}

function validityCompetence(competence) {
	return invalidColorsForCompetence(competence) || !isValidColor(competence.color) || competence.name.length == 0 || competence.label.length == 0;
}

export default function CompetenceEdit({ match, history }) {
	const intl = useIntl();
	const competenceId = match.params.id;

	const [ loading, setLoading ] = useState(true);
	const [ competence, setCompetence ] = useState(null);

	useEffect(() => {
		if (competenceId == null) {
			setCompetence({
				name: '',
				label: '',
				version: '',
				color: '',
				baseLevelDescription: '',
				baseLevelDescriptionAlternative: '',
				associations: '',
				definition: '',
				explanation: '',
				levels: [],
			});
			setLoading(false);
		}

		get('/competences/' + competenceId).then(setCompetence).finally(() => setLoading(false));
	}, [ competenceId ]);

	return <Card>
		<CardToolbar title={competenceId == null ? 'Competentie aanmaken' : 'Competentie bewerken'} />
		{!loading && <CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				if (competenceId) {
					put('/competences/' + competenceId, competence).finally(() => history.push('/admin/competences'));
				} else {
					post('/competences', competence).finally(() => history.push('/admin/competences'));
				}
				return false;
			}}>
				<WithLabel label="Competentienaam">
					<WithValidation valid={competence.name.length > 0} icon={false}>
						<InputField value={competence.name} onChange={name => setCompetence({ ...competence, name })} />
					</WithValidation>
				</WithLabel>

				<WithLabel label="Label">
					<WithValidation valid={competence.label.length > 0} icon={false}>
						<InputField value={competence.label} onChange={label => setCompetence({ ...competence, label })} />
					</WithValidation>
				</WithLabel>

				<WithLabel label="Versie">
					<InputField value={competence.version} onChange={version => setCompetence({ ...competence, version })} />
				</WithLabel>

				<WithLabel label="Kleur">
					<ColorField value={competence.color} onChange={color => setCompetence({ ...competence, color })} />
				</WithLabel>

				<div className="form-2-col">
					<WithLabel label="Beschrijving basisniveau">
						<HtmlField value={competence.baseLevelDescription} onChange={bld => setCompetence({ ...competence, baseLevelDescription: bld })} />
					</WithLabel>

					<WithLabel label="Alternatieve beschrijving basisniveau (voor feedback)">
						<HtmlField value={competence.baseLevelDescriptionAlternative} onChange={blda => setCompetence({ ...competence, baseLevelDescriptionAlternative: blda })} />
					</WithLabel>

					<WithLabel label="Samenhang">
						<HtmlField value={competence.associations} onChange={associations => setCompetence({ ...competence, associations })} />
					</WithLabel>

					<WithLabel label="Definitie">
						<HtmlField value={competence.definition} onChange={definition => setCompetence({ ...competence, definition })} />
					</WithLabel>

					<WithLabel label="Uitleg">
						<HtmlField value={competence.explanation} onChange={explanation => setCompetence({ ...competence, explanation })} />
					</WithLabel>
				</div>

				{competence.levels.map((level, idx) => <CompetenceEditLevel
					setLevel={updatedLevel => setCompetence({ ...competence, levels: competence.levels.map((l, i) => (i == idx ? updatedLevel : l)) })}
					removeLevel={() => setCompetence({ ...competence, levels: competence.levels.filter((_, i) => i != idx) })}
					level={level}
					key={idx} />)}

				{competence.levels.length < 5 && <ButtonArea>
					<TertiaryButton onClick={() => setCompetence({ ...competence, levels: createNewLevels(competence) })} icon="plus" label="Niveau toevoegen" />
				</ButtonArea>}

				<ButtonArea>
					<TertiaryLinkButton label="Annuleren" to="/admin/competences" />
					{validityCompetence(competence) ? <abbr title={intl.formatMessage({ id: 'competenceValidationFields' })}>
						<Button disabled={validityCompetence(competence)} icon="check" label={intl.formatMessage({ id: 'save' })} type="submit" /> </abbr> :
						<Button icon="check" label={intl.formatMessage({ id: 'save' })} type="submit" /> }
				</ButtonArea>
			</form>
		</CardPadding>}
		{loading && <CardLoadingSpinner />}
	</Card>;

}
