import React, { useState } from 'react';
import CardToolbar from '../lib/ui/CardToolbar';
import CardPadding from '../lib/ui/CardPadding';
import { put } from '../../util/api';
import WithLabel from '../lib/forms/WithLabel';
import WithStatusIcon from '../lib/forms/WithStatusIcon';
import InputField from '../lib/forms/simple/InputField';
import WithValidation from '../lib/forms/WithValidation';
import ButtonArea from '../lib/forms/ButtonArea';
import Button from '../lib/forms/simple/Button';
import Card from '../lib/ui/Card';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import NavigateAwayPrompt from '../lib/forms/NavigateAwayPrompt';
import TertiaryButton from '../lib/forms/simple/TertiaryButton';
import { FormattedMessage, useIntl } from 'react-intl';

export default function ProfileUpdatePassword() {
	const [ redirect, setRedirect ] = useState(null);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);

	const [ currentPassword, setCurrentPassword ] = useState('');
	const [ newPassword, setNewPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const checkPasswords = currentPassword.length == 0 || newPassword.length < 8 || confirmPassword.length < 8 || newPassword != confirmPassword;
	const intl = useIntl();

	return <div>
		<NavigateAwayPrompt redirect={redirect} />
		<Card>
			<CardToolbar title={intl.formatMessage({ id: "changePassword" })} />
			<CardPadding>
				<form onSubmit={event => {
					event.preventDefault();
					event.stopPropagation();
					setLoading(true);
					put('/users/me/password', { currentPassword, newPassword }, false).then(() => { setRedirect('/profile'); setLoading(false); setError(null); })
						.catch(err => { setLoading(false); setError(intl.formatMessage({ id: "currentPasswordIncorrect" })); setCurrentPassword(''); });
					return false;
				}}>
					{error != null && <p className="error-message"><FormattedMessage id="currentPasswordIncorrect" /></p>}
					<WithLabel label={intl.formatMessage({ id: "currentPassword" })}>
						<WithStatusIcon icon={error != null ? <span style={{ color: 'red' }} className= "fa fa-exclamation-triangle"/> : <span className="fa fa-lock" />}>
							<InputField style={{ border: error != null ? "1px solid var(--col-red-500)" : "" }} type="password" value={currentPassword} onChange={setCurrentPassword} />
						</WithStatusIcon>
						<p className="form-explanation"><FormattedMessage id="passwordVerificationExplanation" /></p>
					</WithLabel>
					<br />
					<WithLabel label={intl.formatMessage({ id: "newPassword" })}>
						<WithValidation valid={newPassword.length >= 8}>
							<InputField type="password" value={newPassword} onChange={setNewPassword} />
						</WithValidation>
						<p className="form-explanation"><FormattedMessage id="newPasswordRequirements" /></p>
					</WithLabel>
					<WithLabel label={intl.formatMessage({ id: "repeatPassword" })}>
						<WithValidation valid={confirmPassword.length >= 8 && newPassword == confirmPassword}>
							<InputField type="password" value={confirmPassword} onChange={setConfirmPassword} />
						</WithValidation>
					</WithLabel>
					<ButtonArea>
						<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={() => setRedirect('/profile')} />
						{checkPasswords ?
							<abbr title={intl.formatMessage({ id: "passwordsDoNotMatch" })}>
								<Button icon="check" disabled={checkPasswords} label={intl.formatMessage({ id: "change" })} type="submit"/>
							</abbr>
							: <Button icon="check" label={intl.formatMessage({ id: "change" })} type="submit"/> }
					</ButtonArea>

					{loading && <CardLoadingSpinner />}
				</form>
			</CardPadding>
		</Card>
	</div>;
}
