import React, { Fragment, useEffect, useState } from 'react';
import CardPadding from '../lib/ui/CardPadding';
import Card from '../lib/ui/Card';
import { get } from '../../util/api';
import CardToolbar from '../lib/ui/CardToolbar';

export default function AdminOverview() {
	const [ stats, setStats ] = useState(null);

	useEffect(() => {
		get('/admin/dashboard').then(setStats);
	}, []);

	return <Fragment>
		<Card>
			<CardToolbar title="Afgelopen 30 dagen" />
			<CardPadding>
				{stats != null && <div className="admin-overview admin-overview-2-col">
					<div>
						<span className="big-fat-number">{stats.loginsPast30Days}</span><br />
						<span className="stat-description">Logins</span>
					</div>
					<div>
						<span className="big-fat-number">{stats.assessmentsCreatedPast30Days}</span><br />
						<span className="stat-description">Competenties ingevuld</span>
					</div>
				</div>}
			</CardPadding>
		</Card>
		<Card>
			<CardToolbar title="Gebruikerstotalen" />
			<CardPadding>
				{stats != null && <div className="admin-overview admin-overview-3-col">
					<div>
						<span className="big-fat-number">{stats.students}</span><br />
						<span className="stat-description">Leerlingen</span>
					</div>
					<div>
						<span className="big-fat-number">{stats.teachers}</span><br />
						<span className="stat-description">Begeleiders</span>
					</div>
					<div>
						<span className="big-fat-number">{stats.schools}</span><br />
						<span className="stat-description">Scholen</span>
					</div>
				</div>}
			</CardPadding>
		</Card>
	</Fragment>;
}
