import React from 'react';
import RelativeTime from '../../../lib/datetime/RelativeTime';

export default function Comment({ createdAt, authorFullName, content }) {
	return <div className="comment">
		<div className="comment-header">
			<span className="comment-header-author">{authorFullName || 'Verwijderde gebruiker'}</span>
			<span className="comment-header-created">
				<RelativeTime datetime={createdAt} />
			</span>
		</div>
		<div className="comment-content">
			{content}
		</div>
	</div>;
}
