import { useState } from 'react';

export default function usePersistentState(defaultValue, key) {
	const storageKey = 'cm.persistentHook.' + key;
	const [ value, setter ] = useState(localStorage.getItem(storageKey) != null ? JSON.parse(localStorage.getItem(storageKey)) : defaultValue);

	return [
		value,
		newValue => {
			localStorage.setItem(storageKey, JSON.stringify(newValue));
			setter(newValue);
		},
	];
}
