import React from 'react';

function cycleDirection(dir) {
	return {
		'asc': 'desc',
		'desc': 'none',
		'none': 'asc',
	}[dir || 'none'];
}

export default function SortableHeader({ children, name, firstSortName, firstSortDirection, secondSortName, secondSortDirection, onChange, alignedRight = false }) {
	return <th className={!alignedRight ? "th-sortable" : "th-sortable-aligned-right"} onClick={() => {
		if (name == firstSortName) {
			if (firstSortDirection == 'desc') {
				onChange(firstSortName, 'none', secondSortName, 'none');
			} else {
				onChange(firstSortName, cycleDirection(firstSortDirection), secondSortName, secondSortDirection);
			}
		} else if (name == secondSortName && secondSortDirection != 'none') {
			onChange(firstSortName, firstSortDirection, secondSortName, cycleDirection(secondSortDirection));
		} else if (firstSortDirection == 'none') {
			onChange(name, 'asc', secondSortName, secondSortDirection);
		} else if(secondSortDirection == 'none') {
			onChange(firstSortName, firstSortDirection, name, 'asc');
		} else {
			onChange(name, 'asc', secondSortName, 'none');
		}
	}}>
		{name == firstSortName && firstSortDirection == 'asc' && <span><span className="fa fa-angle-down" />&nbsp;</span>}
		{name == secondSortName && secondSortDirection == 'asc' && <span><span className="fa fa-angle-double-down" />&nbsp;</span>}
		{name == firstSortName && firstSortDirection == 'desc' && <span><span className="fa fa-angle-up" />&nbsp;</span>}
		{name == secondSortName && secondSortDirection == 'desc' && <span><span className="fa fa-angle-double-up" />&nbsp;</span>}
		{children}
	</th>;
}
