import React from 'react';
import technasiumLogo from '../../assets/technasium/original/beeldmerk Technasium (zonder tekst).png';

export default function StaticPageFooter() {
	return <div className="static-page-footer">
		<div className="static-page-footer-logo">
			<img src={technasiumLogo} alt="Technasium Logo" />
		</div>
		<div className="static-page-footer-content">
			<a href="#">Privacy statement</a><br />
			<a href="http://technasium.nl">Technasium</a>
		</div>
	</div>;
}
