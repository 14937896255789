import React from 'react';

export default function ErrorMessage({ error }) {
	return <div className="error-message">
		<div className="error-message-icon">
			<span className="fa fa-exclamation-circle error-icon" />
		</div>
		<div className="error-message-content">
			{error}
		</div>
	</div>;
}
