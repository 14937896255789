import React, { Fragment, useContext, useEffect, useState } from 'react';
import Card from '../lib/ui/Card';
import CardPadding from '../lib/ui/CardPadding';
import { get, put } from '../../util/api';
import FormRow from '../lib/forms/layout/FormRow';
import TertiaryLinkButton from '../lib/forms/simple/TertiaryLinkButton';
import TeachersWithRelationToMe from './TeachersWithRelationToMe';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import UserDataChangeCounter from '../../context/UserDataChangeCounter';
import LanguageContext from '../../context/Language';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectField from '../lib/forms/simple/SelectField';

export default function Profile() {
	const userDataChangeCounter = useContext(UserDataChangeCounter);
	const [ loading, setLoading ] = useState(true);
	// const languageObject = useContext(LanguageContext);
	// const { language } = languageObject;
	// const { setLanguage } = languageObject;

	const [ id, setId ] = useState(null);
	const [ me, setMe ] = useState(null);
	const intl = useIntl();

	function load(me) {
		setLoading(false);
		setId(me.id);
		setMe(me);
	}

	useEffect(() => {
		get('/whoami').then(load);
	}, [ userDataChangeCounter.value ]);

	return <Fragment>
		<Card>
			{me != null && <CardPadding>
				<FormRow title={intl.formatMessage({ id: "fullName" })}
				         customInfo={<TertiaryLinkButton label={intl.formatMessage({ id: "edit" })} icon="angle-right" iconRight={true} to="/profile/updateName" />}>
					<div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}><span>{me.fullName}</span><br /></div>
				</FormRow>
				{/* <FormRow title={intl.formatMessage({ id: "language" })}>*/}
				{/*	<SelectField*/}
				{/*		options={[*/}
				{/*			{ value: 'Dutch', label: 'Nederlands' },*/}
				{/*			{ value: 'English', label: 'English' },*/}
				{/*		]}*/}
				{/*		onChange={l => {*/}
				{/*			setLanguage(l);*/}
				{/*			put('/users/me/language', { language: l });*/}
				{/*		}}*/}
				{/*		value={language} />*/}
				{/* </FormRow>*/}
				<FormRow title={intl.formatMessage({ id: "loginDetails" })}
				         description={intl.formatMessage({ id: "cannotSeePassword" })}
				         customInfo={<Fragment>
					         <TertiaryLinkButton label={intl.formatMessage({ id: "changeUsernameEmail" })} icon="angle-right" iconRight={true} to="/profile/updateLoginDetails" />
					         <br />
					         <TertiaryLinkButton label={intl.formatMessage({ id: "changePassword" })} icon="angle-right" iconRight={true} to="/profile/updatePassword" />
				         </Fragment>}>
					<span>{me.username}</span><br />
					<span>{me.email}</span><br />
				</FormRow>
				{me.studentInfo && <FormRow title={intl.formatMessage({ id: "studentData" })}
				                            customInfo={<TertiaryLinkButton label={intl.formatMessage({ id: "edit" })} icon="angle-right" iconRight={true} to="/profile/updateStudentInfo" />}>
					<span>{me.studentInfo.studentNumber || <FormattedMessage id="noStudentNumberKnown" />}</span><br />
					<span>{(me.studentInfo.birthDate || '').split('-').reverse().join('-') || <FormattedMessage id="noBirthdayKnown" />}</span><br /><br />
					<span>{me.studentInfo.schoolClassName || <FormattedMessage id="noClass" />} - {me.studentInfo.schoolName}</span>
				</FormRow>}
				{me.studentInfo && <FormRow title={intl.formatMessage({ id: "supervisors" })}
				                            description={intl.formatMessage({ id: "supervisorsWithAccess" })}>
					<TeachersWithRelationToMe />
				</FormRow>}
				{me.teacherInfo && <FormRow title={intl.formatMessage({ id: "inviteStudents" })}>
					<TertiaryLinkButton icon="paper-plane" label={intl.formatMessage({ id: "inviteStudentsViaTeacherCode" })} to="/inviteStudents" />
				</FormRow>}
			</CardPadding>}

			{loading && <CardLoadingSpinner />}
		</Card>
	</Fragment>;
}
