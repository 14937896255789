import React, { useEffect, useState } from 'react';
import { get } from '../../util/api';
import SelectField from '../lib/forms/simple/SelectField';
import WithLabel from '../lib/forms/WithLabel';
import { Bar, BarChart, Cell, Label, Tooltip, XAxis, YAxis } from 'recharts';
import { FormattedMessage, useIntl } from 'react-intl';

function cmpCompetences(a, b) {
	if (a.name.toLowerCase() < b.name.toLowerCase()) {
		return -1;
	} else if (a.name.toLowerCase() == b.name.toLowerCase()) {
		return 0;
	} else {
		return 1;
	}
}

export default function ScoreRelativeToClass({ studentId, schoolClassId }) {
	const [ competences, setCompetences ] = useState([]);
	const [ classScores, setClassScores ] = useState(null);
	const [ studentAssessments, setStudentAssessments ] = useState(null);
	const [ selectedCompetence, setSelectedCompetence ] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		get('/schoolclasses/' + schoolClassId + '/stats').then(setClassScores);
		get('/monitors/byStudent/' + studentId).then(monitor => {
			const orderedCompetences = monitor.competences.sort(cmpCompetences);
			setCompetences(orderedCompetences);
			if (selectedCompetence == null) setSelectedCompetence(orderedCompetences[0].id);
		});
		get('/assessments/student/' + studentId + '/latest').then(setStudentAssessments);
	}, [ schoolClassId ]);

	// Don't show if there is too few samples that the samples could be identified individually
	// This is necessary due to the GDPR / AVG laws
	if (classScores == null || studentAssessments == null || classScores.scoresByCompetence == null || classScores.schoolClassSize < 5) {
		return <div>
			<span><FormattedMessage id="tooFewResultsToShowGraph" /></span>
		</div>;
	}
	const scoresForThisCompetence = classScores.scoresByCompetence.find(sc => sc.competence.id == selectedCompetence);


	const normalizedCountByGrade = ((scoresForThisCompetence || {}).countByGrade || {});
	normalizedCountByGrade[-1] = 0;
	const data = [
		{ name: 'A', grade: 0, count: normalizedCountByGrade[0] || 0 },
		{ name: 'B', grade: 1, count: normalizedCountByGrade[1] || 0 },
		{ name: 'C', grade: 2, count: normalizedCountByGrade[2] || 0 },
		{ name: 'D', grade: 3, count: normalizedCountByGrade[3] || 0 },
		{ name: 'E', grade: 4, count: normalizedCountByGrade[4] || 0 },
		{ name: 'F', grade: 5, count: normalizedCountByGrade[5] || 0 },
	];
	const COLORS = [ '#0088FE', '#00C49F', '#FFBB28', '#FF8042' ];

	const studentGradeForSelected = (studentAssessments.find(sa => sa.competenceId == selectedCompetence) || {}).grade || 0;
	const numberWithLowerScore = Object.entries(normalizedCountByGrade).filter(x => x[0] < studentGradeForSelected).reduce((total, x) => total + x[1], 0);
	const numberWithEqualScore = Object.entries(normalizedCountByGrade).filter(x => x[0] == studentGradeForSelected).reduce((total, x) => total + x[1], 0) - 1;
	const numberWithHigherScore = Object.entries(normalizedCountByGrade).filter(x => x[0] > studentGradeForSelected).reduce((total, x) => total + x[1], 0);
	const numberAssessmentsTotal = Math.max(Object.entries(normalizedCountByGrade).reduce((total, x) => total + x[1], 0) - 1, 0);

	return <div>
		<WithLabel label={intl.formatMessage({ id: "competence" })}>
			<SelectField options={competences.map(c => ({ label: c.label, value: c.id }))} onChange={setSelectedCompetence} value={selectedCompetence} />
		</WithLabel>

		<WithLabel label={intl.formatMessage({ id: "distributionInClass" })}>
			<BarChart
				width={600}
				height={300}
				data={data}>
				<Tooltip separator={': '} formatter={(value, key, props) => [ value + (props.payload.grade == studentGradeForSelected ? intl.formatMessage({ id: "includingThisStudent" }) : ''), intl.formatMessage({ id: "count" }) ]} />
				<XAxis dataKey="name" />
				<YAxis label={{ value: intl.formatMessage({ id: "competence" }), angle: -90, dx: -15, style: { fontSize: 14 } }} />
				<Bar
					dataKey="count"
					fill="#8884d8">
					{data.map((entry, idx) => <Cell key={idx} fill={studentGradeForSelected != entry.grade ? 'var(--col-primary-300)' : 'var(--col-primary-500)'} />)}
				</Bar>
			</BarChart>
		</WithLabel>
		<WithLabel label={intl.formatMessage({ id: "relativeResults" })}>
			<p><FormattedMessage id="relativeScoreText" values={{ numberAssessmentsTotal: numberAssessmentsTotal }} /> </p>
			{numberAssessmentsTotal > 0 && <table>
				<tbody>
					<tr><td style={{ textAlign: 'right' }}>{Math.round(numberWithLowerScore / numberAssessmentsTotal * 100) + '%'}</td><td><FormattedMessage id="fromClassScoredLower" /></td></tr>
					<tr><td style={{ textAlign: 'right' }}>{Math.round(numberWithEqualScore / numberAssessmentsTotal * 100) + '%'}</td><td><FormattedMessage id="fromClassScoredEqual" /></td></tr>
					<tr><td style={{ textAlign: 'right' }}>{Math.round(numberWithHigherScore / numberAssessmentsTotal * 100) + '%'}</td><td><FormattedMessage id="fromClassScoredHigher" /></td></tr>
				</tbody>
			</table>}
		</WithLabel>
	</div>;
}
