import React, { useEffect, useState } from 'react';
import { get } from '../../util/api';
import Card from '../lib/ui/Card';
import CardPadding from '../lib/ui/CardPadding';
import TertiaryButton from '../lib/forms/simple/TertiaryButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';

export default function InviteStudents() {
	const [ signup, setSignup ] = useState(null);
	const [ paste, setPaste ] = useState(false);

	useEffect(() => {
		get('/signup/code').then(setSignup);
	}, []);

	return <div>
		<Card>
			<CardPadding>
				<div style={{ textAlign: 'center', maxWidth: 'var(--u-768)', margin: '0 auto' }}>
					<p><FormattedMessage id="inviteStudentsMessage" /></p><br /><br />
					<span className="signup-code">{signup && signup.signupCode}</span><br /><br />
					<CopyToClipboard text={signup && signup.signupCode} onCopy={() => {
						setPaste(true);
						setTimeout(() => setPaste(false), 1500);
					}}>
						<TertiaryButton icon={paste ? 'check' : 'copy'} label={paste ? <FormattedMessage id="copied" /> : <FormattedMessage id="copy" />} />
					</CopyToClipboard>
					<br /><br />
				</div>
			</CardPadding>
		</Card>
	</div>;
}
