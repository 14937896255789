import React from 'react';

export default function GraphOverlay({ message, children }) {
	return <div className="graph-overlay-wrapper">
		{children}
		{message && <div className="graph-overlay">
			<div className="graph-overlay-message">
				{message}
			</div>
		</div>}
	</div>;
}
