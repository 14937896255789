import React, { useState, useEffect } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import { get } from '../../../util/api';
import reactHtmlParser from 'react-html-parser';
import CardPadding from '../../lib/ui/CardPadding';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';

function sortByTitle(a, b) {
	if (a.title.toLowerCase() < b.title.toLowerCase()) {
		return -1;
	} else if (a.title.toLowerCase() == b.title.toLowerCase()) {
		return 0;
	} else {
		return 1;
	}
}

export default function PagesOverview() {
	const [ pages, setPages ] = useState([]);

	useEffect(() => {
		get('/pages').then(pages => pages.sort(sortByTitle)).then(setPages);
	}, []);

	return <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: 'var(--u-32)' }}>
		{pages.map(page => <Card>
			<CardToolbar title={page.title}>
				<TertiaryLinkButton icon="angle-right" iconRight={true} label="Bewerken" to={'/admin/pages/' + page.id} />
			</CardToolbar>
			<CardPadding>
				<div className="page-overview-card-content">
					{reactHtmlParser(page.content)}
				</div>
			</CardPadding>
		</Card>)}
	</div>;
}
