import React, { useEffect, useRef, useState } from 'react';
import Card from '../lib/ui/Card';
import CardToolbar from '../lib/ui/CardToolbar';
import CardPadding from '../lib/ui/CardPadding';
import { get } from '../../util/api';
import { useIntl } from 'react-intl';
import WithLabel from '../lib/forms/WithLabel';
import ButtonArea from '../lib/forms/ButtonArea';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import CardAccent from '../lib/ui/CardAccent';
import CollapsableArea from '../lib/ui/CollapsableArea';
import TaxonomyHtmlRenderer from '../taxonomy/TaxonomyHtmlRenderer';
import Button from '../lib/forms/simple/Button';
import { Redirect } from 'react-router';
import DownloadSubmittedFeedback from './DownloadSubmittedFeedback';
import ReactToPrint from 'react-to-print';

export default function ViewSubmittedFeedback({ match }) {
	const { feedbackRequestId } = match.params;
	const [ loading, setLoading ] = useState(true);
	const [ redirect, setRedirect ] = useState(null);
	const [ feedbackRequest, setFeedbackRequest ] = useState(null);
	const grades = [ 'A', 'B', 'C', 'D', 'E', 'F' ];
	const intl = useIntl();

	useEffect(() => {
		get('/feedbackRequest/' + feedbackRequestId).then((fr) => setFeedbackRequest(fr)).finally(setLoading(false));
	}, [ feedbackRequestId ]);

	function getCurrentLevelDescription(f) {
		// grade: A -> null; B -> 0; C -> 1; D -> 2; E -> 3; F -> 4; finished (passed F) -> 5
		if (f.grade == null || f.grade == 0 || f.competence.levels == null) { // nothing filled in is the same as level A
			if (f.competence.baseLevelDescriptionAlternative != null) {
				return f.competence.baseLevelDescriptionAlternative; // use alternative description if available (the default one usually contains a call to action, so alternative is preferred)
			} else {
				return f.competence.baseLevelDescription;
			}
		} else {
			return f.competence.levels[f.grade - 1].explanationText;
		}
	}

	function getCurrentLevel(f) {
		// grade: A -> null; B -> 0; C -> 1; D -> 2; E -> 3; F -> 4; finished (passed F) -> 5
		if (f != null && f.grade != null) { // if something is filled in
			return grades[f.grade];
		} else { // default level
			return 'A';
		}
	}

	const componentRef = useRef();

	return <Card>
		{redirect && <Redirect to={redirect} />}
		{loading && <CardLoadingSpinner />}

		{feedbackRequest != null && <DownloadSubmittedFeedback
			ref={componentRef}
			feedbackRequest={feedbackRequest}
			getCurrentLevel={l => getCurrentLevel(l)}
		/>}

		{!loading && feedbackRequest != null && <>
			<CardToolbar title={intl.formatMessage({ id: "viewFeedbackYouReceivedOn" }) + ' ' + intl.formatDate(feedbackRequest.dateSubmitted) + ' ' + intl.formatMessage({ id: "from" }) + ':'} />
			<CardPadding>

				<WithLabel label={intl.formatMessage({ id: "name" })}>
					<span>{feedbackRequest.name}</span>
				</WithLabel>

				<WithLabel label={intl.formatMessage({ id: "email" })}>
					<span>{feedbackRequest.email}</span>
				</WithLabel>

				{feedbackRequest.feedbacks.toSorted((a, b) => { return a.competence.label.localeCompare(b.competence.label); }).map(f => <Card>
					<Card>
						<CardAccent color={f.competence.color} />
						<div className="assessment-grid">
							<CardPadding>
								<h1>{f.competence.label}</h1>
								<CollapsableArea label={intl.formatMessage({ id: "explanation" })} >
									<TaxonomyHtmlRenderer html={f.competence.explanation} />
								</CollapsableArea>
								<CollapsableArea label={intl.formatMessage({ id: "definition" })} >
									<TaxonomyHtmlRenderer html={f.competence.definition} />
								</CollapsableArea>
								<CollapsableArea label={intl.formatMessage({ id: "currentLevel" })} defaultOpen={true} >
									<TaxonomyHtmlRenderer html={getCurrentLevelDescription(f)} />
								</CollapsableArea>
							</CardPadding>
							<CardPadding>
								<WithLabel label={f.question1}>{f.answer1}</WithLabel>
								<hr />
								<WithLabel label={f.question2}>{f.answer2}</WithLabel>
								<hr />
								<WithLabel label={f.question3}>{f.answer3}</WithLabel>
							</CardPadding>
						</div>
					</Card>
				</Card>)}

				<ButtonArea>
					<ReactToPrint
						trigger={() => <Button label={intl.formatMessage({ id: "print" })} icon="print" />}
						content={() => componentRef.current}
					/>
					<Button style={{ marginLeft: "25px" }} label={intl.formatMessage({ id: "back" })} onClick={() => setRedirect('/feedback')} />
				</ButtonArea>
			</CardPadding>
		</>}
	</Card>;
}