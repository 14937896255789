import React, { useContext, useEffect, useState } from 'react';
import { del, get, put } from '../../../util/api';
import UserContext from '../../../context/User';
import Card from '../../lib/ui/Card';
import CardPadding from '../../lib/ui/CardPadding';
import FormRow from '../../lib/forms/layout/FormRow';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import CardToolbar from '../../lib/ui/CardToolbar';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import ConfirmPopup from '../../lib/forms/confirmation/ConfirmPopup';
import SchoolClassScore from './SchoolClassScore';
import { FormattedMessage, useIntl } from 'react-intl';
import SchoolClassMarkImportant from './SchoolClassMarkImportant';

export default function SchoolClassView({ match, history }) {
	const user = useContext(UserContext);
	const schoolClassId = match.params.id;
	const [ loading, setLoading ] = useState(true);
	const [ relations, setRelations ] = useState(null);
	const [ schoolClass, setSchoolClass ] = useState(null);
	const [ students, setStudents ] = useState([]);
	const [ confirmDelete, setConfirmDelete ] = useState(false);
	const intl = useIntl();

	useEffect(() => {
		get('/schoolclasses/' + schoolClassId).then(setSchoolClass).finally(() => setLoading(false));
		get('/schoolclasses/' + schoolClassId + '/students').then(setStudents);
		get('/me/teacher/studentTeacherRelations')
			.then(relations => setRelations((relations)))
			.catch(e => e.text())
			.finally(() => setLoading(false));
	}, [ schoolClassId ]);

	function teacherHasAccess(student) {
		return relations.filter(r => r.studentRole.id == student.studentInfo.id)[0] != null && relations.filter(r => r.studentRole.id == student.studentInfo.id)[0].hasAccess;
	}

	return <Card>
		{!loading && schoolClass && <CardPadding>
			<ConfirmPopup
				open={confirmDelete}
				onConfirm={() => {
					del('/schoolclasses/' + schoolClassId)
						.then(() => history.push('/schoolclasses'))
						.finally(() => setConfirmDelete(false));
				}}
				onCancel={() => setConfirmDelete(false)}
				formComponent={({ onCancel, onConfirm }) => <Card>
					<CardToolbar title={intl.formatMessage({ id: "deleteClass" })} />
					<CardPadding>
						<p><FormattedMessage id="deleteClassConfirmation" />.</p>
						{schoolClass.numberOfStudents > 0 && <p><FormattedMessage id="deleteClassStudentsWarning" values={{ numberOfStudents: schoolClass.numberOfStudents }} /></p>}
						<ButtonArea>
							<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={onCancel} />
							<Button style={{ background: 'var(--col-red)' }} icon="trash" label={intl.formatMessage({ id: "delete" })} onClick={onConfirm} />
						</ButtonArea>
					</CardPadding>
				</Card>} />

			<FormRow title={intl.formatMessage({ id: "class" })} customInfo={user.schoolAdminInfo && <TertiaryLinkButton to={'/schoolclasses/edit/' + schoolClassId} label={intl.formatMessage({ id: "edit" })} iconRight={true} icon="angle-right" />}>
				{schoolClass.name}<br />
				{(schoolClass.schoolClassType || {}).name || <FormattedMessage id="unknownType" />}<br />
				{schoolClass.numberOfStudents == 1 ? schoolClass.numberOfStudents + ' ' + intl.formatMessage({ id: "lowercaseStudent" }) : schoolClass.numberOfStudents + ' ' + intl.formatMessage({ id: "lowercaseStudents" })}<br /><br />
				<TertiaryButton icon="trash-o" label={intl.formatMessage({ id: "deleteClass" })} onClick={() => setConfirmDelete(true)} />
			</FormRow>

			<FormRow title={intl.formatMessage({ id: "results" })} description={students.length > 4 && intl.formatMessage({ id: "competencesExplanation" })}>
				<SchoolClassScore schoolClassId={schoolClassId} schoolId={(user.teacherInfo?.schoolId || user.schoolAdminInfo?.schoolId)} />
			</FormRow>

			<FormRow title={intl.formatMessage({ id: "markCompetences" })} description={intl.formatMessage({ id: "markCompetencesAsImportantExplanation" })}>
				<SchoolClassMarkImportant schoolClassId={schoolClassId} schoolId={(user.teacherInfo?.schoolId || user.schoolAdminInfo?.schoolId)} />
			</FormRow>

			{students.length > 0 && <FormRow title={intl.formatMessage({ id: "students" })} description={intl.formatMessage({ id: "teacherGivenAccessByStudents" })}>
				<table className="table">
					<thead>
						<tr>
							<th><FormattedMessage id="student" /></th>
							<th><FormattedMessage id="email" /></th>
							<th><FormattedMessage id="number" /></th>
							<th/>
						</tr>
					</thead>
					<tbody>
						{students.map(s => <tr key={s.id}>
							<td>{s.fullName}</td>
							<td>{s.email}</td>
							<td>{(s.studentInfo || {}).studentNumber}</td>
							<td>
								{relations != null && !teacherHasAccess(s) ?
									<abbr title={intl.formatMessage({ id: "teacherNotGivenAccessByParticularStudent" })}>
										<Button style={{ background: 'none', boxShadow: 'none', padding: '0px' }} disabled={true} label={intl.formatMessage({ id: "view" })} ></Button>
									</abbr> :
									<TertiaryLinkButton to={'/students/' + s.studentInfo.id + '/info'} label={intl.formatMessage({ id: "view" })} icon="angle-right" iconRight={true} onClick={() => put('/me/teacher/studentTeacherRelations/markAsRead/' + s.studentInfo.id, null, false)} />}
							</td>
						</tr>)}
					</tbody>
				</table>
			</FormRow> }
		</CardPadding>}
	</Card>;
}
