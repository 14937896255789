import React from 'react';
import ReactModal from 'react-modal';

const customStyles = {
	overlay: {
		background: 'rgba(0, 0, 0, 0.7)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	content: {
		border: 'none',
		borderRadius: '2px',
		boxShadow: '0 4px 16px -8px rgba(0, 0, 0, 0.2)',
		position: 'relative',
		width: '100%',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		padding: 'var(--u-16) var(--u-24)',
		overflow: 'visible',
	},
};

export default function Modal({ children, minWidth = 'var(--u-256)', maxWidth = 'calc(var(--u-512) + var(--u-256)', hasWizardProgress = false, ...props }) {
	const styles = {
		...customStyles,
		overlay: {
			...customStyles.overlay,
			paddingTop: hasWizardProgress ? 'var(--u-96)' : null,
		},
		content: {
			...customStyles.content,
			maxWidth,
			minWidth,
			transform: 'translateY(-5vh)',
		},
	};
	return <ReactModal isOpen={true} style={styles} {...props}>
		{children}
	</ReactModal>;
}
