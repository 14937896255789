import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { get } from '../../../../util/api';

function schoolClassToRsElement(schoolClass) {
	if (schoolClass == null) return null;
	return { label: schoolClass.name, value: schoolClass.id };
}

export default function PotentialSchoolClassesField({ onChange, value, ...props }) {
	const [ schoolClasses, setSchoolClasses ] = useState(null);
	const [ schoolClassesById, setSchoolClassesById ] = useState({});

	useEffect(() => {
		get('/me/student/potentialSchoolClasses').then(schoolClasses => {
			setSchoolClasses(schoolClasses);
			setSchoolClassesById(schoolClasses.reduce((tot, elem) => ({ ...tot, [elem.id]: elem }), {}));
		});
	}, []);

	return <Select
		{...props}
		options={schoolClasses == null ? [] : schoolClasses.map(schoolClassToRsElement)}
		onChange={v => onChange(v == null ? null : schoolClassesById[v.value])}
		value={value == null ? null : schoolClassToRsElement(schoolClassesById[value])}
		className="react-select"
		classNamePrefix="react-select" />;
}
