import React from 'react';
import WithValidation from '../WithValidation';
import InputField from './InputField';

export function isValidColor(value) {
	const hexRegex = new RegExp('#[0-9a-fA-F]{6}', 'i');
	return value != null && value.match(hexRegex);
}

export default function ColorField({ onChange, value }) {

	return <div className="color-input-field">
		<div className="color-input-field-example" style={{ background: !isValidColor(value) ? '#000' : value }} />
		<WithValidation valid={isValidColor(value)}>
			<InputField onChange={onChange} value={value} />
		</WithValidation>
	</div>;
}
