import React, { useState, useEffect } from 'react';
import { get } from '../../util/api';
import reactHtmlParser from 'react-html-parser';
import StaticPageFooter from './StaticPageFooter';

export default function StaticPage({ match }) {
	const { pageId } = match.params;
	const [ page, setPage ] = useState(null);

	useEffect(() => {
		get('/pages/' + pageId).then(setPage);
	}, [ pageId ]);

	if (page == null) return null;
	return <div className="static-page-wrapper">
		<div className="static-page">
			<h1 className="static-page-title">{page.title}</h1>
			<div className="static-page-content">
				{reactHtmlParser(page.content)}
			</div>
		</div>
		<StaticPageFooter />
	</div>;
}
