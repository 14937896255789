import React from 'react';
import CollapsableArea from '../../lib/ui/CollapsableArea';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';

export default function CompetenceEditQuestion({ question, setQuestion, removeQuestion }) {
	return <CollapsableArea label={question.name}>
		<WithLabel label="Vraagnaam">
			<InputField value={question.name} onChange={name => setQuestion({ ...question, name })} />
		</WithLabel>

		<WithLabel label="Vraag">
			<InputField value={question.question} onChange={q => setQuestion({ ...question, question: q })} />
		</WithLabel>
	</CollapsableArea>;
}
