import React, { Fragment } from 'react';

export default function CardLoadingSpinner() {
	return <Fragment>
		<div className="card-loading-spinner-min-height-spacer" />
		<div className="card-loading-spinner">
			<svg xmlns="http://www.w3.org/2000/svg" className="spinner-img" version="1.1" width="72" height="72" viewBox="-10 -10 220 220">
				<defs>
					<linearGradient id="redyel" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="1" y2="1">
						<stop offset="0%" stopColor="#40a52b"/>
						<stop offset="100%" stopColor="#B7990F"/>
					</linearGradient>
					<linearGradient id="yelgre" gradientUnits="objectBoundingBox" x1="0" y1="0" x2="0" y2="1">
						<stop offset="0%" stopColor="#B7990F"/>
						<stop offset="33%" stopColor="#f19200" />
						<stop offset="100%" stopColor="#e8303c"/>
					</linearGradient>
					<linearGradient id="grecya" gradientUnits="objectBoundingBox" x1="1" y1="0" x2="0" y2="1">
						<stop offset="0%" stopColor="#e8303c"/>
						<stop offset="33%" stopColor="#E8303C" />
						<stop offset="100%" stopColor="#e30059"/>
					</linearGradient>
					<linearGradient id="cyablu" gradientUnits="objectBoundingBox" x1="1" y1="1" x2="0" y2="0">
						<stop offset="0%" stopColor="#e30059"/>
						<stop offset="100%" stopColor="#897EA0"/>
					</linearGradient>
					<linearGradient id="blumag" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="0" y2="0">
						<stop offset="0%" stopColor="#897EA0"/>
						<stop offset="33%" stopColor="#5cbdc3" />
						<stop offset="100%" stopColor="#49AD5D"/>
					</linearGradient>
					<linearGradient id="magred" gradientUnits="objectBoundingBox" x1="0" y1="1" x2="1" y2="0">
						<stop offset="0%" stopColor="#49AD5D"/>
						<stop offset="100%" stopColor="#40a52b"/>
					</linearGradient>
				</defs>

				<g fill="none" strokeWidth="18" transform="translate(100,100)">
					<path d="M 0,-100 A 100,100 0 0,1 86.6,-50" stroke="url(#redyel)"/>
					<path d="M 86.6,-50 A 100,100 0 0,1 86.6,50" stroke="url(#yelgre)"/>
					<path d="M 86.6,50 A 100,100 0 0,1 0,100" stroke="url(#grecya)"/>
					<path d="M 0,100 A 100,100 0 0,1 -86.6,50" stroke="url(#cyablu)"/>
					<path d="M -86.6,50 A 100,100 0 0,1 -86.6,-50" stroke="url(#blumag)"/>
					<path d="M -86.6,-50 A 100,100 0 0,1 0,-100" stroke="url(#magred)"/>
				</g>
			</svg>

		</div>
	</Fragment>;
}
