import React from 'react';
import Select from 'react-select';

export default function SelectField({ placeholder, options, onChange, value, ...props }) {
	return <Select
		{...props}
		placeholder={placeholder}
		options={options}
		onChange={(selection) => onChange(selection == null ? null : selection.value)}
		value={value == null ? null : options.find(o => o.value === value)}
		backspaceRemovesValue={true}
		isClearable
		isOptionDisabled={option => option.disabled}
		menuPlacement="auto"
		className="react-select"
		classNamePrefix="react-select" />;
}
