import React, { useEffect, useState } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import { get, post, put } from '../../../util/api';
import { withRouter } from 'react-router';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import HtmlField from '../../lib/forms/simple/HtmlField';
import WithStatusIcon from '../../lib/forms/WithStatusIcon';

function titleToPermalink(title) {
	return title.toLowerCase().replace(/[^a-z0-9 ]/g, "").replace(/\s/g, '-');
}

export default withRouter(({ match, history }) => {
	const pageId = match.params.id;

	const [ loading, setLoading ] = useState(true);
	const [ title, setTitle ] = useState('');
	const [ autogeneratedPermalink, setAutogeneratedPermalink ] = useState(true);
	const [ permalink, setPermalink ] = useState('');
	const [ content, setContent ] = useState([]);

	useEffect(() => {
		if (pageId == null) return setLoading(false);

		get('/pages/' + pageId).then(page => {
			setAutogeneratedPermalink(titleToPermalink(page.title) == page.permalink || page.permalink == null || page.permalink.length == 0);
			setPermalink(page.permalink);
			setTitle(page.title);
			setContent(page.content);
			setLoading(false);
		});
	}, [ pageId ]);

	useEffect(() => {
		if (!autogeneratedPermalink) return;
		setPermalink(titleToPermalink(title));
	}, [ autogeneratedPermalink, title ]);

	return <Card>
		<CardToolbar title={pageId == null ? 'Pagina aanmaken' : 'Pagina bewerken'} />
		{!loading && <CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				const dto = { title, content, permalink };
				if (pageId) {
					put('/pages/' + pageId, dto).finally(() => history.push('/admin/pages'));
				} else {
					post('/pages', dto).finally(() => history.push('/admin/pages'));
				}
				return false;
			}}>
				<WithLabel label="Paginatitel">
					<InputField onChange={setTitle} value={title} />
				</WithLabel>

				<WithLabel label="Permalink">
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<div style={{ paddingBottom: 'var(--u-16)' }}>
							<span>{window.location.protocol}//{window.location.host}/page/&nbsp;</span>
						</div>
						<div style={{ flex: 1, paddingTop: 'var(--u-2)' }}>
							<WithStatusIcon icon={autogeneratedPermalink ? <span className="fa fa-magic" onClick={() => setAutogeneratedPermalink(!autogeneratedPermalink)} /> : <span className="fa fa-keyboard-o" onClick={() => setAutogeneratedPermalink(!autogeneratedPermalink)} />}>
								<InputField onChange={pl => { setPermalink(pl); setAutogeneratedPermalink(false); }} value={permalink} />
							</WithStatusIcon>
						</div>
					</div>
				</WithLabel>

				<WithLabel label="Inhoud">
					<HtmlField onChange={setContent} value={content} defaultHeight={500} minHeight={300} maxHeight={1000} />
				</WithLabel>

				<ButtonArea>
					<TertiaryLinkButton label="Annuleren" to="/admin/pages" />
					<Button icon="check" label="Opslaan" type="submit" />
				</ButtonArea>
			</form>
		</CardPadding>}
		{loading && <CardLoadingSpinner />}
	</Card>;
});
