import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../context/User';
import Card from '../lib/ui/Card';
import NavigateAwayPrompt from '../lib/forms/NavigateAwayPrompt';
import CardToolbar from '../lib/ui/CardToolbar';
import CardPadding from '../lib/ui/CardPadding';
import { get, post } from '../../util/api';
import { FormattedMessage, useIntl } from 'react-intl';
import ErrorMessage from '../lib/forms/ErrorMessage';
import WithLabel from '../lib/forms/WithLabel';
import InputField from '../lib/forms/simple/InputField';
import ButtonArea from '../lib/forms/ButtonArea';
import TertiaryButton from '../lib/forms/simple/TertiaryButton';
import Button from '../lib/forms/simple/Button';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import WithLabelRedesign from '../lib/forms/WithLabelRedesign';
import SelectField from '../lib/forms/simple/SelectField';
import { cmpCompetences } from '../schooladmin/schoolclasses/SchoolClassScore';
import WithValidation from '../lib/forms/WithValidation';
import TaxonomyHtmlRenderer from '../taxonomy/TaxonomyHtmlRenderer';

export default function CreateFeedbackRequest() {
	const user = useContext(UserContext);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const [ redirect, setRedirect ] = useState(null);
	const [ competences, setCompetences ] = useState([]);
	const [ assessments, setAssessments ] = useState([]);
	const [ selectedCompetence, setSelectedCompetence ] = useState(null);
	const [ chosenCompetencesForFeedback, setChosenCompetencesForFeedback ] = useState(new Set());
	const intl = useIntl();

	const grades = [ 'A', 'B', 'C', 'D', 'E', 'F' ];
	const [ email, setEmail ] = useState('');
	const [ name, setName ] = useState('');
	const nameValid = name.length >= 2;
	const emailValid = /^(.+)@(.+)\.(.+)$/.test(email);

	useEffect(() => {
		get('/monitors/bySchool/' + user.studentInfo?.schoolId).then(monitor => {
			const orderedCompetences = monitor.competences.toSorted(cmpCompetences);
			setCompetences(orderedCompetences);
		});
	}, [ user.studentInfo?.schoolId ]);

	useEffect(() => {
		get('/assessments/student/' + user.studentInfo?.id + "/latest").then(as => setAssessments(as));
	}, [ user.studentInfo?.id ]);

	useEffect(() => {
		get('/monitors/bySchool/' + user.studentInfo?.schoolId).then(monitor => {
			const orderedCompetences = monitor.competences.toSorted(cmpCompetences);
			setCompetences(orderedCompetences);
		});
	}, [ selectedCompetence ]);

	function getCurrentLevelDescription(f) {
		const competence = competences.find(c => c.id == f);
		const assessment = assessments.find(a => a.competenceId == competence.id);
		const grade = assessment != null ? assessment.grade : null;

		// grade: A -> null; B -> 0; C -> 1; D -> 2; E -> 3; F -> 4; finished (passed F) -> 5
		if (grade == null || grade == 0 || competence.levels == null) { // nothing filled in is the same as level A
			if (competence.baseLevelDescriptionAlternative != null) {
				return competence.baseLevelDescriptionAlternative; // use alternative description if available (the default one usually contains a call to action, so alternative is preferred)
			} else {
				return competence.baseLevelDescription;
			}
		} else {
			return competence.levels[grade - 1].explanationText;
		}
	}

	function getGrade(f) {
		const competence = competences.find(c => c.id == f);
		const assessment = assessments.find(a => a.competenceId == competence.id);
		const grade = assessment != null ? assessment.grade : null;

		if (grade == null) {
			return grades[0];
		} else {
			return grades[grade];
		}

	}

	return <Card>
		{loading && <CardLoadingSpinner />}
		<NavigateAwayPrompt redirect={redirect} />
		<CardToolbar title={intl.formatMessage({ id: "createFeedbackRequestInvite" })} />
		<CardPadding>
			<span>{intl.formatMessage({ id: "feedbackRequestWarningGrades" })}</span><br /><br />
			<form onSubmit={event => {
				event.preventDefault();
				event.stopPropagation();
				setLoading(true);
				setError(null);
				const arrayOfChosenCompetences = Array.from(chosenCompetencesForFeedback);
				post('/feedbackRequest', { name, email, competenceIds: arrayOfChosenCompetences })
					.then(() => setRedirect('/feedback'))
					.catch(e => {
						setLoading(false);
						setError(<FormattedMessage id="somethingWentWrongWhenCreatingThisFeedbackRequest" />);
					});
				return false;
			}}>
				{error && <ErrorMessage error={error} />}

				<WithLabel label={intl.formatMessage({ id: "name" })}>
					<p className="small-text" ><FormattedMessage id="theOneYouAreAskingForFeedback" /></p>
					<WithValidation valid={nameValid}>
						<InputField value={name} onChange={setName} />
					</WithValidation>
				</WithLabel>

				<WithLabel label={intl.formatMessage({ id: "email" })}>
					<p className="small-text" ><FormattedMessage id="theOneYouAreAskingForFeedback" /></p>
					<WithValidation valid={emailValid}>
						<InputField value={email} onChange={setEmail} />
					</WithValidation>
				</WithLabel>

				<WithLabel label={intl.formatMessage({ id: "chooseCompetence" })}>
					<div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gap: 'var(--u-16)' }}>
						<SelectField placeholder="" options={competences
							.filter(c => !chosenCompetencesForFeedback.has(c.id))
							.map(c => ({ label: c.label, value: c.id }))} onChange={setSelectedCompetence} value={selectedCompetence} />
						<Button icon="plus" label={intl.formatMessage({ id: "add" })} onClick={() => setChosenCompetencesForFeedback(new Set([ ...chosenCompetencesForFeedback, selectedCompetence ]))} disabled={chosenCompetencesForFeedback.size > 2 || selectedCompetence == null || chosenCompetencesForFeedback.has(selectedCompetence)} />
					</div>
				</WithLabel>

				{[ ...chosenCompetencesForFeedback ].map(cc => {
					return <div style={{ background: 'var(--col-grey-100)', padding: 'var(--u-16) var(--u-24)', marginBottom: 'var(--u-16)' }}>
						<div style={{ fontWeight: 'bold' }}>{competences.find(c => c.id == cc).label} {getGrade(cc)}</div>
						<div><TaxonomyHtmlRenderer html={getCurrentLevelDescription(cc)} /></div>
						<Button style={{ background: 'var(--col-red)' }} icon="trash" onClick={() => setChosenCompetencesForFeedback(new Set([ ...chosenCompetencesForFeedback ].filter(c => c != cc)))} />
					</div>;
				})}

				<ButtonArea>
					<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={() => setRedirect('/profile')} />
					<Button icon="paper-plane" label={intl.formatMessage({ id: "sendMail" })} type="submit" disabled={chosenCompetencesForFeedback.size < 1 || !nameValid || !emailValid} />
				</ButtonArea>

			</form>
		</CardPadding>
	</Card>;
}