import React, { useEffect, useState } from 'react';
import { useMagicLink as processMagicLink } from '../../util/api';
import CardPadding from '../lib/ui/CardPadding';
import Card from '../lib/ui/Card';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import LinkButton from '../lib/forms/simple/LinkButton.js';
import ButtonArea from '../lib/forms/ButtonArea';
import headerForeground from '../../assets/competentiemonitor/header/header-foreground-paths-login.svg';

export default function createUseMagicLink(refreshUser) {
	return function UseMagicLink({ match }) {
		const [ expiredToken, setExpiredToken ] = useState(false);
		const { token } = match.params;

		useEffect(() => {
			processMagicLink(token)
				.catch(() => setExpiredToken(true))
				.finally(refreshUser);
		}, [ token ]);

		return <div className="login">
			<Card>
				<div style={{ padding: 'var(--u-24) var(--u-32) 0 var(--u-32)' }}>
					<img
						style={{ paddingTop: 'var(--u-16)', maxWidth: '100%' }}
						className="logo"
						src={headerForeground}
						alt="Logo Competentiemonitor" />
				</div>
				<CardPadding>
					{!expiredToken && <CardLoadingSpinner/>}
					{expiredToken && <div>
                        De eenmalige link is verlopen. Log alsjeblieft nogmaals in.
						<br /><br />
						<ButtonArea>
							<LinkButton label="Naar inloggen" to="/magiclink" icon="angle-right" iconRight={true} />
						</ButtonArea>
					</div>}
				</CardPadding>
			</Card>;
		</div>;
	};
}
