import React, { useContext } from 'react';
import TaxonomyTerms from '../../context/TaxonomyTerms';
import ReactHtmlParser from 'react-html-parser';
import Fragment from 'react-dom-fragment';
import Term from './Term';

function replaceTextInElement(terms, element, depth = 0) {
	// On text, just replace elements in the text and return new react components if necessary
	if (typeof element == 'string') {
		const lowerCaseElement = element.toLowerCase();
		let matches = [];
		const matchingTerms = Object.keys(terms).sort((a, b) => b.length - a.length).filter(term => lowerCaseElement.includes(term));

		// Short circuit if no matches
		if (matchingTerms.length == 0) return <Fragment>{element}</Fragment>;

		// Match the term to be replaced
		const parts = element.split(new RegExp('(\\b)(' + matchingTerms.join('|') + ')(\\b)', 'gi'));

		// Loop through middle captures only [ left, whitespace, term, whitespace, right ]
		let ptr = 0;
		for (let i = 2; i < parts.length; i += 4) {
			// JavaScript is a retarded language that treats special characters as a word boundary
			// If one of those got recognized, skip em
			// I would do this in the original matching regex but holy hell is that difficult to write
			// There is a special place in hell reserved for the cunt who decided to not make regexes deal with Unicode well
			//  ë, à, ü and Ø are not valid word boundaries.
			const specialCharRegex = /[À-ÖØ-öø-ſ]/i;
			const prevChar = element[ptr + parts[i - 2].length + parts[i - 1].length - 1] || '';
			const nextChar = element[ptr + parts[i - 2].length + parts[i - 1].length + parts[i].length] || '';
			if (specialCharRegex.test(prevChar) || specialCharRegex.test(nextChar)) {
				ptr += parts[i - 2].length + parts[i - 1].length + parts[i].length + parts[i + 1].length;
				continue;
			}
			ptr += parts[i - 2].length + parts[i - 1].length;
			matches.push({ from: ptr, match: parts[i].toLowerCase() });
			ptr += parts[i].length + parts[i + 1].length;
		}

		// Put the matches in correct order, this is not guaranteed since we did it term-by-term
		matches = matches.sort((a, b) => a.from - b.from);

		// Replace terms and insert the custom <Term /> component
		const elements = [];
		ptr = 0;
		for (let i = 0; i < matches.length; i++) {
			elements.push(element.slice(ptr, matches[i].from));
			elements.push(<Term text={matches[i].match} description={terms[matches[i].match.toLowerCase()]}/>);
			ptr = matches[i].from + matches[i].match.length;
		}
		elements.push(element.slice(ptr, element.length));

		return <Fragment>{elements}</Fragment>;
	}

	// Skip if null element
	if (element == null) return null;

	// For every actual component, loop over children and replace text in children
	const newChildren = React.Children.map(element.props.children, child => {
		if (Array.isArray(child)) console.log('Array shite', element, child);
		return replaceTextInElement(terms, child, depth + 1);
	});
	const allEqual = (React.Children.map(element, (child, idx) => child == (newChildren || [])[idx]) || []).reduce((a, b) => a && b, true);

	if (allEqual) {
		return element;
	} else {
		// return element;
		return React.cloneElement(element, element.props, newChildren);
	}
}

export default function TaxonomyHtmlRenderer({ html }) {
	const termsMap = useContext(TaxonomyTerms);
	const reactElements = ReactHtmlParser(html);
	return <div className="block-with-terms">{reactElements.map(element => replaceTextInElement(termsMap, element))}</div>;
}
