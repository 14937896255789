import React, { useEffect, useState } from 'react';
import CardPadding from '../../lib/ui/CardPadding';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import { get, post, put } from '../../../util/api';
import SchoolClassTypeField from '../../lib/forms/complex/SchoolClassTypeField';
import { useIntl } from 'react-intl';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import NavigateAwayPrompt from '../../lib/forms/NavigateAwayPrompt';

export default function SchoolClassEdit({ history, match }) {
	const schoolClassId = match.params.id;
	const [ redirect, setRedirect ] = useState(null);
	const [ loading, setLoading ] = useState(true);
	const [ name, setName ] = useState('');
	const [ scType, setScType ] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		if (schoolClassId == null) return setLoading(false);
		get('/schoolclasses/' + schoolClassId).then(sc => {
			setName(sc.name);
			setScType((sc.schoolClassType || {}).id);
			setLoading(false);
		});
	}, [ schoolClassId ]);

	return <Card>
		<NavigateAwayPrompt redirect={redirect} />
		<CardToolbar title={schoolClassId ? intl.formatMessage({ id: "editClass" }) : intl.formatMessage({ id: "createClass" })}/>
		<CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				setLoading(true);
				if (schoolClassId != null) {
					put('/schoolclasses/' + schoolClassId, { name, schoolClassType: scType }).then(() => history.push('/schoolclasses/view/' + schoolClassId));
				} else {
					post('/schoolclasses', { name, schoolClassType: scType }).then(() => history.push('/schoolclasses'));
				}
			}}>
				<WithLabel label={intl.formatMessage({ id: "className" })}>
					<InputField onChange={setName} value={name}/>
				</WithLabel>
				<WithLabel label={intl.formatMessage({ id: "classType" })}>
					<SchoolClassTypeField onChange={sc => setScType(sc == null ? null : sc.id)} value={scType} />
				</WithLabel>
				<ButtonArea>
					<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={() => setRedirect('/schoolclasses')} />
					<Button type="submit" label={intl.formatMessage({ id: "save" })} icon="check"/>
				</ButtonArea>
			</form>
		</CardPadding>
		{loading && <CardLoadingSpinner/>}
	</Card>;
}
