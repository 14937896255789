import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { post } from '../../util/api';
import { PasswordReset } from './issues/PasswordReset';
import SchoolClassSelection from './issues/SchoolClassSelection';
import EmailReset from './issues/EmailReset';
import UserDataChangeCounter from '../../context/UserDataChangeCounter';
import SignupCodeEntry from './issues/SignupCodeEntry';
import { FormattedMessage } from 'react-intl';

function getNextIssueComponent(issues) {
	if (issues == null) return null;
	if (issues.passwordResetState != 'OK') return PasswordReset;
	if (issues.forceEmailReset) return EmailReset;
	if (issues.forceNewClassSelection) return SchoolClassSelection;
	if (issues.unattachedAccount) return SignupCodeEntry;
	return null;
}

function getTotalScreens(issues) {
	let total = 0;
	if (issues.passwordResetState != 'OK') total++;
	if (issues.forceEmailReset) total++;
	if (issues.forceNewClassSelection) total++;
	if (issues.unattachedAccount) total++;
	return total;
}

function logout(history) {
	post('/whoami/logout', null, false).then(() => {
		history.push('/');
		window.location.reload(true);
	});
}

export default withRouter(function BlockingIssuesPopup({ history, issues, onFix }) {
	const { increment } = useContext(UserDataChangeCounter);
	const [ maxPages, setMaxPages ] = useState(0);

	const NextIssueComponent = getNextIssueComponent(issues);

	useEffect(() => {
		if (NextIssueComponent == null) return setMaxPages(0);
		setMaxPages(Math.max(maxPages, getTotalScreens(issues)));
	}, [ NextIssueComponent, issues ]);

	if (NextIssueComponent == null) return null;
	return <div className="app-overlay">
		<div className="app-overlay-top-bar">
			<div>
				<h2><FormattedMessage id="beforeYouContinue" /></h2>
			</div>
			<div className="right-side">
				<div className="progress">
					{maxPages - getTotalScreens(issues) + 1} / {maxPages}
				</div>
				<div className="logout">
					<div className="overlay-logout-icon-circle" onClick={logout.bind(this, history)}>
						<span className="fa fa-sign-out" />
					</div>
				</div>
			</div>
		</div>
		<div className="popup">
			<NextIssueComponent issues={issues} onFix={(...props) => {
				increment();
				onFix(...props);
			}} />
		</div>
		<div></div>
	</div>;
});
