import React, { useEffect, useMemo, useContext } from 'react';
import { IntlProvider } from 'react-intl';
import TimeAgo from 'javascript-time-ago';
import TimeAgoContext from '../context/TimeAgo';
import LanguageContext from '../context/Language';
import UserContext from '../context/User';
import nl from 'javascript-time-ago/locale/nl';
import en from 'javascript-time-ago/locale/en';
import English from '../languages/en.json';
import Dutch from '../languages/nl.json';
import usePersistentState from '../hooks/usePersistentState';

TimeAgo.addLocale(nl);
TimeAgo.addLocale(en);

const locales = {
	'Dutch': {
		locale: 'nl-NL',
		messages: Dutch,
	},
	'English': {
		locale: 'en-GB',
		messages: English,
	},
};

export default function LocaleInjector({ children }) {
	const [ language, setLanguage ] = usePersistentState('Dutch', 'language');
	const { locale, messages } = locales[language];
	const timeAgo = useMemo(() => new TimeAgo(locale), [ language ]);
	const user = useContext(UserContext);

	useEffect(() => {
		if (user == null) return;
		setLanguage(user.language);
	}, [ user ]);

	const languageObject = useMemo(() => ({ language, setLanguage }), [ language, setLanguage ]);

	useEffect(() => {
		document.documentElement.lang = locale == 'en-GB' ? 'en' : 'nl';
	}, [ locale ]);

	return <IntlProvider locale={locale} key={locale} messages={messages}>
		<LanguageContext.Provider value={languageObject}>
			<TimeAgoContext.Provider value={timeAgo}>
				{children}
			</TimeAgoContext.Provider>
		</LanguageContext.Provider>
	</IntlProvider>;
}
