import React, { Fragment, useEffect, useState } from 'react';
import { get } from '../../../../util/api';
import CompetenceInfo from '../parts/CompetenceInfo';
import Level from '../parts/Level';
import { shouldShowLevel } from '../create/AssessmentForm';
import TertiaryLinkButton from '../../../lib/forms/simple/TertiaryLinkButton';
import PageHeader from '../../../lib/ui/PageHeader';
import CompetenceInfoCompact from '../parts/CompetenceInfoCompact';
import ReactSwitch from 'react-switch';
import { FormattedMessage } from 'react-intl';

export default function AssessmentView({ match }) {
	const assessmentId = match.params.id;
	const teacherMode = true;

	const [ competence, setCompetence ] = useState(null);
	const [ assessment, setAssessment ] = useState(null);
	const [ answers, setAnswers ] = useState(null);
	const [ viewModeCompact, setViewModeCompact ] = useState(true);

	useEffect(() => {
		get('/assessments/' + assessmentId).then(a => {
			setAssessment(a);
			setAnswers(a.answers.reduce((tot, ans) => ({ ...tot, [ans.questionId]: ans.answer }), {}));
		});
	}, [ assessmentId ]);

	useEffect(() => {
		if (assessment == null) return;
		get('/competences/' + assessment.competenceId).then(setCompetence);
	}, [ assessment ]);

	if (assessment == null || competence == null) return null;
	return <Fragment>
		<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
			<span> <FormattedMessage id="compactView" /> </span> &nbsp;
			<ReactSwitch checked={viewModeCompact} onChange={setViewModeCompact} />
		</div>
		<div className="assessment-form">
			{viewModeCompact == false ? <CompetenceInfo competence={competence} teacherMode={teacherMode} /> : <CompetenceInfoCompact competence={competence} />}
			{competence.levels
				.filter((l, idx) => shouldShowLevel(competence, competence.levels[idx - 1], answers))
				.map((l, idx) => <Level
					key={l.id}
					readOnly={teacherMode}
					finished={shouldShowLevel(competence, competence.levels[idx], answers)}
					assessment={assessment}
					competence={competence}
					level={l}
					answers={answers}
					viewModeCompact={viewModeCompact}
					onAnswer={(qId, a) => setAnswers({ ...answers, [qId]: a })} />)}
		</div>
	</Fragment>;
}
