import React, { useContext, useEffect, useState } from 'react';
import headerForeground from '../../assets/competentiemonitor/header/header-foreground-paths.svg';
import UserInfo from './UserInfo';
import { Route } from 'react-router';
import { NavLink } from 'react-router-dom';
import UserContext from '../../context/User';
import reactHtmlParser from 'react-html-parser';
import { get } from '../../util/api';
import BlockingIssuesPopup from './BlockingIssuesPopup';
import logo from '../../assets/technasium/logo-technasium.svg';
import { FormattedMessage, useIntl } from 'react-intl';

function fetchBlockingIssues(setBlockingIssues) {
	get('/whoami/blockingissues').then(setBlockingIssues);
}

export default function AppFrame({ children }) {
	const user = useContext(UserContext);
	const [ schoolFooter, setSchoolFooter ] = useState(null);
	const [ blockingIssues, setBlockingIssues ] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		if (user == null) return;
		const schoolId = (user.studentInfo || {}).schoolId || (user.teacherInfo || {}).schoolId || (user.schoolAdminInfo || {}).schoolId;
		if (schoolId == null) return;

		get('/schools/' + schoolId + '/footer').then(setSchoolFooter);
	}, []);

	useEffect(() => {
		fetchBlockingIssues(setBlockingIssues);
	}, []);

	return <div className="app">
		<div className="header">
			<div className="header-content">
				<div className="header-left">
					{/* <img*/}
					{/*	className="logo"*/}
					{/*	src={logoTechnasium}*/}
					{/*	alt="Logo Technasium" />*/}
					<img
						style={{ paddingTop: 'var(--u-16)' }}
						className="logo"
						src={headerForeground}
						alt="Logo Competentiemonitor" />
					{/* <HeaderLogo />*/}
				</div>
				<div className="header-right">
					<UserInfo user={user} />
				</div>
			</div>
		</div>
		<div className="navigation-bar">
			<div className="navigation-bar-content">
				{user.studentInfo && <NavLink to="/assessments"><FormattedMessage id="competences" /></NavLink>}
				{user.studentInfo && <NavLink to="/growth"><FormattedMessage id="progress" /></NavLink>}
				{user.studentInfo && <NavLink to="/feedback"><FormattedMessage id="feedback" /></NavLink>}
				{user.teacherInfo && <NavLink to="/students"><FormattedMessage id="students" /></NavLink>}
				{user.schoolAdminInfo && <NavLink to="/teachers"><FormattedMessage id="supervisors" /></NavLink>}
				{(user.schoolAdminInfo || user.teacherInfo) && <NavLink to="/schoolclasses"><FormattedMessage id="classes" /></NavLink>}
				{user.schoolAdminInfo && <NavLink to="/myschool"><FormattedMessage id="school" /></NavLink>}
				{(user.studentInfo || user.teacherInfo || user.schoolAdminInfo) && <NavLink to="/faq"><FormattedMessage id="faq" /></NavLink>}
				{user.globalAdmin && <NavLink to="/admin"><FormattedMessage id="administrator" /></NavLink>}
			</div>
		</div>
		<Route path="/admin" component={() => <div className="secondary-nav-bar">
			<div className="secondary-nav-bar-content">
				<NavLink to="/admin/schools"><FormattedMessage id="schools" /></NavLink>
				<NavLink to="/admin/schoolclasstypes"><FormattedMessage id="classTypes" /></NavLink>
				<NavLink to="/admin/users"><FormattedMessage id="users" /></NavLink>
				<NavLink to="/admin/monitors"><FormattedMessage id="monitors" /></NavLink>
				<NavLink to="/admin/clusters"><FormattedMessage id="clusters" /></NavLink>
				<NavLink to="/admin/competences"><FormattedMessage id="competences" /></NavLink>
				<NavLink to="/admin/taxonomy/terms"><FormattedMessage id="terms" /></NavLink>
				<NavLink to="/admin/pages"><FormattedMessage id="pages" /></NavLink>
				<NavLink to="/admin/faq"><FormattedMessage id="faq" /></NavLink>
			</div>
		</div>}>
		</Route>
		<Route path="/students/:sid" component={StudentSubMenu} />

		<div className="app-content">
			{children}
		</div>
		<footer>
			<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
				<div>
					{schoolFooter != null && reactHtmlParser(schoolFooter.textField1)}
				</div>
				<div>
					{schoolFooter != null && reactHtmlParser(schoolFooter.textField2)}
				</div>
				<div>
					<a href="/page/privacy-statement"><FormattedMessage id="privacyStatement" /></a><br />
					<a href="/page/terms-and-conditions"><FormattedMessage id="termsAndConditions" /></a><br />
					<a href="https://www.technasium.nl/">Technasium</a><br />
					<img src={logo} className="footer-logo" alt="Logo Stichting Technasium" />
				</div>
			</div>
		</footer>
		<BlockingIssuesPopup
			issues={blockingIssues}
			onFix={() => {
				fetchBlockingIssues(setBlockingIssues);

			}} />
	</div>;
}

function StudentSubMenu({ match }) {
	const [ student, setStudent ] = useState(null);
	const [ hasAccess, setHasAccess ] = useState(false);

	useEffect(() => {
		get('/studentroles/' + match.params.sid).then(srr => {
			setStudent(srr.user);
			setHasAccess(srr.hasAccess);
		});
	}, [ match.params.sid ]);

	return <div className="secondary-nav-bar">
		<div className="secondary-nav-bar-content">
			<h2 className="header-a" style={{ fontSize: 'var(--fs-small)', paddingLeft: 'var(--u-24)', paddingRight: 'var(--u-24)' }}>{(student || {}).fullName}</h2>
			<NavLink to={'/students/' + match.params.sid + '/info'}><FormattedMessage id="overview" /></NavLink>
			{hasAccess && <NavLink to={'/students/' + match.params.sid + '/assessments'}><FormattedMessage id="competences" /></NavLink>}
			{hasAccess && <NavLink to={'/students/' + match.params.sid + '/growth'}><FormattedMessage id="progress" /></NavLink>}
		</div>
	</div>;
}
