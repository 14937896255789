import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../context/User';
import { get, put } from '../../util/api';
import Button from '../lib/forms/simple/Button';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import TertiaryButton from '../lib/forms/simple/TertiaryButton';
import Modal from '../lib/ui/Modal';
import { Redirect } from 'react-router';
import ModalToolbar from '../lib/ui/ModalToolbar';
import ModalContent from '../lib/ui/ModalContent';
import ButtonArea from '../lib/forms/ButtonArea';
import Card from '../lib/ui/Card';
import CardPadding from '../lib/ui/CardPadding';

export default function FeedbackOverview({ studentId }) {
	const user = useContext(UserContext);

	const [ remindPopup, setRemindPopup ] = useState(null);
	const [ redirect, setRedirect ] = useState(null);

	const [ pendingFeedbackRequests, setPendingFeedbackRequests ] = useState([]);
	const [ submittedFeedbackRequests, setSubmittedFeedbackRequests ] = useState([]);
	const [ expiredFeedbackRequests, setExpiredFeedbackRequests ] = useState([]);

	const intl = useIntl();

	function fetchFeedbackData() {
		const sid = studentId || user.studentInfo.id;
		get('/feedbackRequest/student/' + sid + '/all')
			.then(feedbackRequests => {
				if (feedbackRequests != null) {
					const newSubmittedFeedbackRequests = [];
					const newPendingFeedbackRequests = [];
					const newExpiredFeedbackRequests = [];

					// if there is a submitted date then add it to the received feedbacks, otherwise it's still pending
					feedbackRequests.forEach(fr => {
						if (fr.dateSubmitted != null) {
							newSubmittedFeedbackRequests.push(fr);
						} else if (Date.now() < Date.parse(fr.dateExpiration)) {
							newPendingFeedbackRequests.push(fr);
						} else {
							newExpiredFeedbackRequests.push(fr);
						}
					});
					setSubmittedFeedbackRequests(newSubmittedFeedbackRequests.toSorted((a, b) => Date.parse(b.dateSubmitted) - Date.parse(a.dateSubmitted)));
					setPendingFeedbackRequests(newPendingFeedbackRequests.toSorted((a, b) => Date.parse(b.dateSent) - Date.parse(a.dateSent)));
					setExpiredFeedbackRequests(newExpiredFeedbackRequests.toSorted((a, b) => Date.parse(b.dateExpiration) - Date.parse(a.dateExpiration)));
				}
			});
	}

	useEffect(() => {
		fetchFeedbackData();
	}, [ studentId, user.studentInfo.id ]);

	return <Card>
		<CardPadding>
			{redirect && <Redirect to={redirect} />}
			<div style= {{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
				<Button onClick={() => setRedirect("/feedback/new")} label={<FormattedMessage id="requestFeedback" />}/>
			</div>
			<br />

			{remindPopup != null && <Modal>
				<ModalToolbar title={intl.formatMessage({ id: "sendReminder" })} />
				<ModalContent style={{ overflowY: 'visible' }}>
					{intl.formatMessage({ id: "confirmSendingReminder" })}
					<ButtonArea>
						<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={() => setRemindPopup(null)} />
						<Button icon="envelope" label={intl.formatMessage({ id: "sendMail" })} onClick={() => put("/feedbackRequest/remind/" + remindPopup).finally(() => { setRemindPopup(null); fetchFeedbackData(); })} />
					</ButtonArea>
				</ModalContent>
			</Modal>}

			{pendingFeedbackRequests != null && pendingFeedbackRequests.length > 0 ?
				<>
					<h2 className="header-a" style={{ paddingBottom: 'var(--u-8)' }}>{intl.formatMessage({ id: "listOfFeedbackRequestsSentSoFar" })}</h2>
					<table className="table">
						<thead>
							<tr>
								<th><FormattedMessage id="name" /></th>
								<th><FormattedMessage id="email" /></th>
								<th><FormattedMessage id="dateSent" /></th>
								<th><FormattedMessage id="dateReminded" /></th>
								<th><FormattedMessage id="dateExpiry" /></th>
								<th/>
							</tr>
						</thead>
						<tbody>
							{pendingFeedbackRequests.map(fr => <tr key={fr.id}>
								<td>{fr.name}</td>
								<td>{fr.email}</td>
								<td><FormattedDate value={fr.dateSent} /></td>
								<td>{fr.dateReminded == null ? '-' : <FormattedDate value={fr.dateReminded} />}</td>
								<td><FormattedDate value={fr.dateExpiration} /></td>
								<td style={{ padding: 'var(--u-4) var(--u-8)', textAlign: 'right' }}>
									<Button onClick={() => setRemindPopup(fr.id)} icon="envelope" label={<FormattedMessage id="remind" />} disabled={fr.dateExpiration <= Date.now() || fr.dateReminded != null} />
								</td>
							</tr>)}
						</tbody>
					</table>
				</>
				:
				<div>{intl.formatMessage({ id: "noFeedbackRequestsSent" })}</div>
			}

			<br />
			<hr />
			<br />

			{submittedFeedbackRequests != null && submittedFeedbackRequests.length > 0 ?
				<>
					<span>{intl.formatMessage({ id: "listOfFeedbackReceived" })}</span>
					<table className="table">
						<thead>
							<tr>
								<th><FormattedMessage id="name" /></th>
								<th><FormattedMessage id="email" /></th>
								<th><FormattedMessage id="dateReceived" /></th>
								<th/>
							</tr>
						</thead>
						<tbody>
							{submittedFeedbackRequests.map(fr => <tr key={fr.id}>
								<td>{fr.name}</td>
								<td>{fr.email}</td>
								<td><FormattedDate value={fr.dateSubmitted} /></td>
								<td style={{ padding: 'var(--u-4) var(--u-8)', textAlign: 'right' }}>
									<Button icon="angle-right" label={<FormattedMessage id="seeAnswers" />} onClick={() => setRedirect("/feedback/view/" + fr.id)} />
								</td>
							</tr>)}
						</tbody>
					</table>
				</>
				:
				<div>{intl.formatMessage({ id: "noFeedbackReceived" })}</div>
			}

			<br />
			<hr />
			<br />

			{expiredFeedbackRequests != null && expiredFeedbackRequests.length > 0 ?
				<>
					<span>{intl.formatMessage({ id: "listOfFeedbackRequestsExpired" })}</span>
					<table className="table">
						<thead>
							<tr>
								<th><FormattedMessage id="name" /></th>
								<th><FormattedMessage id="email" /></th>
								<th><FormattedMessage id="dateExpired" /></th>
							</tr>
						</thead>
						<tbody>
							{submittedFeedbackRequests.map(fr => <tr key={fr.id}>
								<td>{fr.name}</td>
								<td>{fr.email}</td>
								<td><FormattedDate value={fr.dateExpiration} /></td>
							</tr>)}
						</tbody>
					</table>
				</>
				:
				<div>{intl.formatMessage({ id: "noExpiredFeedbackRequests" })}</div>
			}
		</CardPadding>
	</Card>;
}

