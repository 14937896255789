import React, { useState, useEffect } from 'react';
import { get } from '../../util/api';
import Card from '../lib/ui/Card';
import CardPadding from '../lib/ui/CardPadding';
import FaqElement from './FaqElement';

export default function Faq() {

	const [ faqCategories, setFaqCategories ] = useState([]);
	const [ faqItemsQuestion, setFaqItemsQuestion ] = useState([]);
	const [ faqItemsAnswer, setFaqItemsAnswer ] = useState([]);

	useEffect(() => {
		get('/faqCategory').then(setFaqCategories);
	}, []);
	useEffect(() => {
		get('/faqItem').then(i => {
			setFaqItemsQuestion(i.reduce((tot, q) => ({ ...tot, [q.id]: q.question }), {}));
			setFaqItemsAnswer(i.reduce((tot, q) => ({ ...tot, [q.id]: q.answer }), {}));
		});
	}, []);

	return <Card>
		<CardPadding>
			{faqCategories.sort((a, b) => { if (a.sorting == null) return 1; else if (b.sorting == null) return -1; else return a.sorting - b.sorting; })
				.map(c => <div>
					<h2 className="header-b">{c.name}</h2>
					<div>{c.faqItemIdList.sort((a, b) => { if (a.sorting == null) return 1; else if (b.sorting == null) return -1; else return a.sorting - b.sorting; })
						.map(id => (faqItemsQuestion[id] ? <FaqElement
							question={faqItemsQuestion[id]}
							answer={faqItemsAnswer[id]}/> : null))}
					</div>
					<br />
				</div>
				)
			}
		</CardPadding>
	</Card>;
}