import React from 'react';
import { Link } from 'react-router-dom';

export default function TertiaryLinkButton({ to, style, icon, iconRight, onClick, label }) {
	return <Link to={to} style={style} className="btn btn-tertiary" onClick={onClick || (() => {})}>
		{icon && !iconRight && <span className={'fa fa-' + icon} />}
		{label && <span>{label}</span>}
		{icon && iconRight && <span className={'fa fa-' + icon} />}
	</Link>;
}
