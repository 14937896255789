import { useState } from 'react';

export default function useSessionState(defaultValue, key) {
	const storageKey = 'cm.sessionHook.' + key;
	const [ value, setter ] = useState(sessionStorage.getItem(storageKey) != null ? JSON.parse(sessionStorage.getItem(storageKey)) : defaultValue);

	return [
		value,
		newValue => {
			sessionStorage.setItem(storageKey, JSON.stringify(newValue));
			setter(newValue);
		},
	];
}
