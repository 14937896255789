import React, { useState, useEffect } from 'react';
import { get } from '../../../util/api';
import AssessmentOverview from './AssessmentOverview';

export default function MyAssessmentOverview() {
	const [ me, setMe ] = useState(null);

	useEffect(() => {
		get('/whoami').then(setMe);
	}, []);

	if (me == null || me.studentInfo == null) return null; // TODO: replace by error
	return <AssessmentOverview studentId={me.studentInfo.id} />;
}
