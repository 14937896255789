import React, { useCallback, useEffect, useState } from 'react';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import { useIntl } from 'react-intl';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { put } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import { makeMap, move, sortBySortingProp } from './FaqOverview';

const getItemStyle = (isDragging, draggableStyle) => ({
	color: 'var(--col-primary-800)',
	userSelect: "none",
	margin: `0 0 var(--u-2) 0`,
	borderRadius: '4px',
	top: '0',
	left: '0',
	overflow: 'hidden',
	boxShadow: '0 0 1px inset rgba(0, 0, 0, 0.3)',

	// styles we need to apply on draggables
	...draggableStyle,
});

const getListStyle = isDraggingOver => ({
	width: '100%',
});
export default function FaqCategory({ category, items }) {
	const faqItems = makeMap(items ?? []);

	const intl = useIntl();

	const [ order, setOrder ] = useState(items != null ? sortBySortingProp(faqItems) : []);
	const [ loading, setLoading ] = useState(false);

	useEffect(() => {
		if(category && items && items.length > 0) {
			setOrder(sortBySortingProp(faqItems));
		}
	}, [ category, items ]);

	const onDragEnd = useCallback(result => {
		if (!result.destination) return;
		const newItemsOrder = move(order, result.source.index, result.destination.index);
		setOrder(newItemsOrder);
		const updatedCategory = {
			id: typeof category.id === 'string' ? parseInt(category.id, 10) : category.id,
			name: category.name,
			faqItemIdList: newItemsOrder,
			sorting: category.sorting,
		};
		setLoading(true);
		put('/faqItem/swap', updatedCategory).then(() => setLoading(false))
			.catch((err) => { err.text(); setLoading(false); });
	}, [ order, setOrder ]);

	return <Card>
		{category != null && <CardToolbar title={category.name}>
			<TertiaryLinkButton icon="pencil" label={ intl.formatMessage({ id: "editCategory" })} to={'/admin/faq/faqCategory/edit/' + category.id} />
		</CardToolbar>}
		<table className="table">
			<colgroup>
				<col width="170" />
				<col width="*" />
				<col width="150" />
			</colgroup>
			<thead>
				<tr>
					<th>Doelgroep</th>
					<th>Vraag</th>
					<th />
				</tr>
			</thead>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable">
					{(provided, snapshot) => (
						<tbody
							{...provided.droppableProps}
							ref={provided.innerRef}
							style={getListStyle(snapshot.isDraggingOver)}
						>
							{order.map((id, index) => (
								<Draggable key={id} draggableId={id.toString()} index={index}>
									{(provided, snapshot) => {
										if (snapshot.isDragging) {
											provided.draggableProps.style.backgroundColor = 'var(--col-primary-50)';
											provided.draggableProps.style.display = 'table';
										}
										return <tr
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getItemStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											)}>
											<td style={{ width: '170px' }}>
												<div style= {{ float: 'left', alignSelf: 'center' }}><span className="fa fa-sort" style={{ paddingRight: '6px', color: 'var(--col-grey-500)' }} /></div>
												{faqItems[id].targetGroup == 'STUDENT' && <div style= {{ paddingRight: '6px' }}>Leerling</div>}
												{faqItems[id].targetGroup == 'TEACHER' && <div style= {{ left: '0' }}>Begeleider</div>}
												{faqItems[id].targetGroup == 'SCHOOL_ADMIN' && <div style= {{ display: 'flex', paddingRight: '6px' }}>Schoolbeheerder</div>}
											</td>
											<td>
												{faqItems[id].question}
											</td>
											<td style={{ width: '150px' }}>
												<TertiaryLinkButton
													to={'/admin/faq/edit/' + faqItems[id].id}
													iconRight={true}
													icon="angle-right"
													label="Bewerken" />
											</td>
										</tr>;
									}}
								</Draggable>
							))}
							{provided.placeholder}
						</tbody>
					)}
				</Droppable>
			</DragDropContext>

		</table>
		{category != null && <div style={{ padding: 'var(--u-16)' }}>
			<TertiaryLinkButton icon="plus" label={intl.formatMessage({ id: "addFAQItem" })} to={'/admin/faq/create/' + category.id} />
		</div>}
		{loading && <CardLoadingSpinner />}
	</Card>;
}
