import React from 'react';
import FaqCollapsableArea from './FaqCollapsableArea';
import TaxonomyHtmlRenderer from '../taxonomy/TaxonomyHtmlRenderer';

export default function FaqElement({ question, answer }) {
	return <div>
		<FaqCollapsableArea label={question}>
			<div className="faq-collapsable-area-text-closed"><TaxonomyHtmlRenderer html={answer} /></div>
		</FaqCollapsableArea>

	</div>;
}