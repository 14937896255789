import React, { useEffect, useState } from 'react';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import CardPadding from '../../lib/ui/CardPadding';
import { get, post, put, del } from '../../../util/api';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import HtmlField from '../../lib/forms/simple/HtmlField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';

export default function TermEdit({ history, match }) {
	const termId = match.params.id;
	const [ loading, setLoading ] = useState(true);
	const [ term, setTerm ] = useState('');
	const [ description, setDescription ] = useState('');

	useEffect(() => {
		if (termId == null) return setLoading(false);
		get('/taxonomy/terms/' + termId).then(t => {
			setTerm(t.term);
			setDescription(t.description);
			setLoading(false);
		});
	}, [ termId ]);

	return <Card>
		<CardToolbar title={termId == null ? 'Term aanmaken' : 'Term bewerken'}>
			{termId != null && <TertiaryButton icon="trash" label="Verwijderen" onClick={() => del('/taxonomy/terms/' + termId).then(() => history.push('/admin/taxonomy/terms'))} />}
		</CardToolbar>
		{!loading && <CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				setLoading(true);
				if (termId == null) {
					post('/taxonomy/terms', { term, description }).then(() => history.push('/admin/taxonomy/terms'));
				} else {
					put('/taxonomy/terms/' + termId, { term, description }).then(() => history.push('/admin/taxonomy/terms'));
				}
				return false;
			}}>
				<WithLabel label="Term">
					<InputField onChange={setTerm} value={term} />
				</WithLabel>
				<WithLabel label="Beschrijving">
					<span className="text-small">Let er op dat wellicht niet alle styling daadwerkelijk getoond wordt</span>
					<HtmlField onChange={setDescription} value={description} />
				</WithLabel>
				<ButtonArea>
					<TertiaryLinkButton label="Annuleren" to="/admin/taxonomy/terms" />
					<Button type="submit" label="Opslaan" icon="check" />
				</ButtonArea>
			</form>
		</CardPadding>}
		{loading && <CardLoadingSpinner />}
	</Card>;
}
