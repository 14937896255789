import React, { useCallback, useContext, useEffect, useState } from 'react';
import UserContext from '../../../context/User';
import AssessmentBar from './AssessmentBar';
import CardPadding from '../../lib/ui/CardPadding';
import TabbedCard from '../../lib/ui/TabbedCard';
import { get, post, del } from '../../../util/api';
import usePersistentState from '../../../hooks/usePersistentState';
import { FormattedMessage } from 'react-intl';

// function gradeToNum(grade) {
// 	return parseInt(grade, 10);
// }

function createCompareAssessmentFn(latestAssessmentsByCompetence) {
	return function cmpAssessments(a, b) {
		const gradeA = (latestAssessmentsByCompetence[a.id] || { grade: -1 }).grade;
		const gradeB = (latestAssessmentsByCompetence[b.id] || { grade: -1 }).grade;
		if (gradeA > gradeB) {
			return -1;
		} else if (gradeA < gradeB) {
			return 1;
		} else {
			return a.label.localeCompare(b.label);
		}
	};
}

function fetchStars(studentId, setStarMap) {
	get('/stars/student/' + studentId)
		.then(stars => {
			setStarMap(stars.reduce((tot, star) => ({ ...tot, [star.competenceId]: star.id }), {}));
		});
}

function fetchFocuses(studentId, setFocusMap) {
	get('/focus/student/' + studentId)
		.then(focus => {
			setFocusMap(focus.reduce((tot, focus) => ({ ...tot, [focus.competenceId]: focus.id }), {}));
		});
}

export default function AssessmentOverview({ studentId }) {
	const me = useContext(UserContext);
	const isTeacher = me.studentInfo == null || me.studentInfo.id != studentId;
	const [ monitor, setMonitor ] = useState(null);
	const [ cluster, setCluster ] = usePersistentState('all', 'assessmentOverviewClusterTab');
	const [ latestAssessmentsByCompetence, setLatestAssessmentsByCompetence ] = useState({});
	const [ starMap, setStarMap ] = useState({});
	const [ focusMap, setFocusMap ] = useState({});

	useEffect(() => {
		get('/monitors/byStudent/' + studentId).then(setMonitor);
		get('/assessments/student/' + studentId + '/latest')
			.then(assessments => assessments.reduce((tot, elem) => ({ ...tot, [elem.competenceId]: elem }), {}))
			.then(setLatestAssessmentsByCompetence);
		fetchStars(studentId, setStarMap);
		fetchFocuses(studentId, setFocusMap);
	}, [ studentId ]);

	const onStar = useCallback((competenceId) => {
		post('/stars/' + competenceId).finally(() => fetchStars(studentId, setStarMap));
	}, [ setStarMap, studentId ]);

	const onUnstar = useCallback((starId) => {
		del('/stars/' + starId).finally(() => fetchStars(studentId, setStarMap));
	}, [ setStarMap, studentId ]);

	const onFocus = useCallback((studentId, competenceId) => {
		post('/focus/' + studentId + '/' + competenceId).finally(() => fetchFocuses(studentId, setFocusMap));
	}, [ setFocusMap, studentId ]);

	const onUnfocus = useCallback((studentId, focusId) => {
		del('/focus/' + studentId + '/' + focusId).finally(() => fetchFocuses(studentId, setFocusMap));
	}, [ setFocusMap, studentId ]);

	if (monitor == null) return null;

	// Calculate data for displaying
	const competencesToDisplay = monitor.competences.filter(competence => cluster == 'all' || (monitor.clusters.find(c => c.id == cluster) == null) || (monitor.clusters.find(c => c.id == cluster).competences || []).map(c => c.id).includes(competence.id));

	// const min = competencesToDisplay.map(c => (latestAssessmentsByCompetence[c.id] || { grade: 0 }).grade).filter(x => x != null).reduce((min, cur) => Math.min(min, cur), 1000);
	const min = 0;
	const max = 5;

	return <div className="assessment-overview">
		<TabbedCard
			tabs={[ { label: <FormattedMessage id="all" />, value: 'alls' }, ...monitor.clusters.map(c => ({ value: c.id, label: c.label })) ]}
			selected={cluster}
			onSelect={setCluster}>
			<CardPadding>
				{competencesToDisplay
					.sort(createCompareAssessmentFn(latestAssessmentsByCompetence))
					.map(c => <AssessmentBar
						studentId={studentId}
						isTeacher={isTeacher}
						key={c.id}
						competence={c}
						assessment={latestAssessmentsByCompetence[c.id]}
						min={min}
						max={max}
						star={starMap[c.id]}
						onStar={onStar}
						onUnstar={onUnstar}
						focus={focusMap[c.id]}
						onFocus={onFocus}
						onUnfocus={onUnfocus}/>)}
			</CardPadding>
		</TabbedCard>
	</div>;
}
