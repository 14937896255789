import React, { Fragment, useEffect, useState } from 'react';
import CardToolbar from '../../lib/ui/CardToolbar';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import Card from '../../lib/ui/Card';
import TablePadding from '../../lib/table/TablePadding';
import InputField from '../../lib/forms/simple/InputField';
import reactHtmlParser from 'react-html-parser';
import { get } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';

function cmpTerms(a, b) {
	if (a.term.toLowerCase() < b.term.toLowerCase()) {
		return -1;
	} else if (a.term.toLowerCase() == b.term.toLowerCase()) {
		return 0;
	} else {
		return 1;
	}
}

export default function TermsOverview() {
	const [ loading, setLoading ] = useState(true);
	const [ query, setQuery ] = useState('');
	const [ terms, setTerms ] = useState([]);

	useEffect(() => {
		get('/taxonomy/terms').then(terms => setTerms(terms.sort(cmpTerms))).finally(() => setLoading(false));
	}, []);

	const results = terms.filter(t => t.term.toLowerCase().includes(query.toLowerCase()));

	return <Fragment>
		<Card>
			<CardToolbar title="Termen">
				<TertiaryLinkButton label="Toevoegen" to="/admin/taxonomy/terms/create"/>
			</CardToolbar>
			<TablePadding>
				<InputField onChange={setQuery} value={query} type="search" placeholder="Zoeken..."/>
			</TablePadding>
			{loading && <CardLoadingSpinner />}
		</Card>
		<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gridGap: 'var(--u-16)' }}>
			{results.map(t => <Card key={t.id} style={{ margin: 0, height: 'var(--u-192)', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
				<CardToolbar title={t.term} />
				<div className="taxonomy-description" style={{ flex: 1 }}>
					{reactHtmlParser(t.description)}
				</div>
				<div className="taxonomy-center-button" style={{ display: 'flex', justifyContent: 'center', height: 'var(--u-48)', alignItems: 'center' }}>
					<TertiaryLinkButton to={'/admin/taxonomy/terms/' + t.id} label="Bewerken" iconRight={true} icon="angle-right" />
				</div>
			</Card>)}
		</div>
	</Fragment>;
}
