import React, { useEffect, useState } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import { get, post, put } from '../../../util/api';
import { withRouter } from 'react-router';
import ClusterField from '../../lib/forms/complex/ClusterField';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import MaxWidth from '../../lib/ui/MaxWidth';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import WithValidation from '../../lib/forms/WithValidation';
import SelectField from '../../lib/forms/simple/SelectField';

export default withRouter(({ match, history }) => {
	const monitorId = match.params.id;

	const [ loading, setLoading ] = useState(true);
	const [ name, setName ] = useState('');
	const [ version, setVersion ] = useState('');
	const [ language, setLanguage ] = useState('Dutch');
	const [ clusters, setClusters ] = useState([]);
	const [ selectedCluster, setSelectedCluster ] = useState(null);

	useEffect(() => {
		if (monitorId == null) return setLoading(false);

		get('/monitors/' + monitorId).then(monitor => {
			setName(monitor.name);
			setVersion(monitor.version);
			setLanguage(monitor.language);
			setClusters(monitor.clusters);
			setLoading(false);
		});
	}, [ monitorId ]);


	return <Card>
		<CardToolbar title={monitorId == null ? 'Monitor aanmaken' : 'Monitor bewerken'} />
		{!loading && <CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				setLoading(true);
				if (monitorId) {
					put('/monitors/' + monitorId, { name, version, language, clusters: clusters.map(c => c.id) }).finally(() => history.push('/admin/monitors'));
				} else {
					post('/monitors', { name, version, language, clusters: clusters.map(c => c.id) }).finally(() => history.push('/admin/monitors'));
				}
				return false;
			}}>
				<WithLabel label="Monitornaam">
					<WithValidation valid={name.length > 0} icon={false}>
						<InputField onChange={setName} value={name} />
					</WithValidation>
				</WithLabel>

				<WithLabel label="Versie">
					<InputField onChange={setVersion} value={version} />
				</WithLabel>

				<WithLabel label="Taal">
					<SelectField
						options={[
							{ value: 'Dutch', label: 'Nederlands' },
							{ value: 'English', label: 'Engels' },
						]}
						onChange={setLanguage}
						value={language} />
				</WithLabel>

				<WithLabel label="Clusters" dontUseLabelElement={true}>
					<MaxWidth width="600px">
						<table className="table">
							<colgroup>
								<col width="*" />
								<col width="1" />
							</colgroup>
							<thead>
								<tr>
									<th>Cluster</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{clusters.map(c => <tr key={c.id}>
									<td>{c.name}</td>
									<td>
										<TertiaryButton onClick={() => setClusters(clusters.filter(cl => cl.id != c.id))} icon="chain-broken" label="Ontkoppelen" />
									</td>
								</tr>)}
							</tbody>
						</table>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr auto', paddingTop: 'var(--u-24)' }}>
							<div style={{ paddingRight: 'var(--u-16)' }}>
								<ClusterField
									placeholder="Cluster zoeken..."
									excluding={clusters.map(c => c.id)}
									onChange={setSelectedCluster}
									value={selectedCluster && selectedCluster.id} />
							</div>
							<Button
								disabled={selectedCluster == null}
								onClick={() => {
									setClusters([ ...clusters, selectedCluster ]);
									setSelectedCluster(null);
								}}
								icon="link"
								label="Koppelen" />
						</div>
					</MaxWidth>
				</WithLabel>

				<ButtonArea>
					<TertiaryLinkButton label="Annuleren" to="/admin/monitors" />
					<Button disabled={name.length == 0} icon="check" label="Opslaan" type="submit" />
				</ButtonArea>
			</form>
		</CardPadding>}
		{loading && <CardLoadingSpinner />}
	</Card>;
});
