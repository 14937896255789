import React, { useState, useEffect } from 'react';
import { get, post, put } from '../../util/api';
import TertiaryButton from '../lib/forms/simple/TertiaryButton';
import PotentialTeachersField from '../lib/forms/complex/PotentialTeachersField';
import Button from '../lib/forms/simple/Button';
import { FormattedMessage, useIntl } from 'react-intl';

function sortRelationsByTeacherName(relations) {
	return relations.sort((a, b) => {
		if (a.teacherName < b.teacherName) {
			return -1;
		} else if (a.teacherName == b.teacherName) {
			return 0;
		} else {
			return 1;
		}
	});
}

function loadRelations(setLoading, setRelations) {
	setLoading(true);
	get('/me/student/studentTeacherRelations')
		.then(relations => setRelations(sortRelationsByTeacherName(relations)))
		.catch(e => e.text())
		.finally(() => setLoading(false));
}

export default function TeachersWithRelationToMe() {
	const [ loading, setLoading ] = useState(true);
	const [ relations, setRelations ] = useState([]);
	const [ teacher, setTeacher ] = useState(null);
	const intl = useIntl();

	useEffect(() => {
		loadRelations(setLoading, setRelations);
	}, []);

	if (loading) return null;
	return <div>
		<table className="table rsp-no-mobile">
			<colgroup>
				<col width="100%" />
				<col width="100px" />
				<col width="1px" />
			</colgroup>
			<thead>
				<tr>
					<th><FormattedMessage id="supervisor" /></th>
					<th><FormattedMessage id="access" /></th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{relations.map(r => <tr key={r.id}>
					<td style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: 0 }}>{r.teacherName}</td>
					<td style={{ minWidth: 100 }}>
						{r.hasAccess ? <span className="fa fa-check-circle green" /> : <span className="fa fa-times-circle red" />}&nbsp;&nbsp;
						{r.hasAccess ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />}
					</td>
					<td>
						{r.hasAccess && <TertiaryButton icon="times" label={intl.formatMessage({ id: "revokeAccess" })} onClick={() => {
							put('/me/student/studentTeacherRelations/' + r.id + '?access=false').then(() => loadRelations(setLoading, setRelations));
						}} />}
						{!r.hasAccess && <TertiaryButton icon="check" label={intl.formatMessage({ id: "giveAccess" })} onClick={() => {
							put('/me/student/studentTeacherRelations/' + r.id + '?access=true').then(() => loadRelations(setLoading, setRelations));
						}} />}
					</td>
				</tr>)}
			</tbody>
		</table>
		<div className="rsp-mobile-only">
			{relations.map(r => <div key={r.id} style={{ borderBottom: '1px solid #ccc', padding: 'var(--u-16) 0' }}>
				<span>{r.hasAccess ? <span className="fa fa-check-circle green" /> : <span className="fa fa-times-circle red" />} &nbsp;{r.teacherName}</span><br />
				<span></span>
				{r.hasAccess && <TertiaryButton icon="times" label={intl.formatMessage({ id: "revokeAccess" })} onClick={() => {
					put('/me/student/studentTeacherRelations/' + r.id + '?access=false').then(() => loadRelations(setLoading, setRelations));
				}} />}
				{!r.hasAccess && <TertiaryButton icon="check" label={intl.formatMessage({ id: "giveAccess" })} onClick={() => {
					put('/me/student/studentTeacherRelations/' + r.id + '?access=true').then(() => loadRelations(setLoading, setRelations));
				}} />}
			</div>)}
		</div>
		<div style={{ paddingTop: 'var(--u-16)', display: 'grid', gridTemplateColumns: '1fr auto', gridGap: 'var(--u-16)' }}>
			<div>
				<PotentialTeachersField
					placeholder={intl.formatMessage({ id: "searchSupervisor" })}
					excluding={relations.map(r => r.teacherUserId)}
					onChange={setTeacher}
					value={teacher && teacher.id} />
			</div>
			<Button icon="plus" label={intl.formatMessage({ id: "addSupervisor" })} labelNoMobile={true} disabled={teacher == null} onClick={() => {
				setTeacher(null);
				post('/me/student/studentTeacherRelations/' + teacher.id).then(() => loadRelations(setLoading, setRelations));
			}} />
		</div>
	</div>;
}
