import React from 'react';

export default class TableSummary extends React.Component {
	render() {
		const { visible, maxItems, maxPages } = this.props;
		return <div className="text-small">
			<span>{visible} weergegeven van de {maxItems} rijen op {maxPages} pagina's</span>
		</div>;
	}
}
