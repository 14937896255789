import React, { useState } from 'react';
import Button from '../forms/simple/Button';

export default function CollapsableArea({ label, children, defaultOpen = false }) {
	const [ open, setOpen ] = useState(defaultOpen);

	return <div className="collapsable-area">
		<div className="collapsable-area-header">
			<span className="collapsable-area-label">{label}</span>
			<Button className="btn-primary" icon={open ? 'chevron-up' : 'chevron-down'} onClick={() => setOpen(!open)} />
		</div>
		{open && <div className="collapsable-area-content">
			{children}
		</div>}
	</div>;
}
