import React, { Fragment } from 'react';
import { Prompt, Redirect } from 'react-router';
import { useIntl } from 'react-intl';

export default function NavigateAwayPrompt({ redirect }) {
	const intl = useIntl();
	return <Fragment>
		<Prompt when={redirect == null} message={intl.formatMessage({ id: "navigateAwayWarning" })} />
		{redirect && <Redirect to={redirect} />}
	</Fragment>;
}
