import React, { useEffect, useState } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import { get, post, put } from '../../../util/api';
import { withRouter } from 'react-router';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import ColorField from '../../lib/forms/simple/ColorField';
import MaxWidth from '../../lib/ui/MaxWidth';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import CompetenceField from '../../lib/forms/complex/CompetenceField';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import WithValidation from '../../lib/forms/WithValidation';

export default withRouter(({ match, history }) => {
	const clusterId = match.params.id;

	const [ loading, setLoading ] = useState(true);
	const [ name, setName ] = useState('');
	const [ label, setLabel ] = useState('');
	const [ version, setVersion ] = useState('');
	const [ colorForeground, setColorForeground ] = useState('');
	const [ colorBackground, setColorBackground ] = useState('');
	const [ competences, setCompetences ] = useState([]);

	const [ selectedCompetence, setSelectedCompetence ] = useState(null);

	useEffect(() => {
		if (clusterId == null) return setLoading(false);

		get('/clusters/' + clusterId).then(cluster => {
			setName(cluster.name);
			setLabel(cluster.label);
			setVersion(cluster.version);
			setColorForeground(cluster.colorForeground);
			setColorBackground(cluster.colorBackground);
			setCompetences(cluster.competences);
			setLoading(false);
		});
	}, [ clusterId ]);

	return <Card>
		<CardToolbar title={clusterId == null ? 'Cluster aanmaken' : 'Cluster bewerken'} />
		{!loading && <CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				const dto = { name, version, label, colorForeground, colorBackground, competences: competences.map(c => c.id) };
				if (clusterId) {
					put('/clusters/' + clusterId, dto).finally(() => history.push('/admin/clusters'));
				} else {
					post('/clusters', dto).finally(() => history.push('/admin/clusters'));
				}
				return false;
			}}>
				<WithLabel label="Clusternaam">
					<WithValidation valid={name.length > 0} icon={false}>
						<InputField onChange={setName} value={name} />
					</WithValidation>
				</WithLabel>

				<WithLabel label="Label">
					<WithValidation valid={label.length > 0} icon={false}>
						<InputField onChange={setLabel} value={label} />
					</WithValidation>
				</WithLabel>

				<WithLabel label="Versie">
					<InputField onChange={setVersion} value={version} />
				</WithLabel>

				<div className="form-2-col">
					<WithLabel label="Voorgrondkleur">
						<ColorField onChange={setColorForeground} value={colorForeground} />
					</WithLabel>

					<WithLabel label="Achtergrondkleur">
						<ColorField onChange={setColorBackground} value={colorBackground} />
					</WithLabel>
				</div>

				<WithLabel label="Competenties" dontUseLabelElement={true}>
					<MaxWidth width="600px">
						<table className="table">
							<thead>
								<tr>
									<th>Competentie</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{competences.map(c => <tr key={c.id}>
									<td>{c.name}</td>
									<td>
										<TertiaryButton onClick={() => setCompetences(competences.filter(comp => comp.id != c.id))} icon="unlink" label="Ontkoppelen" />
									</td>
								</tr>)}
							</tbody>
						</table>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr auto', paddingTop: 'var(--u-24)' }}>
							<div style={{ paddingRight: 'var(--u-16)' }}>
								<CompetenceField
									placeholder="Competentie zoeken..."
									excluding={competences.map(c => c.id)}
									onChange={setSelectedCompetence}
									value={selectedCompetence && selectedCompetence.id} />
							</div>
							<Button
								disabled={selectedCompetence == null}
								onClick={() => {
									setCompetences([ ...competences, selectedCompetence ]);
									setSelectedCompetence(null);
								}}
								icon="link"
								label="Koppelen" />
						</div>
					</MaxWidth>
				</WithLabel>

				<ButtonArea>
					<TertiaryLinkButton label="Annuleren" to="/admin/clusters" />
					<Button disabled={name.length == 0 || label.length == 0} icon="check" label="Opslaan" type="submit" />
				</ButtonArea>
			</form>
		</CardPadding>}
		{loading && <CardLoadingSpinner />}
	</Card>;
});
