import React, { Fragment, useState } from 'react';
import CardToolbar from '../lib/ui/CardToolbar';
import Card from '../lib/ui/Card';
import { resetPassword } from '../../util/api';
import ErrorMessage from '../lib/forms/ErrorMessage';
import WithLabel from '../lib/forms/WithLabel';
import InputField from '../lib/forms/simple/InputField';
import ButtonArea from '../lib/forms/ButtonArea';
import Button from '../lib/forms/simple/Button';
import CardPadding from '../lib/ui/CardPadding';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import TertiaryLinkButton from '../lib/forms/simple/TertiaryLinkButton';
import usePersistentState from '../../hooks/usePersistentState';
import { FormattedMessage, useIntl } from 'react-intl';

export default function ResetPassword() {
	const [ error, setError ] = useState(null);
	const [ loading, setLoading ] = useState(false);
	const [ username, setUsername ] = usePersistentState('', 'username');
	const [ showMailSentMessage, setShowMailSentMessage ] = useState(false);
	const intl = useIntl();

	return <div className="login">
		<Card>
			<CardToolbar title={intl.formatMessage({ id: "forgotPassword" })} />
			<CardPadding>
				<form onSubmit={event => {
					event.preventDefault();
					if(!loading) {
						setError(null);
						setLoading(true);

						resetPassword(username)
							.then(() => {
								setShowMailSentMessage(true);
							}).catch(r => {
								if (r.status == 429) {
									setError(intl.formatMessage({ id: "tooManyLoginAttempts" }));
								} else {
									r.text().then(body => {
										if (body == 'NO_VALID_EMAIL') {
											setError(intl.formatMessage({ id: "noEmailForThisAccount" }));
										} else {
											setError(intl.formatMessage({ id: "unknownLoginDetails" }));
										}
									});
								}
							})
							.finally(() => setLoading(false));
					}
				}}>
					{error != null && <ErrorMessage error={error} />}
					{showMailSentMessage && <div>
						<p style={{ paddingTop: '0', marginTop: '0' }}><FormattedMessage id="passwordResetEmailSent1" /></p>
						<p style={{ paddingTop: '0', marginTop: '0' }}><FormattedMessage id="passwordResetEmailSent2" /></p>
					</div>}
					{!showMailSentMessage && <Fragment>
						<WithLabel label={intl.formatMessage({ id: "nameOrEmail" })}>
							<InputField
								type="text"
								autoFocus={true}
								onChange={setUsername}
								value={username} />
						</WithLabel>
						<ButtonArea>
							<TertiaryLinkButton label={intl.formatMessage({ id: "back" })} to="/login" />
							<Button label={intl.formatMessage({ id: "resetPassword" })} type="submit" />
						</ButtonArea>
					</Fragment>}
				</form>
			</CardPadding>
			{loading && <CardLoadingSpinner />}
		</Card>
	</div>;
}