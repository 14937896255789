import React, { Fragment, useState } from 'react';
import Button from '../lib/forms/simple/Button';
import CardPadding from '../lib/ui/CardPadding';
import { requestMagicLink } from '../../util/api';
import ErrorMessage from '../lib/forms/ErrorMessage';
import WithLabel from '../lib/forms/WithLabel';
import InputField from '../lib/forms/simple/InputField';
import ButtonArea from '../lib/forms/ButtonArea';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import Card from '../lib/ui/Card';
import CardToolbar from '../lib/ui/CardToolbar';
import TertiaryLinkButton from '../lib/forms/simple/TertiaryLinkButton';
import usePersistentState from '../../hooks/usePersistentState';
import { FormattedMessage, useIntl } from 'react-intl';

export default function MagicLinkLogin() {
	const [ error, setError ] = useState(null);
	const [ username, setUsername ] = usePersistentState('', 'username');
	const [ loading, setLoading ] = useState(false);
	const [ showMailSentMessage, setShowMailSentMessage ] = useState(false);
	const intl = useIntl();

	return <div className="login">
		<Card>
			<CardToolbar title={intl.formatMessage({ id: "loginWithEmail" })} />
			<CardPadding>
				<form onSubmit={event => {
					event.preventDefault();
					setError(null);
					setLoading(true);

					requestMagicLink(username)
						.then(() => {
							setShowMailSentMessage(true);
						}).catch(r => {
							if (r.status == 429) {
								setError(intl.formatMessage({ id: "tooManyLoginAttempts" }));
							} else {
								r.text().then(body => {
									if (body == 'NO_VALID_EMAIL') {
										setError(intl.formatMessage({ id: "noEmailForThisAccountTryPassword" }));
									} else {
										setError(intl.formatMessage({ id: "unknownLoginDetails" }));
									}
								});
							}
						})
						.finally(() => setLoading(false));
				}}>
					{error != null && <ErrorMessage error={error} />}
					{showMailSentMessage && <div>
						<p><FormattedMessage id="magicLinkEmailSent1" /> <b>{username}</b></p>
						<p><FormattedMessage id="magicLinkEmailSent2" /></p>
						<p><FormattedMessage id="magicLinkEmailSent3" /></p>
						<ButtonArea>
							<TertiaryLinkButton label="Terug" onClick={() => setShowMailSentMessage(false)} />
						</ButtonArea>
					</div>}
					{!showMailSentMessage && <Fragment>
						<WithLabel label={intl.formatMessage({ id: "nameOrEmail" })}>
							<InputField
								type="text"
								autoFocus={true}
								onChange={setUsername}
								value={username} />
						</WithLabel>
						<ButtonArea>
							<TertiaryLinkButton label={intl.formatMessage({ id: "back" })} to="/login" />
							<Button label={intl.formatMessage({ id: "login" })} type="submit" />
						</ButtonArea>
					</Fragment>}
				</form>
			</CardPadding>
			{loading && <CardLoadingSpinner />}
		</Card>
	</div>;
}