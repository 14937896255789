import React, { useEffect, useState } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import { get, put } from '../../../util/api';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import Button from '../../lib/forms/simple/Button';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import ButtonArea from '../../lib/forms/ButtonArea';

export default function UserEditLogin({ history, match }) {
	const userId = match.params.id;
	const [ loading, setLoading ] = useState(true);
	const [ user, setUser ] = useState(null);
	const [ email, setEmail ] = useState(null);
	const [ username, setUsername ] = useState(null);

	useEffect(() => {
		get('/users/' + userId)
			.then(user => {
				setUsername(user.username);
				setEmail(user.email);
				setUser(user);
			})
			.finally(() => setLoading(false));
	}, [ userId ]);

	return <Card>
		<CardToolbar title="Gebruiker bewerken" />
		{user != null && <CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				put('/users/' + userId + '/loginDetails', { email, username }).then(() => history.push('/admin/users/view/' + userId));
				return false;
			}}>
				<WithLabel label="E-mail">
					<InputField onChange={setEmail} value={email} />
				</WithLabel>
				<WithLabel label="Gebruikersnaam">
					<InputField onChange={setUsername} value={username} />
				</WithLabel>
				<ButtonArea>
					<TertiaryLinkButton to={'/admin/users/view/' + userId} label="Annuleren" />
					<Button type="submit" icon="check" label="Opslaan" />
				</ButtonArea>
			</form>
		</CardPadding>}
		{loading && <CardLoadingSpinner />}
	</Card>;
}
