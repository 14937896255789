import React, { useState } from 'react';
import CardToolbar from '../../lib/ui/CardToolbar';
import Card from '../../lib/ui/Card';
import CardPadding from '../../lib/ui/CardPadding';
import { post } from '../../../util/api';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import WithValidation from '../../lib/forms/WithValidation';
import BigMultiSelector from '../../lib/forms/complex/BigMultiSelector';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import SchoolField from '../../lib/forms/complex/SchoolField';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import ErrorMessage from '../../lib/forms/ErrorMessage';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';

export default function UserInvite({ history }) {
	const [ error, setError ] = useState(null);
	const [ loading, setLoading ] = useState(false);

	const [ fullName, setFullName ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ type, setType ] = useState('STUDENT');
	const [ schoolId, setSchoolId ] = useState(null);

	const fullNameValid = fullName.length >= 2;
	const emailValid = /^(.+)@(.+)\.(.+)$/.test(email);
	const schoolValid = schoolId != null;

	return <Card>
		<CardToolbar title="Gebruiker uitnodigen" />
		<CardPadding>
			<form onSubmit={e => {
				e.preventDefault();
				e.stopPropagation();
				setError(false);
				setLoading(true);
				post('/users/invite', { email, fullName, userCreationType: type, schoolId })
					.then(() => history.push('/admin/users'))
					.catch(e => {
						e.text().then(setError).catch(() => setError('UNKNOWN'));
					})
					.finally(() => setLoading(false));
				return false;
			}}>
				{error && <ErrorMessage error={error == 'DUPLICATE_EMAIL' ? 'Dit e-mailadres is al in gebruik' : 'Er is iets fout gegaan bij het uitnodigen'} />}

				<WithLabel label="Naam">
					<WithValidation valid={fullNameValid}>
						<InputField onChange={setFullName} value={fullName} />
					</WithValidation>
					<p className="form-explanation">Deze wordt ook getoond aan leerlingen</p>
				</WithLabel>

				<WithLabel label="E-mail">
					<WithValidation valid={emailValid}>
						<InputField type="email" onChange={setEmail} value={email} />
						<p className="form-explanation">Er wordt een mail met verdere instructies naar dit e-mailadres gestuurd.</p>
					</WithValidation>
				</WithLabel>

				<WithLabel label="Accounttype">
					<BigMultiSelector
						options={[
							{ value: 'STUDENT', label: 'Leerling', description: 'De leerling kan zelf een klas kiezen bij het inloggen de eerste keer' },
							{ value: 'TEACHER', label: 'Begeleider', description: 'Docent of TOA, kan bij leerlingen meekijken' },
							{ value: 'SCHOOLADMIN', label: 'Schoolbeheerder', description: 'Krijgt automatisch ook begeleiderrechten, maar kan daar bovenop ook docentaccounts beheren en nieuwe docenten uitnodigen' },
						]}
						onChange={setType}
						value={type} />
				</WithLabel>

				<WithLabel label="School">
					<WithValidation valid={schoolValid}>
						<div style={{ display: 'grid', gridTemplateColumns: '1fr var(--u-48)' }}>
							<div>
								<SchoolField onChange={s => setSchoolId(s.id)} value={schoolId} />
							</div>
						</div>
					</WithValidation>
				</WithLabel>

				<ButtonArea>
					<TertiaryLinkButton label="Annuleren" to="/admin/users" />
					<Button disabled={!fullNameValid || !emailValid || !schoolValid} type="submit" icon="paper-plane" label="Uitnodigen" />
				</ButtonArea>
			</form>
		</CardPadding>
		{loading && <CardLoadingSpinner />}
	</Card>;
}
