import React, { useEffect, useState } from 'react';
import TaxonomyTerms from '../../context/TaxonomyTerms';
import { get } from '../../util/api';

export default function TaxonomyInjector({ children }) {
	const [ termsMap, setTermsMap ] = useState({});

	useEffect(() => {
		get('/taxonomy/terms').then(terms => {
			setTermsMap(terms.reduce((total, { term, description }) => ({ ...total, [term.toLowerCase()]: description }), {}));
		});
	}, []);

	return <TaxonomyTerms.Provider value={termsMap}>
		{children}
	</TaxonomyTerms.Provider>;
}
