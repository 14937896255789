import React, { useState } from 'react';
import CardPadding from '../lib/ui/CardPadding';
import Card from '../lib/ui/Card';
import WithLabel from '../lib/forms/WithLabel';
import InputField from '../lib/forms/simple/InputField';
import ButtonArea from '../lib/forms/ButtonArea';
import Button from '../lib/forms/simple/Button';
import CardToolbar from '../lib/ui/CardToolbar';
import TertiaryLinkButton from '../lib/forms/simple/TertiaryLinkButton';
import WithValidation from '../lib/forms/WithValidation';
import ErrorMessage from '../lib/forms/ErrorMessage';
import { post } from '../../util/api';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import usePersistentState from '../../hooks/usePersistentState';
import { FormattedMessage, useIntl } from 'react-intl';

function errorCodeToHumanReadable(error) {
	if (error == 'DUPLICATE_EMAIL') return <FormattedMessage id="emailAlreadyInUse" />;
	if (error == 'SIGNUP_CODE_NOT_FOUND') return <FormattedMessage id="invalidTeacherCode" />;
	return <FormattedMessage id="unknownErrorCreateAccount" />;
}

export default function Signup() {
	const [ showEmailMessage, setShowEmailMessage ] = useState(false);
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);

	const [ signupCode, setSignupCode ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ username, setUsername ] = usePersistentState('', 'username');
	const [ name, setName ] = useState('');

	const emailValid = /^(.+)@(.+)\.(.+)$/.test(email);
	const nameValid = name.length > 1;
	const signupValid = signupCode.length == 6;
	const intl = useIntl();

	return <div className="login">
		<Card>
			<CardToolbar title={intl.formatMessage({ id: "createNewAccount" })} />
			{!showEmailMessage && <CardPadding>
				<form onSubmit={e => {
					e.preventDefault();
					e.stopPropagation();
					setError(null);
					setLoading(true);
					post('/signup', { signupCode, fullName: name, email }, false)
						.then(() => { setShowEmailMessage(true); setUsername(email); })
						.catch(e => { e.text().then(setError); setUsername(''); })
						.finally(() => setLoading(false));
					return false;
				}}>
					{error && <ErrorMessage error={errorCodeToHumanReadable(error)} />}

					<WithLabel label={intl.formatMessage({ id: "teacherCode" })}>
						<WithValidation valid={signupValid}>
							<InputField onChange={setSignupCode} value={signupCode} />
						</WithValidation>
						<p className="form-explanation"><FormattedMessage id="teacherCodeExplanation" /></p>
					</WithLabel>
					<hr />
					<WithLabel label={intl.formatMessage({ id: "emailAddress" })}>
						<WithValidation valid={emailValid}>
							<InputField onChange={setEmail} value={email} />
						</WithValidation>
						<p className="form-explanation"><FormattedMessage id="emailAddressExplanation" /></p>
					</WithLabel>
					<WithLabel label={intl.formatMessage({ id: "fullName" })}>
						<WithValidation valid={nameValid}>
							<InputField onChange={setName} value={name} />
						</WithValidation>
					</WithLabel>
					<ButtonArea>
						<TertiaryLinkButton label={intl.formatMessage({ id: "cancel" })} to="/login" />
						<Button type="submit" icon="check" label={intl.formatMessage({ id: "create" })} disabled={!emailValid || !nameValid || !signupValid} />
					</ButtonArea>
				</form>
			</CardPadding>}
			{showEmailMessage && <CardPadding>
				<p><FormattedMessage id="accountCreationEmailSent1" /> <b>{username}</b></p>
				<p><FormattedMessage id="accountCreationEmailSent2" /></p>
				<p><FormattedMessage id="accountCreationEmailSent3" /></p>
				<ButtonArea>
					<TertiaryLinkButton label={intl.formatMessage({ id: "back" })} onClick={() => setShowEmailMessage(false)} />
				</ButtonArea>
			</CardPadding>}
			{loading && <CardLoadingSpinner />}
		</Card>
	</div>;
}
