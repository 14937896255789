import React, { useContext, useEffect, useState } from 'react';
import CardToolbar from '../lib/ui/CardToolbar';
import CardPadding from '../lib/ui/CardPadding';
import { get, put } from '../../util/api';
import WithLabel from '../lib/forms/WithLabel';
import InputField from '../lib/forms/simple/InputField';
import ButtonArea from '../lib/forms/ButtonArea';
import Button from '../lib/forms/simple/Button';
import Card from '../lib/ui/Card';
import CardLoadingSpinner from '../lib/ui/CardLoadingSpinner';
import NavigateAwayPrompt from '../lib/forms/NavigateAwayPrompt';
import TertiaryButton from '../lib/forms/simple/TertiaryButton';
import UserDataChangeCounter from '../../context/UserDataChangeCounter';
import { useIntl } from 'react-intl';

export default function ProfileNameForm() {
	const { increment, value } = useContext(UserDataChangeCounter);
	const [ redirect, setRedirect ] = useState(null);
	const [ loading, setLoading ] = useState(false);

	const [ fullName, setFullName ] = useState('');
	const intl = useIntl();

	useEffect(() => {
		get('/whoami').then(user => {
			setFullName(user.fullName);
		});
	}, [ value ]);

	return <Card>
		<NavigateAwayPrompt redirect={redirect} />
		<CardToolbar title={intl.formatMessage({ id: "changeName" })} />
		<CardPadding>
			<form onSubmit={event => {
				event.preventDefault();
				event.stopPropagation();
				setLoading(true);
				put('/users/me/name', { fullName }).then(() => setRedirect('/profile')).finally(increment);
				return false;
			}}>
				<WithLabel label={intl.formatMessage({ id: "fullName" })}>
					<InputField value={fullName} onChange={setFullName} />
				</WithLabel>

				<ButtonArea>
					<TertiaryButton label={intl.formatMessage({ id: "cancel" })} onClick={() => setRedirect('/profile')} />
					<Button icon="check" label={intl.formatMessage({ id: "save" })} type="submit" />
				</ButtonArea>
				{loading && <CardLoadingSpinner />}
			</form>
		</CardPadding>
	</Card>;
}
