import React from 'react';

export default function BigMultiSelector({ disabled, options, value, onChange, capTwoCols = false }) {
	return <div className={'multi-selector' + (disabled ? ' multi-selector-disabled' : '') + (capTwoCols ? ' multi-selector-max-2-col' : '')}>
		{options.map(o => <div
			key={o.value}
			className={'multi-selector-option' + (o.value == value ? ' multi-selector-option-selected' : '')}
			onClick={() => !disabled && onChange(o.value)}>
			<span className="multi-name">{o.label}</span><br />
			<p>{o.description}</p>
		</div>)}
	</div>;
}
