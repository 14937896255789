import React from 'react';
import Card from '../lib/ui/Card';
import CardAccent from '../lib/ui/CardAccent';
import CardPadding from '../lib/ui/CardPadding';
import headerForeground from '../../assets/competentiemonitor/header/header-foreground-paths.svg';

const DownloadSubmittedFeedback = React.forwardRef(({ feedbackRequest, getCurrentLevel }, ref) => {
	return <div>
		<div style={{ display: 'none' }}>
			<style type="text/css" media="print">{css}</style>

			<div ref={ref} style={{ background: 'white', height: '100%' }}>
				<div className="header-left" style={{ background: 'var(--col-grey-100)' }}>
					<img
						style={{ width: '18cm', paddingTop: 'var(--u-16)' }}
						className="logo"
						src={headerForeground}
						alt="Logo Competentiemonitor"/>
				</div>
				<Card>
					{feedbackRequest.feedbacks.toSorted((a, b) => { return a.competence.label.localeCompare(b.competence.label); }).map(f => <Card style={{ boxShadow: 'none' }}>
						<CardAccent color={f.competence.color}/>
						<div>
							<CardPadding>
								<h1 style={{ padding: '0' }}>{f.competence.label} {getCurrentLevel(f)}</h1>
							</CardPadding>
							<div style={{ padding: 'var(--u-8) var(--u-24) var(--u-32)' }}>
								<div>
									<div className="label" style={{ fontSize: 'var(--fs-small)', lineHeight: '110%', paddingBottom: '6px' }}>{f.question1}</div>
									<div
										style={{ fontSize: 'var(--fs-small)', paddingLeft: 'var(--u-24)', lineHeight: '1.5' }}>{f.answer1}</div>
								</div>
								<hr style={{ margin: '12px 0 16px' }}/>
								<div>
									<div className="label" style={{ fontSize: 'var(--fs-small)', lineHeight: '110%', paddingBottom: '6px' }}>{f.question2}</div>
									<div
										style={{ fontSize: 'var(--fs-small)', paddingLeft: 'var(--u-24)', lineHeight: '1.5' }}>{f.answer2}</div>
								</div>
								<hr style={{ margin: '12px 0 16px' }}/>
								<div>
									<div className="label" style={{ fontSize: 'var(--fs-small)', lineHeight: '110%', paddingBottom: '6px' }}>{f.question3}</div>
									<div
										style={{ fontSize: 'var(--fs-small)', paddingLeft: 'var(--u-24)', lineHeight: '1.5' }}>{f.answer3}</div>
								</div>
								<hr style={{ margin: '12px 0 0 0' }}/>
							</div>
						</div>
					</Card>)}
				</Card>
			</div>
		</div>
	</div>;
});


export default DownloadSubmittedFeedback;

const css = `
@page {
	margin: 10mm;
	print-color-adjust: exact;
}
`;