import React, { Fragment, useContext, useEffect, useState } from 'react';
import Card from '../../lib/ui/Card';
import CardToolbar from '../../lib/ui/CardToolbar';
import { get } from '../../../util/api';
import UserContext from '../../../context/User';
import InputField from '../../lib/forms/simple/InputField';
import TablePadding from '../../lib/table/TablePadding';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import TertiaryLinkButton from '../../lib/forms/simple/TertiaryLinkButton';
import { FormattedMessage, useIntl } from 'react-intl';
import SortableHeader from '../../lib/table/SortableHeader';
import useSessionState from '../../../hooks/useSessionState';

function customSort(filters, a, b) {
	const filter = filters[0].name.split('.');
	const keyA = filter.reduce((element, part) => element[part], a);
	const keyB = filter.reduce((element, part) => element[part], b);
	const correctionFactor = {
		'asc': 1,
		'desc': -1,
		'none': 0,
	}[filters[0].direction];

	// First deal with null cases
	if (keyA == null && keyB == null) {
		return filters.length > 1 ? customSort(filters.slice(1), a, b) : 0;
	} else if (keyB == null) {
		return -1 * correctionFactor;
	} else if (keyA == null) {
		return 1 * correctionFactor;
	}

	if (filter[0] == 'numberOfStudents') {
		if (keyA == keyB) {
			return filters.length > 1 ? customSort(filters.slice(1), a, b) : 0;
		} else if (keyA < keyB) {
			return -1 * correctionFactor;
		} else {
			return 1 * correctionFactor;
		}
	} else {
		if (keyA.toUpperCase() == keyB.toUpperCase()) {
			return filters.length > 1 ? customSort(filters.slice(1), a, b) : 0;
		} else if (keyA.toUpperCase() < keyB.toUpperCase()) {
			return -1 * correctionFactor;
		} else {
			return 1 * correctionFactor;
		}
	}
}

export default function SchoolClassesOverview() {
	const user = useContext(UserContext);
	const [ loading, setLoading ] = useState(true);
	const [ schoolClasses, setSchoolClasses ] = useState([]);
	const [ query, setQuery ] = useSessionState('', 'classesOverviewQuery');
	const [ sort, setSort ] = useSessionState([ { name: 'name', direction: 'asc' }, { name: 'numberOfStudents', direction: 'none' } ], 'classesFilters');
	const intl = useIntl();

	useEffect(() => {
		get('/schools/' + (user.schoolAdminInfo || user.teacherInfo).schoolId + '/schoolclasses').then(setSchoolClasses).finally(() => setLoading(false));
	}, []);

	const results = schoolClasses.filter(sc => sc.name.toLowerCase().includes(query.toLowerCase())).sort((a, b) => {
		return customSort(sort, a, b);
	});

	return <Card>
		<CardToolbar title={intl.formatMessage({ id: "classes" })}>
			{user.schoolAdminInfo && <TertiaryLinkButton icon="plus" label={intl.formatMessage({ id: "createClass" })} to="/schoolclasses/create" />}
		</CardToolbar>
		{!loading && <Fragment>
			<TablePadding>
				<InputField placeholder={intl.formatMessage({ id: "search" })} onChange={setQuery} value={query} type="search" />
				<span className="text-small">{results.length} <FormattedMessage id="classesShown" /></span>
			</TablePadding>
			<table className="table">
				<colgroup>
					<col width="*" />
					<col width="*" />
					<col width="*" />
					<col width="1" />
				</colgroup>
				<thead>
					<tr>
						<SortableHeader
							name="name"
							firstSortName={sort[0].name}
							firstSortDirection={sort[0].direction}
							secondSortName={sort[1].name}
							secondSortDirection={sort[1].direction}
							onChange={(firstName, firstDirection, secondName, secondDirection) => setSort([ { name: firstName, direction: firstDirection }, { name: secondName, direction: secondDirection } ])}>
							<FormattedMessage id="class" />
						</SortableHeader>
						<SortableHeader
							name="schoolClassType.name"
							firstSortName={sort[0].name}
							firstSortDirection={sort[0].direction}
							secondSortName={sort[1].name}
							secondSortDirection={sort[1].direction}
							onChange={(firstName, firstDirection, secondName, secondDirection) => setSort([ { name: firstName, direction: firstDirection }, { name: secondName, direction: secondDirection } ])}>
							<FormattedMessage id="type" />
						</SortableHeader>
						<SortableHeader
							name="numberOfStudents"
							firstSortName={sort[0].name}
							firstSortDirection={sort[0].direction}
							secondSortName={sort[1].name}
							secondSortDirection={sort[1].direction}
							onChange={(firstName, firstDirection, secondName, secondDirection) => setSort([ { name: firstName, direction: firstDirection }, { name: secondName, direction: secondDirection } ])}
							alignedRight={true}>
							# <FormattedMessage id="students" />
						</SortableHeader>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{results.map(sc => <tr key={sc.id}>
						<td>{sc.name}</td>
						<td>{(sc.schoolClassType || {}).name || '-'}</td>
						<td style={{ textAlign: 'right' }}>{sc.numberOfStudents}</td>
						<td>
							<TertiaryLinkButton to={'/schoolclasses/view/' + sc.id} label={intl.formatMessage({ id: "view" })} iconRight={true} icon="angle-right" />
						</td>
					</tr>)}
				</tbody>
			</table>
		</Fragment>}
		{loading && <CardLoadingSpinner />}
	</Card>;
}
