import React, { useState } from 'react';
import CardToolbar from '../../lib/ui/CardToolbar';
import CardPadding from '../../lib/ui/CardPadding';
import { put } from '../../../util/api';
import WithLabel from '../../lib/forms/WithLabel';
import InputField from '../../lib/forms/simple/InputField';
import WithValidation from '../../lib/forms/WithValidation';
import ButtonArea from '../../lib/forms/ButtonArea';
import Button from '../../lib/forms/simple/Button';
import CardLoadingSpinner from '../../lib/ui/CardLoadingSpinner';
import Card from '../../lib/ui/Card';
import TertiaryButton from '../../lib/forms/simple/TertiaryButton';
import MaxWidth from '../../lib/ui/MaxWidth';
import CenterHorizontal from '../../lib/ui/CenterHorizontal';
import { FormattedMessage, useIntl } from 'react-intl';


export function PasswordReset({ issues, onFix }) {
	const [ loading, setLoading ] = useState(false);
	const [ newPassword, setNewPassword ] = useState('');
	const [ confirmPassword, setConfirmPassword ] = useState('');
	const intl = useIntl();

	return <CenterHorizontal>
		<MaxWidth width="var(--u-512)">
			<Card>
				<CardToolbar title={intl.formatMessage({ id: "setPassword" })}/>
				<CardPadding>
					<form onSubmit={event => {
						event.preventDefault();
						event.stopPropagation();
						setLoading(true);
						put('/users/me/password', { currentPassword: null, newPassword }, false).then(onFix);
						return false;
					}}>
						<p><FormattedMessage id="newPasswordRequirements" /></p>
						<br />

						<WithLabel label={intl.formatMessage({ id: "newPassword" })}>
							<WithValidation valid={newPassword.length >= 8}>
								<InputField type="password" value={newPassword} onChange={setNewPassword}/>
							</WithValidation>
						</WithLabel>
						<WithLabel label={intl.formatMessage({ id: "repeatPassword" })}>
							<WithValidation valid={confirmPassword.length >= 8 && newPassword == confirmPassword}>
								<InputField type="password" value={confirmPassword} onChange={setConfirmPassword}/>
							</WithValidation>
						</WithLabel>

						<ButtonArea>
							{issues.passwordResetState == 'REQUESTED' && <TertiaryButton onClick={() => { setLoading(true); put('/users/me/stopRequestedPasswordReset').then(onFix); }} label={intl.formatMessage({ id: "confirm" })} />}
							<Button disabled={newPassword != confirmPassword || newPassword.length < 8} icon="check" label={intl.formatMessage({ id: "confirm" })} type="submit" />
						</ButtonArea>

						{loading && <CardLoadingSpinner/>}
					</form>
				</CardPadding>
			</Card>
		</MaxWidth>
	</CenterHorizontal>;
}
